
import companyLogo from "branch/resource/company-logo.png"

export const printHeader = ({ jsPDFDoc, companyData, startX, startY}) => {
  console.log("companyData::", companyData)
  let xPos = startX
  let yPos = startY

  const logoWidth = 2.1
  const logoHeight = 2.1
  const img = new Image()
  const defaultHeaderSize = 18;
  const comapanyAddrSize = 11;

  img.src = companyLogo
  try {
    jsPDFDoc.addImage(img, 'png', xPos, yPos - 0.54, logoWidth, logoHeight)
    console.log("imgWidth::", img.width)
    xPos += logoWidth
  } catch (e) {
    console.log("error::", e)
  }
  jsPDFDoc.setFont("THSarabun", "bold")
  jsPDFDoc.setFontSize(defaultHeaderSize)
  yPos += 0.2
  jsPDFDoc.text(companyData.NameTH, xPos, yPos)
  yPos +=  0.2
  jsPDFDoc.text(companyData.NameEN, xPos, yPos)
  jsPDFDoc.setFont("THSarabun", "normal")
  jsPDFDoc.setFontSize(comapanyAddrSize)
  const lineHeightAddr = 0.165
  yPos += lineHeightAddr
  jsPDFDoc.text(companyData.AddrTH, xPos, yPos)
  yPos += lineHeightAddr
  jsPDFDoc.text(companyData.AddrEN, xPos, yPos)
  yPos += lineHeightAddr
  jsPDFDoc.text(`โทร/Tel: ${companyData.Tel} เลขประจำตัวผู้เสียภาษี/TAX ID: ${companyData.TaxId}`, xPos, yPos)

  // img.src = companyLogo
  // try {
  //   jsPDFDoc.addImage(img, 'png', xPos, yPos + 0.1, logoWidth, logoHeight)
  //   console.log("imgWidth::", img.width)
  //   xPos += logoWidth + 0.2
  // } catch (e) {
  //   console.log("error::", e)
  // }
  // jsPDFDoc.setFont("THSarabun", "bold")
  // jsPDFDoc.setFontSize(defaultHeaderSize)
  // yPos += 0.2
  // jsPDFDoc.text(companyData.NameEN, xPos, yPos)
  // jsPDFDoc.setFont("THSarabun", "bold")
  // jsPDFDoc.setFontSize(comapanyAddrSize)
  // yPos += lineHeight
  // jsPDFDoc.text(companyData.AddrEN, xPos, yPos)
  // yPos += lineHeight - 0.025
  // yPos += lineHeight - 0.025
  // jsPDFDoc.text(`Tax ID: ${companyData.TaxId}`, xPos, yPos)
  // yPos += lineHeight - 0.025
  // jsPDFDoc.text(`Tel: ${companyData.Tel}${companyData.Fax && ` Fax: ${companyData.Fax}`}`, xPos, yPos)
  return yPos
}