import { Button, MenuItem, Paper, TextField } from "@mui/material";
import { BoxFC } from "components/BoxCustom";
import React, { useEffect, useState } from "react";

const AbsentChoice = ({defaultSts, defaultDscp, tukAttnSts, tukId, col, handleCheck}) => {
  const [attnSts, setAttnSts] = useState(101)
  const [dscp, setDscp] = useState("")

  useEffect(()=>{
    setAttnSts(defaultSts)
    setDscp(defaultDscp)
  }, [defaultSts, defaultDscp])
  return (
    <Paper sx={{ p: 1, width: 180 }}>
      <BoxFC sx={{gap:1}}>
        <TextField label="สถานะ" size="small" fullWidth value={attnSts??""} select onChange={(e)=>{setAttnSts(e.target.value)}}>
          <MenuItem value={null}>ไม่ระบุ</MenuItem>
          {tukAttnSts.map(sts=>(
            <MenuItem key={sts.TukAttnStsId} value={sts.TukAttnStsId}>
              {`(${sts.Abbr})${sts.TukAttnStsNm}`}
            </MenuItem>
          ))}
        </TextField>
        <TextField label="หมายเหตุ" size="small" fullWidth value={dscp} onChange={(e)=>{setDscp(e.target.value)}}/>
        <Button variant="contained" size="small" onClick={()=>{handleCheck(tukId, col, attnSts, dscp)}}>บันทึก</Button>
      </BoxFC>
    </Paper>
  )
}

export default AbsentChoice