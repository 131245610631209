export const columns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "TukCd",
    headerName: "เลขรถ",
    valueGetter: (params)=> params.row.LicNm === params.value? params.value : `${params.value}(${params.row.LicNm})`,
    width: 100,
  },
  
]