import { MenuItem, TextField, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
// import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useContext } from "react";
// import { useContext } from "react";

const GeneralContent = ({ generalData, setGeneralData }) => {

  const { msData } = useContext(GlobalStateContext)

  // const {ax} = useContext(GlobalStateContext);

  const handleChange = useCallback((e) => {
    setGeneralData(o => ({ ...o, TableDensity: e.target.value }))
  }, [setGeneralData])

  return (
    <BoxFC>
      <Typography variant="h6">การตั้งค่าทั่วไป</Typography>
      <BoxFC sx={{ gap: 1 }} pl={2}>
        <BoxFR >
          <Typography sx={{ width: 200 }}>ระยะห่างระว่างแถวของตาราง</Typography>
          <TextField size="small" value={generalData.TableDensity} onChange={handleChange} select>
            <MenuItem value="standard">ปกติ</MenuItem>
            <MenuItem value="compact">แคบ</MenuItem>
            <MenuItem value="comfortable">กว้าง</MenuItem>
          </TextField>
        </BoxFR>
        <BoxFR>
          <Typography sx={{ width: 200 }}>บัญชีเงินสดย่อยเริ่มต้น</Typography>
          <TextField size="small" value={generalData.DfPCJnlId || ""} sx={{width: 200}} label="เลือกบัญชี"
            onChange={e => { setGeneralData(o => ({ ...o, DfPCJnlId: e.target.value })) }} select>
            {msData.pcJournalCombo.map(item => (
              <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
            ))}
          </TextField>
        </BoxFR>
      </BoxFC>
    </BoxFC>
  )
}

export default React.memo(GeneralContent)
