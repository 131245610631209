import { Box, Dialog, DialogContent, Divider, IconButton, MenuItem, TextField, Typography } from "@mui/material"
import { alertConfirm, alertError} from "components/Alert"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { jobOrderColNm } from "constants/jobOrderConstant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { clearJobOrderDocColumns } from "./columns"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import { branchApiNm } from "branch/constant/branchApiNm"
import { addIdForDataGrid, generateMenuComboItem } from "utils"
import dayjs from "dayjs"
import DateTimeSelect from "components/DateTimeSelect"
import { RefreshRounded } from "@mui/icons-material"
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime"
import ComboBox from "components/ComboBox"
import CheckboxFormControl from "components/CheckboxFormControl"
import SaveButton from "components/buttons/SaveButton"
import DailyJobDialogV2 from "pages/Menu1/DailyJob/DailyJobDialogV2"

const isToUpper = true

const initJOItem = {
  JobOrdId: null,
  FinDte: null,
  ContNo: "",
  ContNo2: "",
  ContSize: ".",
  ContTyp: ".",
  ContHq: 0,
  TakePOIId: null,
  LocPOIId: null,
  RtnPOIId: null,
  SealNo: "",
  Rmk: "",
  PlnClr: "#FFFFFF",
  PlnTxt: "",
  Memo: null,
  ArvTm: null,
  LevTm: null,
  TakeAptTm: null,
  RtnAptTm: null,
  DrvId: null,
  TukId: null,
  DrvActTm: null,
  FullWt: "",
  TareWt: "",
}

let selectedRow = null
let oJOItem = null
let selectedJobOrdId = null

const ClearJobOrderDocDialog = ({ drvId, dialogOpen, setDialogOpen }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [joItem, setJOItem] = useState({ ...initJOItem })
  const [joData, setJOData] = useState([])
  const [finJOData, setFinJOData] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [dialogDailyJobOpen, setDialogDailyJobOpen] = useState(false)
  const [filterData, setFilterData] = useState({
    ModDteSt: dayjs(dayjs().format("YYYY-MM-DD 00:00:00.00")),
    ModDteEn: dayjs(dayjs().format("YYYY-MM-DD 23:59:59.99"))
  })

  const topColumns = useMemo(() => {
    return clearJobOrderDocColumns(msData)
  }, [msData])

  const bottomColumns = useMemo(() => {
    return [
      ...clearJobOrderDocColumns(msData),
      {
        field: "ModDte",
        headerName: "เวลาแก้ไข",
        width: 100,
        type: 'dateTime',
        valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-",
      },
    ]
  }, [msData])

  const textFieldProps = useCallback((name) => ({
    size: "small",
    variant: "outlined",
    fullWidth: true,
    label: jobOrderColNm[name],
    value: joItem[name],
    onChange: (e) => setJOItem(o => ({ ...o, [name]: e.target.value })),
    onBlur: (e) => {
      if (isToUpper && e.target.value !== "") {
        setJOItem(o => ({ ...o, [name]: e.target.value.toUpperCase() }))
      }
    }
  }), [joItem, setJOItem])

  const datePickerJobOrderProp = useCallback((name) => ({
    label: jobOrderColNm[name],
    inputFormat: "DD/MM HH:mm",
    mask: "__/__ __:__",
    value: joItem[name],
    onChange: (newValue) => { setJOItem(oldState => ({ ...oldState, [name]: newValue && newValue.format("YYYY-MM-DD HH:mm:00.00") })) },
    renderInput: (params) => <TextField size="small"  {...params} fullWidth />,
  }), [joItem, setJOItem])

  const poiComBoProp = useCallback((name) => ({
    sx: { flex: 1 },
    options: msData.poiCombo,
    selectedId: joItem[name],
    setSelectedId: (id) => setJOItem(o => ({ ...o, [name]: id }))
  }), [msData.poiCombo, joItem, setJOItem])

  const setAllData = useCallback((data) => {


    setJOData(addIdForDataGrid(data.jobOrders, "JobOrdId"))
    setFinJOData(addIdForDataGrid(data.finJobOrders, "JobOrdId"))

  }, [])

  const getData = useCallback(() => {
    const postData = {
      DrvId: drvId,
      ModDteSt: dayjs().format("YYYY-MM-DD 00:00:00"),
      ModDteEn: dayjs().format("YYYY-MM-DD 23:59:59"),
      getFn: "getJobOrderByDriver",
    }
    ax.post(branchApiNm.getJobOrderByDriver, postData).then(value => {
      if (value.data) {
        // lastFilter = postData
        setAllData(value.data)
      }
    })
  }, [ax, drvId, setAllData])

  const handleSave = useCallback(() => {
    if (!selectedRow) {
      alertError("กรุณาเลือกแถวใบงานก่อน")
      return
    }

    let hasChange = false

    const postData = {
      getArgs: {
        getFn: "getJobOrderByDriver",
        DrvId: drvId,
        ModDteSt: dayjs().format("YYYY-MM-DD 00:00:00"),
        ModDteEn: dayjs().format("YYYY-MM-DD 23:59:59"),
      }
    }


    //---------------------------------------------- JobOrder ----------------------------------------------
    let hasJOChange = false
    const colValue = {}
    for (const key in joItem) {
      if (oJOItem[key] !== joItem[key]) {
        hasChange = true
        hasJOChange = true
        colValue[key] = joItem[key]
      }
    }
    if (hasJOChange) {
      postData.jobOrdArr = [{
        JobOrdId: joItem.JobOrdId,
        ColValues: colValue,
        LogRmk: "ลงจบใบงาน"
      }]
    }

    if (hasChange) {
      ax.post(branchApiNm.updateJobOrderAllDetail, postData).then(value => {
        if (value.data) {
          setAllData(value.data)
          setJOItem({ ...initJOItem })
          oJOItem = { ...initJOItem }
          setSelectionModel([])
          selectedRow = null
        }
      })
    }
  }, [ax, joItem, drvId, setAllData])

  const rowClick = useCallback((row) => {
    selectedRow = row
    setSelectionModel([row.JobOrdId])
    const newJOItem = { ...row }
    const contNos = newJOItem.ContNo?.split(",") || []
    if (contNos.length > 1) {
      newJOItem.ContNo = contNos[0].trim();
      contNos.splice(0, 1);
      newJOItem.ContNo2 = contNos.join(",");
    } else {
      newJOItem.ContNo2 = "";
    }
    oJOItem = { ...newJOItem }

    setJOItem(newJOItem)
  }, [])

  const handleRowClick = useCallback((params) => {
    console.log("in handleRowClick params::", params)


    if (selectedRow &&
      (JSON.stringify(oJOItem) !== JSON.stringify(joItem))) {
      alertConfirm("ต้องการบันทึกข้อมูลก่อนเปลี่ยนใบงานหรือไม่?", handleSave(() => rowClick(params.row)), () => rowClick(params.row), "บันทึก")
      return
    } else {
      rowClick(params.row)
    }

  }, [joItem, handleSave, rowClick])

  const handleRowDoubleClick = useCallback((params) => {
    selectedJobOrdId = params.row.JobOrdId
    setDialogDailyJobOpen(true)
  }, [])

  const getJobOrderByFinDte = useCallback(() => {
    const postData = {
      DrvId: drvId,
      ...filterData
    }
    ax.post(branchApiNm.getJobOrderByFinDte, postData).then(value => {
      if (value.data) {
        setFinJOData(addIdForDataGrid(value.data, "JobOrdId"))
      }
    })
  }, [filterData, drvId, ax, setFinJOData])

  const onDailyJobDialogFinish = useCallback(() => {
    getData()
  }, [getData])

  const handleDrvCheckChange = useCallback((e) => {
    if (e.target.checked) {
      setJOItem(o => ({ ...o, DrvId: drvId }))
    } else {
      setJOItem(o => ({ ...o, DrvId: null, TukId: null, DrvActTm: null }))
    }
  }, [drvId])

  useEffect(() => {
    if (dialogOpen) {
      getData()
    }
    else {
      setJOData([])
      setFinJOData([])
      oJOItem = { ...initJOItem }
      setJOItem({ ...initJOItem })
    }
  }, [dialogOpen, getData])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xl'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"รับใบงาน"}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFR pt={2} sx={{ alignItems: "flex-start" }} height={650}>
          <BoxFC width={850} height="100%" sx={{ gap: 1 }}>
            <BoxFR sx={{ alignItems: "baseline" }}>
              <Typography variant="h6">งานที่รับผิดชอบ</Typography>
              <Typography variant="body" >(จำนวน {joData.length} ใบงาน)</Typography>
            </BoxFR>
            <Box height={300}>
              <DataGridCellExpand
                hideToolbar
                hideFooter
                selectionModel={selectionModel}
                onSelectionModelChange={(ids) => setSelectionModel(ids)}
                columns={topColumns}
                rows={joData}
                onRowClick={handleRowClick}
                onRowDoubleClick={handleRowDoubleClick}
              />
            </Box>
            <Divider width="80%" sx={{ alignSelf: "center", mb: 1 }} />
            <BoxFR flex={1} >
              <Typography variant="h6">วันที่แก้ไขข้อมุล</Typography>
              <DateTimeSelect label="วันที่แก้ไข"
                sx={{ width: 180 }}
                start={filterData.ModDteSt}
                end={filterData.ModDteEn}
                setSelectDate={(start, end) => { setFilterData({ ModDteSt: start, ModDteEn: end }) }}
                getData={getJobOrderByFinDte} />
              <IconButton onClick={getJobOrderByFinDte}>
                <RefreshRounded color="primary" />
              </IconButton>
            </BoxFR>
            <Box height="100%">
              <DataGridCellExpand
                hideToolbar
                hideFooter
                selectionModel={selectionModel}
                onSelectionModelChange={(ids) => setSelectionModel(ids)}
                columns={bottomColumns}
                rows={finJOData}
                onRowClick={handleRowClick}
                onRowDoubleClick={handleRowDoubleClick}
              />
            </Box>
          </BoxFC>
          <Divider orientation="vertical" flexItem />
          <BoxFC sx={{ gap: 0 }} height="100%">
            <BoxFR p={1}>
              <Typography fontWeight="bold">ข้อมูลใบงานทีเลือก</Typography>
            </BoxFR>
            <BoxFC sx={{ gap: 1 }} flex={1}>
              <BoxFR>
                <TextField {...textFieldProps("ContNo")} />
                <TextField {...textFieldProps("ContNo2")} label="หมายเลขตู้2" />
                <TextField {...textFieldProps("SealNo")} />
              </BoxFR>
              <BoxFR>
                <TextField select {...textFieldProps("ContSize")} >{generateMenuComboItem(msData.contSizeCombo)}</TextField>
                <TextField select {...textFieldProps("ContTyp")} >{generateMenuComboItem(msData.contTypCombo)}</TextField>
                <TextField select {...textFieldProps("ContHq")}
                  onBlur={null}>
                  <MenuItem value={0}>Std</MenuItem>
                  <MenuItem value={1}>HQ</MenuItem>
                </TextField>
              </BoxFR>
              <BoxFR>
                <ComboBox label="สถานที่รับ" {...poiComBoProp("TakePOIId")} />
                <ComboBox label="โรงงาน" {...poiComBoProp("LocPOIId")} />
                <ComboBox label="สถานที่คืน" {...poiComBoProp("RtnPOIId")} />
              </BoxFR>
              <BoxFR>
                <DateTimePickerCustomTime {...datePickerJobOrderProp("AptTm")} />
                <DateTimePickerCustomTime {...datePickerJobOrderProp("FinDte")} />
                <CheckboxFormControl sx={{ width: "100%" }} label="รับผิดชอบใบงาน" checked={joItem.DrvId ? true : false} onChange={handleDrvCheckChange} />
              </BoxFR>

              <TextField {...textFieldProps("Rmk")} multiline rows={3} />
              <Divider />
              <Typography fontWeight="bold">เงินค้าง</Typography>
              <Box flex={1} />
              <SaveButton sx={{ width: "100%" }} onClick={handleSave} />
            </BoxFC>
          </BoxFC>
        </BoxFR>
        <DailyJobDialogV2
          dialogOpen={dialogDailyJobOpen}
          setDialogOpen={setDialogDailyJobOpen}
          selectedId={selectedJobOrdId}
          onFinish={onDailyJobDialogFinish}
          lastFilter={null} />

      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(ClearJobOrderDocDialog)