
import { PrintRounded } from "@mui/icons-material";
import { Button, ButtonGroup, Dialog, DialogContent, Divider, InputAdornment, TextField, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { alertError } from "components/Alert";
import { BoxFC, BoxFR } from "components/BoxCustom";
import CheckboxFormControl from "components/CheckboxFormControl";
import ComboBox from "components/ComboBox";
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import TextFieldWithOption from "components/TextFieldWithOption/TextFieldWithOption";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import dayjs from "dayjs";
import React, { useCallback, useContext, useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";


const AddDataDialog = ({ dialogOpen, setDialogOpen, confirmSelect, row, selectionDrvs, isManualJobordId }) => {

  const { msData } = useContext(GlobalStateContext)

  // const truckDriverCombo = useMemo(() => generateTruckDriverCombo(msData), [msData])

  const drvNm = useMemo(() => {
    if (selectionDrvs.length === 0) {
      return ""
    } else if (selectionDrvs.length === 1) {
      const drv = msData.drivers.find(driver => driver.DrvId === selectionDrvs[0])
      if (!drv) return ""
      const tuk = msData.trucks.find(truck => truck.TukId === drv.TukId)
      const tukStr = tuk ? ` (${tuk.Code})` : ""
      return `${drv.FName} ${drv.LName}${tukStr}`
    } else {
      return `จำนวน ${selectionDrvs.length} คน`
    }
  }, [selectionDrvs, msData.drivers, msData.trucks])


  const initDailogData = useMemo(() => ({
    DrvId: null,
    Amnt: 0,
    Dacp: "",
    IsCash: +(msData.serverData.DefaultValue?.IsCash || 0),
    IsPaid: +(msData.serverData.DefaultValue?.IsPaid || 0),
    IsTake: 0,
    IsLoc: 0,
    IsRtn: 0,
    AptTm: dayjs(dayjs().format("YYYY-MM-DD 00:00:00")),
    JobOrdId: null,
    FuelAmnt: 0,
    RemainFuelAmnt: 0,
    NumJobOrd: 1,
  }), [msData.serverData])

  const [dialogData, setDialogData] = useState({ ...initDailogData })

  const datetimePickerProp = useMemo(() => ({
    label: "เวลานัด",
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData.AptTm,
    onChange: (newValue) => { setDialogData(o => ({ ...o, AptTm: newValue })) },
    renderInput: (params) => <TextField size="small" sx={{ width: 220 }}  {...params} required={true} error={!dialogData.AptTm} />,
  }), [dialogData])

  const checkB4Confirm = useCallback(()=>{
    if (selectionDrvs.length === 0 && !dialogData.DrvId) {
      alertError("กรุณาเลือกพนักงานขับรถก่อน")
      return false
    }

    const numDrvId = selectionDrvs.length===0? 1 : selectionDrvs.length
    //row might be null in init
    if(row?.JobOrdIds.length < numDrvId * +dialogData.NumJobOrd){
      alertError("จำนวนงานทั้งหมดมีน้อยกว่าจำนวนใบงานที่จะจ่าย")
      return false
    }
    return true
  }, [selectionDrvs, dialogData, row])
  const handleOk = useCallback(() => {
    if (!checkB4Confirm()) return 
    confirmSelect({ row, data: { ...dialogData }, isPrint: false })
    setDialogOpen(false)
  }, [dialogData, row, confirmSelect, setDialogOpen, checkB4Confirm])

  const handleOkWithPrint = useCallback(() => {
    if (!checkB4Confirm()) return
    confirmSelect({ row, data: { ...dialogData }, isPrint: true })
    setDialogOpen(false)
  }, [dialogData, row, confirmSelect, setDialogOpen, checkB4Confirm])

  useEffect(() => {
    if (dialogOpen) {
      let isLoc = 0
      let isRtn = 0
      if ([1, 2].includes(row?.ShpmTypId)) {
        isLoc = 1
        isRtn = 1
      } else {
        isLoc = 1
      }

      let aptTm = row.AptTm ? dayjs(row.AptTm) : row.TakeDteSt ? dayjs(row.TakeDteSt) : dayjs()
      console.log("in AddDataDialog useEffect row::", row)


      // const drvId = selectionDrvs?.length === 1? selectionDrvs[0] : null 
      setDialogData(o => ({
        ...o,
        IsTake: 1,
        IsLoc: isLoc,
        IsRtn: isRtn,
        AptTm: aptTm
      }))
    } else {
      setDialogData({ ...initDailogData })
    }
  }, [dialogOpen, row, initDailogData, selectionDrvs])

  return (

    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="ข้อมูลการจ่ายงานและเงิน"
      />
      <DialogContent>
        <BoxFC mt={2} sx={{ gap: 2 }}>
          <Typography variant="h6">การจ่ายงาน</Typography>
          {
            selectionDrvs.length === 0 ?
              <ComboBox sx={{ width: 220 }} options={msData.driverOnlyCombo} label={"พนักงานขับรถ"}
                selectedId={dialogData.DrvId}
                setSelectedId={(id) => setDialogData(o => ({ ...o, DrvId: id }))}
              />
              :

              <BoxFR>
                <Typography variant="h6">พนักงานขับรถ:</Typography>
                <Typography variant="h6" color={blue[800]}>{drvNm}</Typography>
              </BoxFR>
          }

          <DateTimePickerCustomTime {...datetimePickerProp} />
          <BoxFR>
            <CheckboxFormControl label={"รับ (ตู้/สินค้า)"} checked={dialogData.IsTake ? true : false}
              onChange={e => { setDialogData(o => ({ ...o, IsTake: e.target.checked ? 1 : 0 })) }} />
            <CheckboxFormControl label={"ส่ง (ตู้/สินค้า)"} checked={dialogData.IsLoc ? true : false}
              onChange={e => { setDialogData(o => ({ ...o, IsLoc: e.target.checked ? 1 : 0 })) }} />
            <CheckboxFormControl label={"คืน (ตู้/สินค้า)"} checked={dialogData.IsRtn ? true : false}
              onChange={e => { setDialogData(o => ({ ...o, IsRtn: e.target.checked ? 1 : 0 })) }} />
          </BoxFR>
          <TextField size="small" variant="outlined" label="จำนวนใบงาน" type="number" sx={{width: 220}}
          value={dialogData.NumJobOrd}
          onChange={e=>setDialogData(o=>({...o, NumJobOrd:e.target.value}))}/>
          <Divider width="100%" sx={{ mt: 1 }} />
          <Typography variant="h6" mt={1}>การจ่ายเงิน</Typography>
          <BoxFR>
            <TextField size="small" variant="outlined"
              label="จำนวนเงิน" type="number"
              value={dialogData.Amnt} onChange={e => setDialogData(o => ({ ...o, Amnt: +e.target.value }))}
              onFocus={(e) => e.target.select()}
              sx={{ width: 130 }}
              inputProps={{ style: { textAlign: 'right' } }}
              InputProps={{ endAdornment: <InputAdornment position="end">บาท</InputAdornment> }} />

            <ButtonGroup variant="outlined" >
              <Button sx={{ px: 1 }} variant={dialogData.IsCash === 1 ? "contained" : "outlined"}
                onClick={() => setDialogData(o => ({ ...o, IsCash: 1 }))}>เงินสด</Button>
              <Button sx={{ px: 1 }} variant={dialogData.IsCash === 0 ? "contained" : "outlined"}
                onClick={() => setDialogData(o => ({ ...o, IsCash: 0 }))}>โอน</Button>
            </ButtonGroup>
            <TextFieldWithOption
              size="small" label="รายละเอียด" value={dialogData.Dscp} sx={{ flex: 1 }}
              setValue={(value) => setDialogData(o => ({ ...o, Dscp: value }))}
              options={msData.serverData.PreText.DailyExpense} />
          </BoxFR>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"ตกลง"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleOk} >
        <Button variant="contained" color="secondary" onClick={handleOkWithPrint}>
          <PrintRounded sx={{ mr: 1 }} />
          ตกลงและพิมพ์ใบงาน
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default React.memo(AddDataDialog)