
export const initFilterData = {
  Name: null,
  POITypId: null,
}

export const initDialogData = {
  POIID: null,
  Name: "",
  SName: "",
  InvNm: "",
  Addr: "",
  Tel: "",
  Lat: null,
  Lng: null,
  Coord: null,
  points: null,
  PolyStr: null,
  Rad: null,
  Rmk: "",
  Prty: 100,
  POITypId: null,
}