import { Dialog, DialogContent } from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { initItemDialogData } from "./initData"
import { UserContext } from "contexts/UserContext"
import { pettyCashApiNm } from "./constant"
import { alertConfirmDelete, alertError } from "components/Alert"
import PayComponent from "./PayComponent"
import dayjs from "dayjs"

const AddItemDialog = ({ maxAmount, pcTrnsId, prPCTrnsData, pcItmId, dialogOpen, setDialogOpen, onFinish, isFromJobOrder }) => {
  const { user } = useContext(UserContext)
  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initItemDialogData })

  // const initItemDialogDataMemo = useMemo(() => ({
  //   ...initItemDialogData,
  //   PayTm: dayjs().format("YYYY-MM-DD HH:mm"),
  //   PCJnlId: msData.pcJournalCombo.find(item => item.IsDf)?.id || null
  // })
  //   , [msData.pcJournalCombo])

  const handleDialogOK = useCallback(() => {
    if (!dialogData.PayTm || !dialogData.Amnt ) {
      alertError("กรุณากรอกข้อมูลให้ครบถ้วน")
      return
    }
    const postData = {
      ...dialogData,
      PCItmTm: dialogData.PayTm, //Coz PayComponent use PayTm for PCItmTm
      PCItmId: pcItmId,
      PCTrnsId: pcTrnsId,
      AdmAccId: user.AccId,
      getFn: "getPettyCashDetail"
    }
    if (isFromJobOrder) {
      const postData = {
        itemArgs: {
          ...dialogData,
          AdmAccId: user.AccId,
          PCItmTm: dialogData.PayTm,
        },
      }
      onFinish({...dialogData, ExpNm: msData.expObj[dialogData.ExpId]?.ExpNm, postData})
      setDialogOpen(false)
    } else {
      ax.post(pcItmId ? pettyCashApiNm.updatePCItem : pettyCashApiNm.insertPCItem, postData).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false)
        }
      })
    }
  }, [ax, dialogData, onFinish, setDialogOpen, user.AccId, pcTrnsId, pcItmId, isFromJobOrder, msData.expObj])

  const handleDeletePCItem = useCallback(() => {
    alertConfirmDelete(() => {
      ax.post(pettyCashApiNm.deletePCItem, { PCItmId: pcItmId, getArgs: { PCTrnsId: pcTrnsId } }).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false)
        }
      })
    })
  }, [ax, pcItmId, pcTrnsId, onFinish, setDialogOpen])
  useEffect(() => {
    if (dialogOpen) {
      if (pcItmId) {
        ax.post(pettyCashApiNm.getPettyCashItem, { PCItmId: pcItmId }).then(value => {
          if (value.data && value.data.length === 1) {
            setDialogData({
              ...value.data[0],
              PayTm: value.data[0].PCItmTm, // use PayTm for PCItmTm coz PayComponent use PayTm
            })
          }
        })
      } else if(prPCTrnsData) {
        console.log("prPCTrnsData", prPCTrnsData)
        setDialogData({
          ...prPCTrnsData,
          isJobOrdIdCorrect: prPCTrnsData?.JobOrdId ? true : null,
          LocText: !prPCTrnsData?.JobOrdId ? "" : `${prPCTrnsData?.TakePlc || ""}-${prPCTrnsData?.Loc || ""}-${prPCTrnsData?.RtnPlc ?? ""}`,
          Amnt: maxAmount??"",
          PayTm: dayjs().format("YYYY-MM-DD HH:mm"),
        })
      } else {
        setDialogData({ ...initItemDialogData, PayTm: dayjs().format("YYYY-MM-DD HH:mm") })
      }
    } else {
      setDialogData({ ...initItemDialogData })
    }
  }, [dialogOpen, ax, pcItmId, prPCTrnsData, maxAmount])

  // console.log('AddItemDialog', dialogData)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="รับใบเสร็จ-การใช้เงิน/รับเงิน"
        onDeleteClick={pcItmId ? handleDeletePCItem : null}
      />
      <DialogContent>
        <BoxFC pt={2} >
          <PayComponent dialogData={dialogData} setDialogData={setDialogData} isItemDialog={true} />
        </BoxFC>

      </DialogContent>
      <DialogFooter okText={"บันทึกข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOK} />
    </Dialog>
  )
}

export default React.memo(AddItemDialog)