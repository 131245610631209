import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const CheckboxFormControl = ({sx, label, checked, onChange, icon, checkedIcon, disabled}) => {

  return (
    <FormControlLabel sx={{m:0, verticalAlign:"middle", ...sx}}
      label={label}
      control={
        <Checkbox sx={{mr:1, p:0}}
          checked={checked}
          onChange={onChange}
          icon={icon}
          checkedIcon={checkedIcon}
          disabled={disabled}
        />
      }
    />
  )
}

export default React.memo(CheckboxFormControl)