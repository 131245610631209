import { Dialog, DialogContent, TextField } from "@mui/material"
import { DesktopDatePicker } from "@mui/x-date-pickers-pro"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import dayjs from "dayjs"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { maintenanceApiNm } from "./constant"
import { GlobalStateContext } from "contexts/GlobalStateContext"

const initDialogData = {
  MtChkPlnNm: "",
  Rev: 1,
  DocNo: "",
  DocDte: dayjs()
}
const PlanDialog = ({ title, selectedChkPlnId, dialogOpen, setDialogOpen, onFinish }) => {
  const [dialogData, setDialogData] = useState({ ...initDialogData })
  const {ax} = useContext(GlobalStateContext)

  const handleDialogOK = useCallback(() => {
    onFinish(dialogData)
    setDialogOpen(false)
  }, [onFinish, setDialogOpen, dialogData])

  useEffect(() => {
    if (dialogOpen) {
      if (selectedChkPlnId) {
        ax.post(maintenanceApiNm.getCheckupPlan, { MtChkPlnId: selectedChkPlnId }).then(value=>{
          if(value.data && value.data.length === 1){
            setDialogData(value.data[0])
          }
        })
      }
    } else {
      setDialogData({ ...initDialogData })
    }
  }, [dialogOpen, ax, selectedChkPlnId])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={title}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} >
          <TextField
            autoFocus size="small" fullWidth label="ชื่อแบบฟอร์ม"
            value={dialogData.MtChkPlnNm || ""}
            onChange={(e) => { setDialogData(o => ({ ...o, MtChkPlnNm: e.target.value })) }} />
          <TextField
            size="small" fullWidth label="เลขที่เอกสาร"
            value={dialogData.DocNo || ""}
            onChange={(e) => { setDialogData(o => ({ ...o, DocNo: e.target.value })) }} />
          <TextField
            size="small" fullWidth label="Revision" type="number"
            value={dialogData.Rev || ""}
            onChange={(e) => { setDialogData(o => ({ ...o, Rev: e.target.value })) }} />
          <DesktopDatePicker label="วันที่" value={dialogData.DocDte}
            renderInput={(params) => <TextField {...params} fullWidth size="small" />}
            onChange={(newValue) => setDialogData(o => ({ ...o, DocDte: newValue }))} />

        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"ตกลง"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOK} />
    </Dialog>
  )
}

export default React.memo(PlanDialog)