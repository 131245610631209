import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
import { Box, Button, ButtonGroup, CircularProgress, Dialog, DialogContent, InputAdornment, MenuItem, Paper, TextField, Typography } from "@mui/material"
import ModAccName from "components/ModAccName";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import { dailyExpenseColNm } from "constants/dailyExpenseConstant";
import { initDialogData } from "./initData";
import { dailyExpApiNm } from "constants/dailyExpenseConstant";
import useDialogOpen from "hooks/useDialogOpen";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { UserContext } from "contexts/UserContext";
import { BoxFC, BoxFR } from "components/BoxCustom";
import '@mui/lab';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { jobOrderApiNm } from "constants/jobOrderConstant";
import { alertConfirmDelete, alertError } from "components/Alert";
import dayjs from "dayjs";
import { selectOnFocus, validate } from "utils";
import PaperComponent from "components/PaperComponent";
import CheckboxFormControl from "components/CheckboxFormControl";
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime";
import { getPOIPlace } from "utils/getPOIPlace";
import { ArrowDropDownCircleRounded } from "@mui/icons-material";
import PopoverDefault from "components/PopoverDefault";

const DailyExpenseDialog = ({ dialogOpen, setDialogOpen, selectedId, onFinish, lastFilter, selectedDrvId, selectedJobOrdId }) => {
  const { ax, msData } = useContext(GlobalStateContext);
  const { user } = useContext(UserContext);
  const [dialogData, setDialogData] = useState(initDialogData)
  const [isChkJobOrdId, setisChkJobOrdId] = useState(true);
  // const [isShowChange, setIsShowChange] = useState(false);
  const [changeAmnt, setChangeAmnt] = useState(0);
  const [isWithdraw, setIsWithDraw] = useState(false);
  const [isLoadingJobOrd, setIsLoadingJobOrd] = useState(false);
  const [closeDialogAfterSave, setCloseDialogAfterSave] = useState(true)
  // const [isShowWithdraw, setIsShowWithDraw] = useState(false);
  // const [widthdrawAmnt, setWithdrawAmnt] = useState(0);
  const [payType, setPayType] = useState(null)

  const modifiedData = useCallback((data) => {
    if (data.Amnt < 0) {
      setPayType("OUT")
      data.Amnt *= -1
    }
    else if (data.Amnt > 0) {
      setPayType("IN")
    }

    return data
  }, [])

  const initWithExpDte = useMemo(() => {
    if (dialogOpen) {

      return { ...initDialogData, ExpDte: dayjs(), DrvId: selectedDrvId, JobOrdId: selectedJobOrdId, PayAccId: user.AccId }
    }
    else
      return { ...initDialogData }
  }, [dialogOpen, selectedDrvId, selectedJobOrdId, user.AccId])

  const payName = useMemo(()=>{
      const acc = dialogData.PayAccId? msData.accountObj[dialogData.PayAccId]: null
      return acc? `${acc.FName} ${acc.LName} (${acc.NName})`: "-"
    
  }, [dialogData.PayAccId, msData.accountObj])

  useDialogOpen(dialogOpen, selectedId, dailyExpApiNm.getDailyExpense, "DalyExpId", setDialogData, initWithExpDte, modifiedData)

  const validation = useMemo(() => ({
    ExpDte: dialogData.ExpDte === null || !dayjs(dialogData.ExpDte).isValid(),
  }), [dialogData])

  const dialogDataProp = useCallback((name) => ({
    variant: "outlined",
    size: "small",
    name: name,
    label: dailyExpenseColNm[name],
    value: dialogData[name] || "",
    onChange: (e) => { setDialogData((oldState) => ({ ...oldState, [name]: e.target.value })) }
  }), [dialogData])

  const dialogTextTimeProp = useCallback((name) => ({
    ...dialogDataProp(name),
    value: (dialogData[name] && dayjs(dialogData[name]).format("DD/MM/YYYY HH:mm")) || ""
  }), [dialogDataProp, dialogData])

  const dialogJobOrdProp = useMemo(() => ({
    ...dialogDataProp("JobOrdId"),
    disabled: selectedJobOrdId ? true : false,
    onChange: (e) => {
      const value = e.target.value;
      setisChkJobOrdId(value ? false : true)
      setDialogData(oldState => ({ ...oldState, JobOrdId: value }))
    }
  }), [dialogDataProp, selectedJobOrdId])

  const dialogDataSelectProp = useCallback((name) => ({
    ...dialogDataProp(name),
    value: dialogData[name] || 0,
    onChange: (e) => { setDialogData((oldState) => ({ ...oldState, [name]: e.target.value || null })) }
  }), [dialogDataProp, dialogData])

  const datePickerProp = useCallback((name) => ({
    label: dailyExpenseColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(oldState => ({ ...oldState, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" fullWidth  {...params} error={validation[name]} />,
  }), [dialogData, validation])

  const checkJobOrdId = useCallback((e) => {
    const value = e.target.value;
    if (value === "") return;
    if (!/[0-9]/.test(e.target.value)) {
      alertError("เลขที่ใบงานเป็นตัวเลขเท่านั้น");
      return
    }
    const jobOrdId = parseInt(value)
    setIsLoadingJobOrd(true)
    ax.post(jobOrderApiNm.getDailyJob, { JobOrdId: jobOrdId }, false).then(value => {
      setIsLoadingJobOrd(false)
      if (value.data && value.data.length > 0) {
        const jobOrdData = value.data[0];
        setisChkJobOrdId(true)
        setDialogData(oldState => ({
          ...oldState,
          AptTm: jobOrdData.AptTm,
          TakePlc: jobOrdData.TakePlc,
          RtnPlc: jobOrdData.RtnPlc,
          Loc: jobOrdData.Loc,
          TakePOIId: jobOrdData.TakePOIId,
          RtnPOIId: jobOrdData.RtnPOIId,
          LocPOIId: jobOrdData.LocPOIId,
          ContSizeFull: jobOrdData.ContSizeFull
        }))
      } else {
        alertError("ไม่พบเลขที่ใบงานนี้")
        return
      }
    })
  }, [ax])

  const insertUpdateDailyExpense = useCallback((argsArr) => {

    const jsonData = { argsArr: argsArr, getArgs: lastFilter }

    ax.post(dailyExpApiNm.insertDailyExpenseBulk, jsonData).then(value => {
      if (value.data) {

        console.log("insertUpdateDailyExpense call onFinish", value.data)
        onFinish(value.data)
      }
    })
  }, [ax, lastFilter, onFinish])

  const deleteDailyExpense = useCallback((id) => {
    alertConfirmDelete(() => {
      ax.post(dailyExpApiNm.deleteDailyExpense, { DalyExpId: id, getArgs: lastFilter }).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false);
        }
      })
    })
  }, [ax, lastFilter, onFinish, setDialogOpen])

  // const handleChkClrDteChange = useCallback((e) => {
  //   let clrDte = null
  //   let clrAccId = null
  //   if (e.target.checked) {
  //     clrDte = dayjs().toString("YYYY-MM-DD HH:mm:00")
  //     clrAccId = user.AccId
  //   }
  //   setDialogData(oldData => ({ ...oldData, ClrDte: clrDte, ClrAccId: clrAccId }))
  // }, [user.AccId])

  const onOkClick = useCallback(() => {
    if (!payType) {
      alertError(`กรุณาเลือก "รับ" หรือ "จ่าย"`)
      return
    }
    if (validate(validation)) {
      const postData = { ...dialogData}
      const argsArr = []
      if (payType === "OUT") postData.Amnt = postData.Amnt * -1
      argsArr.push(postData)
      // insertUpdateDailyExpense(postData)
      if (+changeAmnt > 0) {
        argsArr.push({
          ...dialogData,
          DalyExpId: 0,
          ExpDte: dayjs().format("YYYY-MM-DD HH:mm:00"),
          Amnt: (+changeAmnt),
          Rmk: "เงินทอน",
          ClrDte: dayjs().format("YYYY-MM-DD HH:mm:00")
        })
        // insertUpdateDailyExpense()
      }
      if (isWithdraw) {
        argsArr.push({
          ...dialogData,
          DalyExpId: 0,
          ExpDte: dayjs().format("YYYY-MM-DD HH:mm:00"),
          Amnt: (+changeAmnt) * -1,
          Rmk: "เงินทอนลงเบิก",
          ClrDte: dayjs().format("YYYY-MM-DD HH:mm:00")
        })
        // insertUpdateDailyExpense()
      }
      if (closeDialogAfterSave) {
        setDialogOpen(false);
      }
      insertUpdateDailyExpense(argsArr)
    }
  }, [dialogData, validation, changeAmnt, insertUpdateDailyExpense
    , payType, isWithdraw, closeDialogAfterSave, setDialogOpen])

  const handleSetIsCash = useCallback((value) => () => {
    setDialogData(oldData => ({ ...oldData, IsCash: value }))
  }, [])

  const handleSetPayType = useCallback((payType) => () => {
    setPayType(payType)
    if (payType === "IN") {
      setDialogData(o => {
        return o.IsPaid ? { ...o, IsPaid: 0 } : o
      })
    }
  }, [])

  const handleChceckBoxDscpChange = useCallback((item) => (e) => {
    if (e.target.checked) {
      setDialogData(o => {

        const dscps = o.Dscp.trim().length === 0 ? [] : o.Dscp.split(",").map(item => item.trim())
        dscps.push(item)
        return {
          ...o,
          Dscp: dscps.join(", ")
        }
      })
    } else {
      setDialogData(o => ({
        ...o,
        Dscp: o.Dscp.split(",").map(item => item.trim()).filter(i => i !== item).join(", ")
      }))
    }
  }, [])

  const handleClrDteChange = useCallback((newValue) => {
    setDialogData(o => ({
      ...o,
      ClrDte: newValue,
      ClrAccId: newValue ? user.AccId : null,
    }))
  }, [user.AccId])
  useEffect(() => {
    if (dialogOpen) {
      setChangeAmnt(0);
      setIsWithDraw(false)
      setPayType(null);
    } else {
      setDialogData(initDialogData)
    }
  }, [dialogOpen])
  console.log("DailyExpenseDialog render ---- dialogData ::", dialogData)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth="md" >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={selectedId > 0 ? "แก้ไขรายการ" : "เพิ่มรายการ"}
        onDeleteClick={selectedId > 0 ? () => deleteDailyExpense(selectedId) : null}
      />
      <DialogContent sx={{ backgroundColor: "#f6f6f6" }}>
        <BoxFR mt={3} justifyContent="space-evenly" >
          <Paper sx={{ px: 2, pb: 2, pt: 1, width: 350 }}>
            <Typography variant="h6" >ข้อมูล รับ/จ่าย เงิน</Typography>

            <BoxFC sx={{ gap: 1 }}>
              <BoxFR sx={{p:1}}>
                <Typography fontWeight="bold">ผู้ทำรายการ:</Typography>
                <Typography fontWeight="bold" color="primary">{payName}</Typography>
              </BoxFR>
              <DateTimePickerCustomTime {...datePickerProp("ExpDte")} />
              <BoxFR alignItems="center">
                <TextField {...dialogJobOrdProp} sx={{ flexGrow: 1 }} onBlur={checkJobOrdId} />
                <CheckCircleIcon fontSize="large" color={isChkJobOrdId ? "success" : "disabled"} />
              </BoxFR>
              <TextField {...dialogDataSelectProp("DrvId")} select disabled={selectedDrvId ? true : false}>
                <MenuItem value={0}>ไม่ระบุ</MenuItem>
                {msData.driverOnlyCombo.map(item => (
                  <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                ))}
              </TextField>
              <BoxFR>
                <ButtonGroup variant="outlined" >
                  <Button sx={{ px: 1 }} variant={payType === "IN" ? "contained" : "outlined"} onClick={handleSetPayType("IN")}>รับ</Button>
                  <Button sx={{ px: 1 }} variant={payType === "OUT" ? "contained" : "outlined"} onClick={handleSetPayType("OUT")}>จ่าย</Button>
                </ButtonGroup>
                <ButtonGroup variant="outlined" >
                  <Button sx={{ px: 1 }} variant={dialogData.IsCash === 1 ? "contained" : "outlined"} onClick={handleSetIsCash(1)}>เงินสด</Button>
                  <Button sx={{ px: 1 }} variant={dialogData.IsCash === 0 ? "contained" : "outlined"} onClick={handleSetIsCash(0)}>โอน</Button>
                </ButtonGroup>

              </BoxFR>
              <BoxFR>
                <TextField sx={{ width: 300 }} {...dialogDataProp("Dscp")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" >
                        <PopoverDefault icon={<ArrowDropDownCircleRounded />} >
                          <Paper sx={{ p: 1 }}>
                            <BoxFC sx={{ gap: 1 }}>
                              {msData.serverData.PreText.DailyExpense.map((item, index) => {
                                return (
                                  <CheckboxFormControl key={index} label={item} checked={dialogData.Dscp?.indexOf(item.trim()) >= 0}
                                    onChange={handleChceckBoxDscpChange(item)} />
                                )
                              })}
                            </BoxFC>
                          </Paper>
                        </PopoverDefault>
                        {/* <IconButton disableRipple={true} sx={{ p: 0 }} edge="end" onClick={e => setAnchorEl(e.currentTarget)}> */}

                        {/* </IconButton> */}
                      </InputAdornment>
                    )
                  }}
                />
                {/* <TextFieldWithOption {...dialogDataProp("Dscp")} sx={{width: 180}}
                  setValue={(value) => setDialogData(o => ({ ...o, Dscp: value }))}
                  options={msData.serverData.PreText.DailyExpense || []} /> */}
                <TextField {...dialogDataProp("Amnt")} type="number"
                  value={dialogData.Amnt}
                  inputProps={{ style: { textAlign: "right" } }}
                  onFocus={selectOnFocus} />

              </BoxFR>



              <BoxFR sx={{ gap: 1 }} justifyContent="flex-start">
                <TextField {...dialogDataProp("Rmk")} sx={{ width: 300 }} />
                <TextField variant="outlined" size="small" label="เงินทอน" value={changeAmnt}
                  inputProps={{ style: { textAlign: "right" } }}
                  onChange={(e) => setChangeAmnt(e.target.value)} />
              </BoxFR>
              <BoxFR>
                <CheckboxFormControl label="จ่ายเงินแล้ว" checked={dialogData.IsPaid ? true : false}
                  onChange={(e) => setDialogData(o => ({ ...o, IsPaid: e.target.checked ? 1 : 0 }))} disabled={payType === "IN"} />
                <Box flex={1} />
                <CheckboxFormControl label="เงินทอนลงเบิก" checked={isWithdraw}
                  onChange={(e) => setIsWithDraw(e.target.checked)} />
              </BoxFR>
              <Box>
                {/* <BoxFR >
                  <FormControlLabel label="Clear"
                    control={<Checkbox checked={dialogData.ClrDte != null} onChange={handleChkClrDteChange} />} />
                  <TextField {...dialogTextTimeProp("ClrDte")} />
                </BoxFR> */}
                <DateTimePickerCustomTime {...datePickerProp("ClrDte")} onChange={handleClrDteChange} />
                <Typography variant="caption" sx={{ display: "block" }}>
                  {dialogData.ClrNNm && ` Clear โดย ${dialogData.ClrNNm}`}
                </Typography>
              </Box >
              {/* <BoxFR height={30}>
                <Button variant="contained" size="small" sx={{ minWidth: 80 }} onClick={onWithdrawBtnClick}>ลงเบิก</Button>
                {
                  isShowWithdraw &&
                  <TextField variant="outlined" size="small" value={widthdrawAmnt}
                    inputProps={{ style: { textAlign: "right" } }}
                    onChange={(e) => setWithdrawAmnt(e.target.value)} fullWidth />
                }
              </BoxFR> */}
            </BoxFC>
          </Paper>
          <Paper sx={{ px: 2, pb: 2, pt: 1, width: 350, alignSelf: "stretch" }}>
            <BoxFR>
              <Typography variant="h6">ข้อมูลงาน</Typography>
              {isLoadingJobOrd && <CircularProgress />}
            </BoxFR>
            <BoxFC mt={2} sx={{ gap: 1 }}>
              <TextField {...dialogTextTimeProp("AptTm")} disabled />
              <TextField {...dialogDataProp("TakePlc")} disabled value={getPOIPlace(dialogData, "TAKE")} />
              <TextField {...dialogDataProp("Loc")} disabled value={getPOIPlace(dialogData, "LOC")} />
              <TextField {...dialogDataProp("RtnPlc")} disabled value={getPOIPlace(dialogData, "RTN")} />
              <TextField {...dialogDataProp("ContSizeFull")} disabled />
              <TextField {...dialogDataProp("JobOrdRmk")} disabled multiline rows={3} />
            </BoxFC>
          </Paper>
        </BoxFR>
        <CheckboxFormControl sx={{ ml: 5, mt: 2 }} label="ปิดหน้าต่างหลังจากบันทึก" checked={closeDialogAfterSave}
          onChange={e => setCloseDialogAfterSave(e.target.checked)} />
        {
          selectedId > 0 && <ModAccName modDte={dialogData.ModDte} modAcc={dialogData.ModAcc} />
        }
      </DialogContent>
      <DialogFooter okText={selectedId > 0 ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={onOkClick} />
    </Dialog>
  )
}

export default React.memo(DailyExpenseDialog)