import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Popover, Typography } from "@mui/material";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { UserContext } from "contexts/UserContext";
import React, { useContext, useState } from "react";
import { useHistory } from 'react-router-dom';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
// import ArticleIcon from '@mui/icons-material/Article';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import AccountDialog from "components/AccountDialog/AccountDialog";
import UserPrefDialog from "components/UserPreferenceDialog/UserPreferenceDialog";
import { alertSuccess } from "components/Alert";
import { ACC_TYP_EMP, DIALOG_MODE_PROFILE } from "constants/accountConstant";
import { useCallback } from "react";
import { BoxFR } from "components/BoxCustom";
import { loadMSData } from "utils";

const listItemSx = { minWidth: 0, px: 1 }

const ProfileButton = () => {

  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const { ax, setMSData, setBadge } = useContext(GlobalStateContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false)
  const [userPrefDialogOpen, setUserPrefDialogOpen] = useState(false)
  const open = Boolean(anchorEl);


  const handleSignOut = useCallback(() => {
    ax.post("/account/logout", {}).then(value => {
      console.log(`logout value::`, value)
      setUser(null);
      history.replace("/");
    })
  }, [ax, history, setUser])

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [])

  const handleNameClick = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, [])

  const onFinishDialog = useCallback(() => {
    setDialogOpen(false);
    alertSuccess("บันทึกข้อมูลเรียบร้อยแล้ว")
  }, [])

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true)
  }, [])

  const handleOpenUserPrefDialog = useCallback(() => {
    setUserPrefDialogOpen(true)
  }, [])

  return (

    <BoxFR sx={{ gap: 0, flex: "0 1 100px" }}>

      <Typography noWrap sx={{ textOverflow: "ellipsis" }}>{`${user.FName} ${user.LName}`}</Typography>
      <IconButton sx={{ p: 0 }} onClick={handleNameClick}>
        <MoreVertRoundedIcon sx={{ color: "white" }} />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Paper>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleOpenDialog}>
                <ListItemIcon sx={listItemSx}>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="แก้ไขข้อมูลส่วนตัว" />
              </ListItemButton>
            </ListItem>
            {user.AccTypId === ACC_TYP_EMP &&
              <ListItem disablePadding>
                <ListItemButton onClick={handleOpenUserPrefDialog}>
                  <ListItemIcon sx={listItemSx}>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="ตั้งค่าส่วนตัว" />
                </ListItemButton>
              </ListItem>
            }
            <ListItem disablePadding>
              <ListItemButton onClick={() => loadMSData(ax, setMSData, setUser, setBadge)}>
                <ListItemIcon sx={listItemSx}>
                  <CloudSyncIcon />
                </ListItemIcon>
                <ListItemText primary="Master Data" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleSignOut}>
                <ListItemIcon sx={listItemSx}>
                  <LogoutRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="ออกจากระบบ" />
              </ListItemButton>
            </ListItem>
          </List>
        </Paper>
      </Popover>
      <AccountDialog
        dialogOpen={dialogOpen}
        selectedAccId={user.AccId}
        setDialogOpen={setDialogOpen}
        onFinish={onFinishDialog}
        mode={DIALOG_MODE_PROFILE} />
      <UserPrefDialog
        dialogOpen={userPrefDialogOpen}
        setDialogOpen={setUserPrefDialogOpen}
      />
    </BoxFR>
  )
}

export default React.memo(ProfileButton)