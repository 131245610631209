import { billingNoteColNm } from "./billingNoteConstant"
import {  jobOrderColNm } from "./jobOrderConstant"

export const invoiceApiNm = {
  getInvoice: "/invoice/getInvoice",
  getInvoiceTable: "/invoice/getInvoiceTable",
  getInvoiceDetail: "/invoice/getInvoiceDetail",
  getInvoiceTableV2: "/invoice/getInvoiceTableV2",
  getInvoiceTableForClient: "/invoice/getInvoiceTableForClient",
  getInvoiceDetailForClient: "/invoice/getInvoiceDetailForClient",
  generateInvoiceNo: "/invoice/generateInvoiceNo",
  getInvoiceTarget: "/invoice/getInvoiceTarget",
  insertInvoice: "/invoice/insertInvoice",
  insertInvoiceWithExpense: "/invoice/insertInvoiceWithExpense",
  insertInvoiceWithJobOrder: "/invoice/insertInvoiceWithJobOrder",
  insertInvoiceWithNewExpense: "/invoice/insertInvoiceWithNewExpense",
  uploadFile: "/invoice/uploadFile",
  updateFileName: "/invoice/updateFileName",
  deleteFile: "/invoice/deleteFile",
  updateInvoice: "/invoice/updateInvoice",
  updateInvoiceRmk: "/invoice/updateInvoiceRmk",
  updateInvoiceColumn: "/invoice/updateInvoiceColumn",
  deleteInvoice: "/invoice/deleteInvoice",
  sendEmail: "/invoice/sendEmail",
  getSendEmailData: "/invoice/getSendEmailData"
}


export const invoiceColNm =  {
  InvId: "รหัสใบแจ้งหนี้",
  InvNo: "เลขที่ใบแจ้งหนี้",
  InvDte: "วันที่ใบแจ้งหนี้",
  RefNo: "เลขที่อ้างอิง",
  RefNo2: "เลขที่อ้างอิง2",
  CusId: "ลูกค้า",
  BNNo: billingNoteColNm.BNNo,
  SndMailDte: "วันที่ส่งEmail",
  SndDocDte: "วันที่ส่งเอกสาร",
  AptTm: jobOrderColNm.AptTm,
  JobNo: jobOrderColNm.JobNo,
  JobNos: jobOrderColNm.JobNo,
  Bkgs: jobOrderColNm.Bkg,
  WhtPr: "หัก ณ ที่จ่าย",
  NumJobOrd: "จำนวนใบงาน",
  Bkg: jobOrderColNm.Bkg,
  ContNo: jobOrderColNm.ContNo,
  OwnRcptAmnt: "ใบเสร็จบริษัท",
  CusRcptAmnt: "ใบเสร็จลูกค้า",
  CostAmnt: "ต้นทุน",
  Total: "รวมรายรับ",
  Cost: "ต้นทุน",
  BillNm: "ชื่อลูกค้า",
  BillAddr: "ที่อยู่",
  BillTaxId: "เลขประจำตัวผู้เสียภาษี",
  BillBrchNo: "เลขที่สาขา",
  Rmk: "หมายเหตุ",
  PrntTxt: "ข้อความเพิ่มเติม",
  ModDte: "วันที่แก้ไข",
  ModAcc: "ผู้แก้ไข",
}