import { expenseColNm } from "./expenseConstant"
import { jobOrderColNm } from "./jobOrderConstant"
import { invoiceColNm } from "./invoiceConstant"
import { billingNoteColNm } from "./billingNoteConstant"
import { receiveVoucherColNm } from "./receiveVoucherConstant"
import { receiptColNm } from "./receiptConstant"

export const expenseJobOrderApiNm = {
  getExpenseJobOrder: "/expense-job-order/getExpenseJobOrder",
  getExpenseJobOrderDetail: "/expense-job-order/getExpenseJobOrderDetail",
  getExpenseJobOrderGroupByExp: "/expense-job-order/getExpenseJobOrderGroupByExp",
  getFinJobOrder: "/expense-job-order/getFinJobOrder",
  getFinJobOrderV2: "/expense-job-order/getFinJobOrderV2",
  getJobOrderWithExpense: "/expense-job-order/getJobOrderWithExpense",
  getContainerExpense: "/expense-job-order/getContainerExpense",
  setNoInvoice: "/expense-job-order/setNoInvoice",
  insertUpdateExpenseJobOrderBulk: "/expense-job-order/insertUpdateExpenseJobOrderBulk",
  insertExpenseJobOrder: "/expense-job-order/insertExpenseJobOrder",
  insertFromExpenseJob: "/expense-job-order/insertFromExpenseJob",
  updateExpenseJobOrderRmk: "/expense-job-order/updateExpenseJobOrderRmk",
  updateExpenseJobOrder: "/expense-job-order/updateExpenseJobOrder",
  deleteExpenseJobOrder: "/expense-job-order/deleteExpenseJobOrder"
}


export const expenseJobOrderColNm =  {
  JobSName: "ชื่องาน",
  ExpNm: expenseColNm.ExpNm,
  JobOrdId: jobOrderColNm.JobOrdId,
  AptTm: jobOrderColNm.AptTm,
  RtnDteSt: jobOrderColNm.RtnDteSt,
  LevTm: jobOrderColNm.LevTm,
  ArvTm: jobOrderColNm.ArvTm,
  Memo: jobOrderColNm.Memo,
  FinDte: jobOrderColNm.FinDte,
  ContNo: jobOrderColNm.ContNo,
  ContSize: jobOrderColNm.ContSize,
  ContTyp: jobOrderColNm.ContTyp,
  TlrTyp: jobOrderColNm.TlrTyp,
  TakePlc: jobOrderColNm.TakePlc,
  RtnPlc: jobOrderColNm.RtnPlc,
  Loc: jobOrderColNm.Loc,
  JobNo: jobOrderColNm.JobNo,
  Bkg: jobOrderColNm.Bkg,
  Vssl: jobOrderColNm.Vssl,
  Voy: jobOrderColNm.Voy,
  VatPrct: expenseColNm.VatPrct,
  WhtPrct: expenseColNm.WhtPrct,
  UntPr: expenseColNm.UntPr,
  InvNo: invoiceColNm.InvNo,
  InvDte: invoiceColNm.InvDte,
  BNNo: billingNoteColNm.BNNo,
  BNDte: billingNoteColNm.BNDte,
  RVNo: receiveVoucherColNm.RVNo,
  RVDte: receiveVoucherColNm.RVDte,
  RcptNo: receiptColNm.RcptNo,
  RcptDte: receiptColNm.RcptDte,
  Qty: "จำนวน",
  Total: "ราคารวม",
  Prty: expenseColNm.Prty,
  IsOwnRcpt: expenseColNm.IsOwnRcpt,
  Rmk: "หมายเหตุ",
  ModDte: "วันที่แก้ไข",
  ModAcc: "ผู้แก้ไข",
}