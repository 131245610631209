

import { jsPDF } from "jspdf";
// import { toBahtText } from "utils/toBahtText";
import "resource/font/THSarabun-normal"
import "resource/font/THSarabun-bold"

const contentSize = 11
const lineHeight = 0.155

export const printWht = ({ whtData }) => {

  console.log("whtData::", whtData)
  const pageSize = [8.3, 11.7];
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "in",
    format: pageSize
  });

  const startY = 0.2
  const startX = 0.4
  let yPos = startY
  let xPos = startX


  //------------------------------------------Start Content-------------------------------------------------------
  // doc.setLineWidth(0.01)
  doc.setLineWidth(0.005)
  doc.setFont("THSarabun", 'bold')
  doc.setFontSize(contentSize)
  doc.text("ฉบับที่ 1", xPos, yPos,)
  doc.setFont("THSarabun", "normal")
  doc.text("( สำหรับผู้ถูกหักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี )", xPos + 0.4, yPos,)

  xPos = pageSize[1] / 2 + startX
  doc.setLineWidth(0.005)
  doc.setFont("THSarabun", 'bold')
  doc.setFontSize(contentSize)
  doc.text("ฉบับที่ 2", xPos, yPos,)
  doc.setFont("THSarabun", "normal")
  doc.text("( สำหรับผู้ถูกหักภาษี ณ ที่จ่าย เก็บไว้เป็นหลักฐาน )", xPos + 0.4, yPos,)

  yPos += 0.08
  drawContent({ doc, whtData, startX: 0, startY: yPos, width: pageSize[1] / 2 })

  drawContent({ doc, whtData, startX: pageSize[1] / 2, startY: yPos, width: pageSize[1] / 2 })



  //------------------------------------------second page-------------------------------------------------------
  yPos = startY
  xPos = startX
  doc.addPage(pageSize, "landscape")
  doc.setLineWidth(0.005)
  doc.setFont("THSarabun", 'bold')
  doc.setFontSize(contentSize)
  doc.text("ฉบับที่ 3", xPos, yPos,)
  doc.setFont("THSarabun", "normal")
  doc.text("( สำหรับผู้หักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี )", xPos + 0.4, yPos,)

  xPos = pageSize[1] / 2 + startX
  doc.setLineWidth(0.005)
  doc.setFont("THSarabun", 'bold')
  doc.setFontSize(contentSize)
  doc.text("ฉบับที่ 4", xPos, yPos,)
  doc.setFont("THSarabun", "normal")
  doc.text("( สำหรับผู้หักภาษี ณ ที่จ่าย เก็บไว้เป็นหลักฐาน )", xPos + 0.4, yPos,)

  yPos += 0.08
  drawContent({ doc, whtData, startX: 0, startY: yPos, width: pageSize[1] / 2 })

  drawContent({ doc, whtData, startX: pageSize[1] / 2, startY: yPos, width: pageSize[1] / 2 })

  doc.output('dataurlnewwindow');

}

function drawContent({ doc, whtData, startX, startY, width }) {
  const height= 7.9
  const startContent = startX + 0.3
  const endContent = startX + width - 0.3
  const underlinepadding = 0.03
  let yPos = startY + lineHeight
  doc.setLineDashPattern()
  doc.rect(startX + 0.2, startY, width - 0.4, height)
  doc.setFont("THSarabun", 'bold')
  doc.setFontSize(contentSize + 3)
  doc.text("หนังสือรับรองการหักภาษี ณ ที่จ่าย", startX + width / 2, yPos, { align: 'center' })
  doc.setFont("THSarabun", 'normal')
  doc.setFontSize(contentSize)
  doc.text("เล่มที่ . . . . . . . . .", endContent, yPos, { align: 'right' })
  yPos += lineHeight
  doc.text("ตามมาตรา 50 ทวิ แห่งประมวลรัษฎากร", startX + width / 2, yPos, { align: 'center' })
  doc.text("เลขที่ . . . . . . . . .", endContent, yPos, { align: 'right' })
  yPos += lineHeight / 2

  const printAddrContent = (yPos, title, name, addr, taxId) => {
    doc.setFont("THSarabun", 'bold')
    doc.text(title, startContent, yPos)
    doc.text("เลขประจำตัวผู้เสียภาษีอากร :", endContent - 1, yPos, { align: 'right' })
    doc.setFont("THSarabun", 'normal')
    doc.text(taxId || "", endContent, yPos, { align: 'right' })
    yPos += lineHeight
    doc.setFont("THSarabun", 'bold')
    doc.text("ชื่อ", startContent, yPos)
    doc.setFont("THSarabun", 'normal')
    doc.text(name, startContent + 0.25, yPos)
    doc.setLineDashPattern([0.01, 0.05], 0)
    doc.line(startContent + 0.25, yPos + underlinepadding, endContent, yPos + underlinepadding)
    yPos += lineHeight
    doc.setFont("THSarabun", 'bold')
    doc.text("ที่อยู่", startContent, yPos)
    doc.line(startContent + 0.25, yPos + underlinepadding, endContent, yPos + underlinepadding)
    doc.setFont("THSarabun", 'normal')
    const addrs = doc.splitTextToSize(addr, width - 0.8)
    for (const addr of addrs) {
      doc.text(addr, startContent + 0.25, yPos)
      yPos += lineHeight
    }
    if (addrs.length === 1) {
      yPos += lineHeight
    }
    doc.line(startContent + 0.25, yPos - lineHeight + underlinepadding, endContent, yPos - lineHeight + underlinepadding)
    doc.setLineDashPattern()
    return yPos
  }
  //----------------------------------------- payer box --------------------------------------------------


  doc.roundedRect(startContent - 0.05, yPos, width - 0.5, lineHeight * 4 + lineHeight / 2, 0.05, 0.05)
  yPos += lineHeight

  yPos = printAddrContent(yPos, "ผู้มีหน้าที่หักภาษี ณ ที่จ่าย :", whtData.payer.name, whtData.payer.addr, whtData.payer.taxId)
  yPos += lineHeight * 3 / 4
  doc.roundedRect(startContent - 0.05, yPos - lineHeight, width - 0.5, lineHeight * 4 + lineHeight / 2, 0.05, 0.05)
  yPos = printAddrContent(yPos, "กระทำการแทนโดย :", whtData.agent.name, whtData.agent.addr, whtData.agent.taxId)
  yPos += lineHeight * 3 / 4
  doc.roundedRect(startContent - 0.05, yPos - lineHeight, width - 0.5, lineHeight * 5 + lineHeight / 2, 0.05, 0.05)
  yPos = printAddrContent(yPos, "ผู้ถูกหักภาษี ณ ที่จ่าย :", whtData.receiver.name, whtData.receiver.addr, whtData.receiver.taxId)
  doc.text("ลำดับที่ . . . . . . . . .", startContent, yPos)
  doc.text("ในแบบ แบบยื่น ภ.ง.ด. 53", startContent + 1.5, yPos)
  yPos += lineHeight
  // doc.roundedRect(startBox, boxAddrYPos, boxWidth, boxAddrHeight, 0.07, 0.07)

  const detailColW = 3
  const dateColW = 0.8
  const amntColW = 0.8
  const taxColW = 0.7

  const dateCol = startContent + detailColW
  const amntCol = dateCol + dateColW
  const taxCol = amntCol + amntColW
  yPos += lineHeight * 3 / 4
  const startTableY =  yPos - lineHeight
  doc.roundedRect(startContent - 0.05, startTableY, width - 0.5, lineHeight * 26, 0.05, 0.05)
  doc.setFont("THSarabun", 'bold')
  doc.text("ประเภทเงินได้พึงประเมินที่จ่าย", startContent + detailColW / 2, yPos + lineHeight / 2, { align: 'center' })
  doc.text("วัน เดือน ปี\nภาษี ที่จ่าย", dateCol + dateColW / 2, yPos, { align: 'center' })
  doc.text("จำนวน\nเงินที่จ่าย", amntCol + amntColW / 2, yPos, { align: 'center' })
  doc.text("ภาษีที่หัก\nและนำส่งไว้", taxCol + taxColW / 2, yPos, { align: 'center' })
  yPos += lineHeight * 2
  doc.line(startContent - 0.05, yPos - lineHeight / 2, endContent + 0.05, yPos - lineHeight / 2)
  yPos += lineHeight / 2
  doc.setFont("THSarabun", 'normal')
  doc.text("1. เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส ฯลฯ ตามมาตรา 40(1)", startContent, yPos)
  yPos += lineHeight
  doc.text("2. ค่าธรรมเนียม ค่านายหน้า ฯลฯ ตามมาตรา 40(2)", startContent, yPos)
  yPos += lineHeight
  doc.text("3. ค่าแห่งลิขสิทธิ์ ฯลฯ ตามมาตรา 40(3)", startContent, yPos)
  yPos += lineHeight
  doc.text("4. (ก) ค่าดอกเบี้ย ฯลฯ ตามมาตรา 40(4)(ก)", startContent, yPos)
  yPos += lineHeight
  doc.text("   (ข) เงินปันผล ส่วนแบ่งของกำไร ฯลฯ ตามมาตรา 40(4)(ข)", startContent, yPos)
  yPos += lineHeight
  doc.text("      (1) กรณีผู้ได้รับเงินปันผลได้รับเครดิตภาษี โดยจ่ายจาก", startContent, yPos)
  yPos += lineHeight
  doc.text("      กำไรสุทธิของกิจการที่ต้องเสียภาษีเงินได้นิติบุคคลในอัตราดังนี้", startContent, yPos)
  yPos += lineHeight
  doc.text("         (1.1) อัตราร้อยละ 30 ของกำไรสุทธิ", startContent, yPos)
  yPos += lineHeight
  doc.text("         (1.2) อัตราร้อยละ 25 ของกำไรสุทธิ", startContent, yPos)
  yPos += lineHeight
  doc.text("         (1.3) อัตราร้อยละ 20 ของกำไรสุทธิ", startContent, yPos)
  yPos += lineHeight
  doc.text("         (1.4) อัตราอื่น ๆ (ระบุ)........ของกำไรสุทธิ", startContent, yPos)
  yPos += lineHeight
  doc.text("      (2) กิจการที่ได้รับยกเว้นภาษีเงินได้นิติบุคคลซึ่ง", startContent, yPos)
  yPos += lineHeight
  doc.text("          ผู้รับเงินปันผลไม่ได้รับเครดิตภาษี", startContent, yPos)
  yPos += lineHeight
  doc.text("      (3) กำไรเฉพาะส่วนที่ได้รับยกเว้นไม่ต้องนำมารวมคำนวณภาษี", startContent, yPos)
  yPos += lineHeight
  doc.text("          เงินได้นิติบุคคลซึ่งผู้รับเงินปันผลไม่ได้รับเครดิตภาษี", startContent, yPos)
  yPos += lineHeight
  doc.text("5. การจ่ายเงินได้ที่ต้องหักภาษี ณ. ที่จ่าย ตามคำสั่งกรมสรรพากรที่", startContent, yPos)
  yPos += lineHeight
  doc.text("   ออกตามมาตรา 3 เตรส เช่น รางวัล ส่วนลดหรือประโยชน์ใดๆ ", startContent, yPos)
  yPos += lineHeight
  doc.text("   เนื่องจากการส่งเสริมการขายางวัลในการประกวด การแข่งขัน", startContent, yPos)
  yPos += lineHeight
  doc.text("   ค่าขนส่ง ค่าจ้างทำของ ค่าจ้างโฆษณา ค่าเช่า", startContent, yPos)
  doc.text(whtData.amnt === "0.00"? "": whtData.amnt, amntCol + amntColW-0.05, yPos, { align: 'right' })
  doc.text(whtData.wht === "0.00"? "": whtData.wht, taxCol + taxColW-0.05, yPos, { align: 'right' })
  doc.setFont("THSarabun", 'bold')
  doc.text("ค่าบริการ", startContent + 1.95, yPos)
  doc.setFont("THSarabun", 'normal')
  doc.text("ฯลฯ", startContent + 2.45, yPos)
  doc.line(startContent + 1.95, yPos + underlinepadding, startContent + 2.35, yPos + underlinepadding)
  yPos += lineHeight
  doc.text("6. อื่นๆ ระบุ . . . . . . . . . . . . . . . . . . . . . . . . . . . .", startContent, yPos)
  yPos += lineHeight
  doc.line(startContent - 0.05, yPos - lineHeight / 2, endContent + 0.05, yPos - lineHeight / 2)
  doc.line(dateCol,startTableY, dateCol, yPos - lineHeight / 2 )
  yPos += lineHeight / 2
  
  doc.line(amntCol,startTableY, amntCol, yPos+0.05 )
  doc.line(taxCol,startTableY, taxCol, yPos+0.05 )
  doc.line(amntCol, yPos+0.04, taxCol + taxColW, yPos+0.04)
  doc.line(amntCol, yPos +0.06, taxCol + taxColW, yPos+0.06)
  doc.text("รวมเงินที่จ่าย และ ภาษีที่นำส่ง", startContent + detailColW, yPos, { align: 'right' })
  doc.text(whtData.amnt === "0.00"? "": whtData.amnt, amntCol + amntColW-0.05, yPos, { align: 'right' })
  doc.text(whtData.wht === "0.00"? "": whtData.wht, taxCol + taxColW-0.05, yPos, { align: 'right' })
  yPos += lineHeight + lineHeight/2
  doc.text("รวมเงินภาษีที่นำส่ง (ตัวอักษร)", startContent, yPos)
  doc.roundedRect(startContent + 1.3, yPos - lineHeight, width - 1.875, lineHeight+0.05, 0.03, 0.03)
  doc.text(whtData.wht === "0.00"?"": `(${whtData.whtText}).`, startContent + 1.5, yPos)
  yPos += lineHeight + lineHeight * 3 / 4

  doc.roundedRect(startContent - 0.05, yPos - lineHeight, width - 0.5, lineHeight + lineHeight / 2, 0.05, 0.05)
  doc.setFont("THSarabun", 'bold')
  doc.text("ผู้จ่ายเงิน", startContent, yPos)
  doc.setFont("THSarabun", 'normal')
  const colChkW = 1.17
  const chkBW = 0.1
  const colChk1 = startContent + 0.5
  const colChk2 = colChk1 + colChkW
  const colChk3 = colChk2 + colChkW
  const colChk4 = colChk3 + colChkW
  doc.rect(colChk1, yPos - 0.1, chkBW, chkBW)
  doc.text("(1) หักภาษี ณ ที่จ่าย", colChk1 + 0.15, yPos)
  doc.setFont("THSarabun", 'bold')
  doc.text("X", colChk1 + 0.02, yPos - 0.01)
  doc.setFont("THSarabun", 'normal')
  doc.rect(colChk2, yPos - 0.1, chkBW, chkBW)
  doc.text("(2) ออกให้ตลอดไป", colChk2 + 0.15, yPos)
  doc.rect(colChk3, yPos - 0.1, chkBW, chkBW)
  doc.text("(3) ออกให้ครั้งเดียว", colChk3 + 0.15, yPos)
  doc.rect(colChk4, yPos - 0.1, chkBW, chkBW)
  doc.text("(4) อื่นๆ ระบุ . . . . . . . . .", colChk4 + 0.15, yPos)


  yPos += lineHeight + lineHeight * 3 / 4
  doc.roundedRect(startContent - 0.05, yPos - lineHeight, width - 0.5, lineHeight * 4 + lineHeight / 2, 0.05, 0.05)
  doc.setLineDashPattern([0.01, 0.05], 0)
  doc.text("ขอรับรองว่าข้อความและตัวเลขดังกล่าวข้างต้นถูกต้องตรงกับความจริงทุกประการ", startContent + width/2 -0.4, yPos, {align:'center'})
  yPos += lineHeight + lineHeight / 2
  doc.text("ผู้มีหน้าที่หักภาษี ณ. ที่จ่าย", startX + 2.4, yPos, { align: 'right' })
  doc.line(startX + 2.7, yPos + underlinepadding, startX + 4.3, yPos + underlinepadding)
  yPos += lineHeight + lineHeight / 2
  doc.text("วัน เดือน ปี ที่ออกหนังสือรับรองฯ", startX + 2.4, yPos, { align: 'right' })
  doc.text("        /           /", startX + 3.7, yPos, { align: 'right' })
  doc.line(startX + 3, yPos + underlinepadding, startX + 4, yPos + underlinepadding)

}

