import dayjs from "dayjs";


export const columns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "MtChkDte",
    headerName: "วันที่ตรวจ",
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-";
    },
  },
  {
    field: "DrvNm",
    headerName: "พนักงานขับรถ",
    width: 200, 
  },
  {
    field: "NName",
    headerName: "ชือเล่น",
    width: 120,
  },
  {
    field: "LicNm",
    headerName: "ทะเบียนรถ",
    width: 120,
  },
  {
    field: "TukCd",
    headerName: "เลขรถ",
    width: 120,
  },
  {
    field: "MtChkTypNm",
    headerName: "ประเภทการตรวจ",
    width: 120,
  },
  {
    field: "MtChkPlnNm",
    headerName: "ชื่อแผนการตรวจ",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "NumChk",
    headerName: "รายการเช็ค",
    width: 100,
    valueGetter: ({row}) => `${row.NumChkItm}/${row.NumPlnItm}`,
    cellClassName: ({row}) => +row.NumChkItm !== +row.NumPlnItm ? "highlight" : "",
    align: "center",
  },
  {
    field: "NumFile",
    headerName: "จำนวนรูปภาพ",
    width: 100,
    align: "center",
  },
  {
    field: "IsAprv",
    headerName: "ยืนยันการตรวจ",
    width: 100,
    valueFormatter: ({value}) => value === 1 ? "\u2714" : "",
    align: "center",
  },
]

export const itemColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "MtChkItmNm",
    headerName: "รายการตรวจสภาพรถ",
    minWidth: 200,
    flex: 1
  },
  {
    field: "Chkd",
    headerName: "เช็ค",
    width: 70,
    valueFormatter: ({value}) => value === 1 ? "\u2714" : "",
    align: "center",
  },
]