import { Box, Button, Paper, Typography, useMediaQuery } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useMemo } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';
import { coreApiNm } from "constants/serverConstant";
import { useState } from "react";
import { initJobByDateChartData, initJobByDateFilter, lineChartOption, monthLabels } from "../initData";
import { bgColors, bgGraphToneColor, borderColors } from "../constant";
import { jobOrderColNm } from "constants/jobOrderConstant";
import { useCallback } from "react";
import ComboBox from "components/ComboBox";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { addIdForDataGrid, sortObjDesc } from "utils";
import { jobByJobYearColumns } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";

import { red, green, yellow } from '@mui/material/colors';
import YearSelect from "components/YearSelect";
import dayjs from "dayjs";
import { leftRightComboKeyUp } from "utils/keyUpSearchData";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, CategoryScale, LinearScale, PointElement, LineElement, BarElement);
const titleProp = {
  textAlign: "center",
  variant: "overline",
  lineHeight: "100%",
  sx: { fontSize: "1rem" }
}

const getCellClassName = (params) => {
  // console.log("in getCellClassName params::", params)
  if (params.field === "DiffAmnt" && params.value !== 0) {
    return "has-diff"
  }
  return ""
}

const lineChartOptionNoCircle = JSON.parse(JSON.stringify(lineChartOption))

lineChartOptionNoCircle.plugins.datalabels.align = "top"
const FiveYearReport = () => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [chartData, setChartData] = useState(initJobByDateChartData)
  // const [jobCombo, setJobCombo] = useState(msData.jobCombo)
  const [jobByDateFilter, setJobByDateFilter] = useState(initJobByDateFilter)
  const [dataTable, setDataTable] = useState([])
  const [tableColumns, setTableColumns] = useState([])

  const jobCombo = useMemo(() => {
    const result = [{ id: null, label: "All Jobs" }, ...msData.jobCombo]
    return result
  }, [msData.jobCombo])
  
  const w800 = useMediaQuery('(min-width:800px)');

  // const handleCusSelect = useCallback((id) => {
  //   console.log("cusID::", id);
  //   setJobByDateFilter(oldState => ({ ...oldState, CusId: id, JobId: null }));
  //   if (id) {
  //     setJobCombo(msData.jobCombo.filter(item => item.refId === id));
  //   } else {
  //     setJobCombo(msData.jobCombo);
  //   }
  // }, [msData])

  const modLineChartOption = useMemo(()=>{
    const result = {...lineChartOption}
    if(!w800){
      result.plugins.legend.position = "bottom"
    }
    return result
  }, [w800])
  const modLineChartOptionNoCircle = useMemo(()=>{
    const result = {...lineChartOptionNoCircle}
    if(!w800){
      result.plugins.legend.position = "bottom"
    }
    return result
  }, [w800])

  const searchJobByDate = useCallback((filter) => {
    ax.post(coreApiNm.getMonthlyJobReportClient, filter).then(value => {
      if (value.data) {
        const data = value.data
        const datasets = []
        const startYear = dayjs(filter.AptTmSt).year()

        for (let i = 0; i < 5; i++) {
          datasets.push({
            label: `${+startYear + i}`,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            borderColor: bgGraphToneColor[i],
            backgroundColor: bgGraphToneColor[i],
            tension: 0.2,
            pointStyle: "circle",
          })
        }
        for (const item of data.jobByDate) {
          const year = item.YearMonth.toString().substring(0, 4)
          const found = datasets.find(it => it.label === year)
          if (!found) {
            console.log(`Not found year ${year}!!!`);// this should not occur
            continue
          }
          const month = item.YearMonth.toString().substring(4, 6)
          found.data[+month - 1] = +item.Num
        }
        const jobByDateData = {
          labels: monthLabels,
          datasets: datasets
        }
        console.log("jobByDateData::", jobByDateData)

        //------------------------------------------- lower part -----------------------------------------

        const sumCuses = []
        let lastJob = { Port: "" };
        const calYears = []
        const sumYears = {}
        for (let i = 0; i < 5; i++) {
          sumYears[startYear + i] = 0;
          calYears.push(startYear + i)
        }

        data.jobByJobYear.forEach(item => {
          if (lastJob.Port !== item.Port) {
            lastJob = { ...item }
            sumCuses.push(lastJob)
          } else {
            lastJob.Num += item.Num
          }
          if (sumYears[item.Year]) {
            sumYears[item.Year] += +item.Num
          } else {
            sumYears[item.Year] = +item.Num
          }
          if (!calYears.includes(item.Year)) calYears.push(item.Year)
        })

        sortObjDesc(sumCuses, "Num")
        calYears.sort((a, b) => a - b)

        setTableColumns(jobByJobYearColumns(calYears))
        const result = []
        const sumRow = { id: 0, No: "", JobId: 0, Port: "รวม" }
        let grandTotal = 0
        calYears.forEach(year => {
          sumRow[year] = sumYears[year]
          grandTotal += sumYears[year]
        })
        sumRow.Total = grandTotal
        sumCuses.forEach(cus => {
          const obj = {
            Port: cus.Port,
            Total: +cus.Num,
          }
          calYears.forEach(year => {
            const found = data.jobByJobYear.find(item => item.Port === cus.Port && item.Year === year)
            obj[year] = found ? +found.Num : ""
          })
          result.push(obj)
        })
        console.log("result::", result)
        const finalResult = addIdForDataGrid(result, "Port")
        // finalResult.splice(0, 0, sumRow)
        finalResult.push(sumRow)
        const sumYearData = {
          labels: calYears,
          datasets: [{
            label: "จำนวนงานรวม",
            data: calYears.map(year => sumYears[year]),
            borderColor: borderColors[2],
            backgroundColor: bgColors[2],
            tension: 0.2,
            pointStyle: "circle",
            pointRadius: 15
          }]
        }
        setChartData({ jobByDateData, sumYearData })
        // setChartData(o=>({...o, sumYearData}))
        setDataTable(finalResult)
      }
    })

  }, [ax])

  const handleComboKeyUp = useCallback((e) => {
    if (e.keyCode === 13) {
      setTimeout(() => searchJobByDate(jobByDateFilter), 100)
      return
    }
    leftRightComboKeyUp(e, msData.driverOnlyCombo, "DrvId", setJobByDateFilter, searchJobByDate)
  }, [jobByDateFilter, searchJobByDate, msData])

  useEffect(() => {
    setJobByDateFilter(o => {
      const filter = { ...initJobByDateFilter }
      filter.AptTmSt = dayjs(dayjs().add(-4, "year").format("YYYY-01-01 00:00:00.00"))
      filter.AptTmEn = dayjs()
      searchJobByDate(filter)
      return filter
    })
  }, [searchJobByDate])

  console.log({ jobByDateFilter })
  return (
    <BoxFC height="100%" sx={{ gap: 1 }}>
      
      <BoxFR justifyContent="center">
        <ComboBox sx={{ flex:1, maxWidth: 200}} options={jobCombo} label={jobOrderColNm.JobSNm}
          selectedId={jobByDateFilter.JobId}
          setSelectedId={(id) => setJobByDateFilter(oldState => ({ ...oldState, JobId: id }))}
          onKeyUp={handleComboKeyUp}
        />
        <YearSelect dteEn={jobByDateFilter.AptTmEn}
          setDte={(dteSt, dteEn) => setJobByDateFilter(o => ({ ...o, AptTmSt: dteSt, AptTmEn: dteEn }))} />
        <Button variant="contained" onClick={() => searchJobByDate(jobByDateFilter)}><SearchOutlinedIcon /></Button>
      </BoxFR>
      <Paper sx={{ p: 2, flex: 1, minHeight: 400 }}>
        <BoxFC sx={{ gap: 0 }} height="100%" >
          <Typography {...titleProp} >Number of jobs completed over the past 5 calendar years, by month</Typography>
          <Box height="100%">
            <Line data={chartData.jobByDateData} options={modLineChartOptionNoCircle} />
          </Box>
        </BoxFC>
      </Paper>
      <Grid2 container spacing={2} sx={{flex: 1}}>
        <Grid2 xs={12} md={6}>
          <Paper sx={{ p: 2, flex: 1, height: "100%", minHeight: 400 }}>
            <BoxFC height="100%" flex={1}>
              <Typography {...titleProp} >Job Completion Trend Over the Past 5 Years</Typography>
              <Box height="100%">
                <Line data={chartData.sumYearData} options={modLineChartOption} />
              </Box>
            </BoxFC>
          </Paper>
        </Grid2>

        <Grid2 xs={12} md={6}>
          <Paper sx={{ p: 2, flex: 1, height: "100%", minHeight: 400 }}>
            <BoxFC height="100%" width="100%"
              sx={{
                '& .sum-row': {
                  bgcolor: (theme) => yellow[100],
                  fontWeight: "bold",
                  // '&:hover': {
                  //   bgcolor: (theme) => 'darkkhaki',
                  // },
                },
                '& .more-than': {
                  color: green[800]
                },
                '& .less-than': {
                  color: red[900]
                }
              }}>

              <Typography {...titleProp} >Total number of jobs completed over the past 5 years, by PORT</Typography>
              <DataGridCellExpand
                hideFooter
                rows={dataTable}
                columns={tableColumns}
                getRowClassName={(params) => params.id === 0 && "sum-row"}
                getCellClassName={getCellClassName} />
            </BoxFC>
          </Paper>
        </Grid2>
      </Grid2>


    </BoxFC>
  )
}

export default FiveYearReport