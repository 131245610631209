import React from 'react'
import {
    Box, Typography
} from '@mui/material';
import dayjs from 'dayjs';

const ModAccName = ({modDte, modAcc, sx}) => {
    return (
        <Box  display='flex' justifyContent='flex-end' sx={sx}>
            <Typography variant='subtitle2'>
                แก้ไขล่าสุด:{modDte?dayjs(modDte).format('DD/MM/YYYY HH:mm:ss'):'-'} โดย:{modAcc?`${modAcc.FName} ${modAcc.LName}(${modAcc.NName})`:'-'}</Typography>
        </Box>
    )
}

export default ModAccName