

import { expenseColNm } from "constants/expenseConstant"
import { jobOrderColNm } from "constants/jobOrderConstant"

export const expensePOIApiNm = {
  getExpensePOI: "/expense-poi/getExpensePOI",
  getUntPrByJobOrdIdExpId: "/expense-poi/getUntPrByJobOrdIdExpId",
  insertUpdateFuelPrice: "/expense-poi/insertUpdateFuelPrice",
  insertExpensePOI: "/expense-poi/insertExpensePOI",
  updateExpensePOI: "/expense-poi/updateExpensePOI",
  deleteExpensePOI: "/expense-poi/deleteExpensePOI"
}

export const expensePOIColNm =  {
  POISNm: "ชื่องาน",
  ExpNm: expenseColNm.ExpNm,
  ContNo: jobOrderColNm.ContNo,
  ContSize: jobOrderColNm.ContSize,
  ContTyp: jobOrderColNm.ContTyp,
  ShpmTypId: jobOrderColNm.ShpmTypId,
  ShpmTypNm: jobOrderColNm.ShpmTypNm,
  VatPrct: expenseColNm.VatPrct,
  WhtPrct: expenseColNm.WhtPrct,
  UntPr: expenseColNm.UntPr,
  Prty: expenseColNm.Prty,
  IsOwnRcpt: expenseColNm.IsOwnRcpt,
  Rmk: "หมายเหตุ",
  ModDte: "วันที่แก้ไข",
  ModAcc: "ผู้แก้ไข",
}