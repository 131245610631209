export const maintenanceApiNm = {
  getAllCheckup: "/maintenance/getAllCheckup",
  getCheckupPlan: "/maintenance/getCheckupPlan",
  getCheckupPlanItem: "/maintenance/getCheckupPlanItem",
  getCheckUpPlanTruck: "/maintenance/getCheckUpPlanTruck",
  getCheckupCheckTable: "/maintenance/getCheckupCheckTable",
  getMtCheckupCheckDetail: "/maintenance/getMtCheckupCheckDetail",
  insertCheckupCategory: "/maintenance/insertCheckupCategory",
  updateCheckupCategoryCol: "/maintenance/updateCheckupCategoryCol",
  deleteCheckupCategory: "/maintenance/deleteCheckupCategory",
  insertCheckupItem: "/maintenance/insertCheckupItem",
  updateCheckupItemCol: "/maintenance/updateCheckupItemCol",
  deleteCheckupItem: "/maintenance/deleteCheckupItem",
  insertCheckupPln: "/maintenance/insertCheckupPln",
  insertCheckupPlnItemBulk: "/maintenance/insertCheckupPlnItemBulk",
  updateCheckupPlnCol: "/maintenance/updateCheckupPlnCol",
  deleteCheckupPln: "/maintenance/deleteCheckupPln",
  insertCheckupPlanTruckBulk: "/maintenance/insertCheckupPlanTruckBulk",
  deleteCheckupCheck: "/maintenance/deleteCheckupCheck",
  updateCheckupCheckApprove: "/maintenance/updateCheckupCheckApprove",
}