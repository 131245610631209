import { InputAdornment } from "@mui/material";

export const bahtProps = {
  inputProps: { style: { textAlign: "right" } },
  InputProps:{endAdornment: <InputAdornment position="end">บาท</InputAdornment>},
}

export const percentProps = {
  inputProps: { style: { textAlign: "right" } },
  InputProps:{endAdornment: <InputAdornment position="end">%</InputAdornment>},
}