import { Box, Button, ButtonGroup, Container, IconButton, MenuItem, Paper, TextField, Typography, useMediaQuery } from "@mui/material";
// import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { BoxFC, BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { barChartOption, initChartData, initPieData, lateChartOptions, lineChartOption, pieChartOptionsWihtLabel } from "../initData";
import { bgColors, borderColors } from "../constant";
import { coreApiNm } from "constants/serverConstant";
import dayjs from "dayjs";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { sortObj } from "utils";
import { yellow } from "@mui/material/colors";
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { ArticleRounded } from "@mui/icons-material";
import { lateTmColumns, vaningColumns } from "./columns";
import VanningDialog from "./VanningDialog";
import LateDialog from "./LateDialog";
import DateTimeSelect from "components/DateTimeSelect";
import SearchButton from "components/buttons/SearchButton";
dayjs.extend(weekOfYear);


ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, CategoryScale, LinearScale, PointElement, LineElement, BarElement);



const intiFilterData = {
  AptTmSt: dayjs().startOf('month'),
  AptTmEn: dayjs().endOf('month'),
  JobId: null
}
const initReportChartData = {
  dailyData: initChartData,
  weeklyData: initPieData,
  avgVanning: initChartData,
  lateData: initPieData
}

const lineChartOptionMod = { ...lineChartOption }
lineChartOptionMod.plugins.legend.display = false
lineChartOptionMod.scales = {
  x: {
    title: {
      display: true,
      text: "Date"
    }
  }
}

const plugin = {
  id: "drawcenter",
  afterDraw(chart, args, options) {
    const [numOnTime, numLate] = chart.data.datasets[0].data
    const percentage = numOnTime + numLate === 0 ? 100 : Math.round((numOnTime / (numOnTime + numLate))*10000)/100;
    const ctx = chart.ctx;
    const fontSize = 20 * chart.height/150
    ctx.save();
    ctx.font = `${fontSize}px Arial`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    const text = `${percentage}%`; // You can calculate the percentage here
    const textX = Math.round((chart.width-chart.legend.width) / 2);
    const textY = chart.height / 2;
    ctx.fillText(text, textX, textY);
    ctx.restore();
  }
}
// lateChartOption.animantion  = {
//   animateScale: true,
//   animateRotate: true,
//   onComplete: function() {
//     var canvasBounds = canvas.getBoundingClientRect();
//     dataLabel.innerHTML = ' Utilized  :  95 %';
//     var dataLabelBounds = dataLabel.getBoundingClientRect();
//     dataLabel.style.top = (canvasBounds.top + (canvasBounds.height / 2) - (dataLabelBounds.height / 2)) + 'px';
//     dataLabel.style.left = (canvasBounds.left + (canvasBounds.width / 2) - (dataLabelBounds.width / 2)) + 'px';
//   }
// }

let queryVanning = null
let queryLate = null
const MonthlyReport = () => {

  const [dialogVanningOpen, setDialogVanningOpen] = useState(false)
  const [dialogLateTmOpen, setDialogLateTmOpen] = useState(false)
  const [filterData, setFilterData] = useState({ ...intiFilterData })
  const [chartData, setChartData] = useState({ ...initReportChartData })
  const [dataTable, setDataTable] = useState({ dailyTable: [], weeklyTable: [], vaningTable: [], lateTmTable: [] })
  const [dailyColumns, setDailyColumns] = useState([])
  const [weeklyColumns, setWeeklyColumns] = useState([])
  const [weekSelect, setWeekSelect] = useState("WholeMonth")
  const [weekYearDonutData, setWeekYearDonutData] = useState([{
    weekYear: "WholeMonth",
    dteSt: dayjs().startOf('month'),
    dteEn: dayjs().endOf('month'),
    header: "Whole Month",
    caption: `${dayjs().startOf('month').format("DD/MM")} - ${dayjs().startOf('month').format("DD/MM")}`
  }])
  const { ax, msData } = useContext(GlobalStateContext)

  const w800 = useMediaQuery('(min-width:800px)');

  const doughnutTitle = useMemo(() => {
    const found = weekYearDonutData.find(weekYearObj => weekYearObj.weekYear === weekSelect)
    if (!found) return ""
    console.log("found::", found)
    if (found.weekYear === "WholeMonth") return `${dayjs(filterData.AptTmSt).format("DD/MM/YYYY")}-${dayjs(filterData.AptTmEn).format("DD/MM/YYYY")}`
    if (found.weekYear === "AllWeek") return found.caption

    return `${found.header} of ${dayjs(filterData.AptTmSt).format("DD/MM/YYYY")}-${dayjs(filterData.AptTmEn).format("DD/MM/YYYY")}`
  }, [weekYearDonutData, weekSelect, filterData])

  // const lateChartOption = useMemo(()=>{
  //   const result = {...pieChartOptionsWihtLabel}
  //   result.plugins.legend = {
  //     title: {
  //       display: true,
  //       text: "Late"
  //     },
  //     }
  //   }
  //   return result
  // }, [])
  const titleProp = useMemo(() => ({
    textAlign: "center",
    variant: "overline",
    lineHeight: "100%",
    sx: { fontSize: w800 ? "0.9rem" : "0.8rem", textTransform: "none" }
  }), [w800])

  const jobCombo = useMemo(() => {
    const result = [{ id: null, label: "All" }, ...msData.jobCombo]
    return result
  }, [msData.jobCombo])


  // const datePickerProp = useMemo(() => ({
  //   views: ["year", "month"],
  //   openTo: "month",
  //   label: "ประจำเดือน",
  //   inputFormat: "MMMM YYYY",
  //   disableMaskedInput: true,
  //   value: filterData.YearMonth,
  //   onChange: (newValue) => setFilterData(o => ({ ...o, YearMonth: newValue })),
  //   renderInput: (params) => <TextField sx={{ width: 180 }} size="small" {...params} />
  // }), [filterData.YearMonth])

  const getData = useCallback((filter)=>{
    const postData = {
      AptTmSt: filter.AptTmSt,
      AptTmEn: filter.AptTmEn,
      JobId: filter.JobId
    }

    ax.post(coreApiNm.getMonthlyDataReportClient, postData).then(value => {
      if (value.data) {

        const dteSt = 1;
        const dteEn = dayjs(filter.AptTmEn).date();
        const dteArr = [...Array(dteEn - dteSt + 1).keys()].map(i => i + dteSt)
        //-------------------------------------------- Daily Chart Data --------------------------------------------
        const dateObj = value.data.dailyData.reduce((prev, cur) => ({
          ...prev,
          [dayjs(cur.Dte).date()]: cur.Num || 0
        }), {})
        for (const dte of dteArr) {
          dateObj[dte] = dateObj[dte] || 0
        }
        const dailyChartData = dteArr.map(dte => dateObj[dte] || 0)
        const dailyData = {
          labels: dteArr,
          datasets: [{
            data: dailyChartData,
            borderColor: borderColors[1],
            backgroundColor: bgColors[1],
            tension: 0.2,
            pointStyle: "circle",
            pointRadius: w800 ? 15 : 10
          }]
        }

        //-------------------------------------------- Daily Cont Size Table --------------------------------------------
        const dailyContSizeObj = value.data.dailyContSizeData.reduce((prev, cur) => {
          prev[cur.ContSizeFull] = prev[cur.ContSizeFull] ?
            { ...prev[cur.ContSizeFull], [dayjs(cur.Dte).date()]: cur.Num }
            : { [dayjs(cur.Dte).date()]: cur.Num }
          return prev
        }, {})

        const dailyContSizeTable = Object.keys(dailyContSizeObj).map(contSizeFull => {
          const dataObj = dailyContSizeObj[contSizeFull]
          let total = 0
          for (const dte of dteArr) {
            dataObj[dte] = dataObj[dte] || 0
            total += +dataObj[dte] || 0
          }
          // const data = dteArr.map(dte =>  ({[dte]: dataObj[dte] || 0}))
          return {
            id: contSizeFull,
            ContSizeFull: contSizeFull,
            ...dataObj,
            Total: total
          }
        })
        sortObj(dailyContSizeTable, "ContSizeFull")
        dailyContSizeTable.push({
          id: 'Total',
          ContSizeFull: 'Total',
          ...dateObj
          , Total: dailyChartData.reduce((prev, cur) => prev + cur, 0)
        })
        const dailyCols = [
          {
            field: "ContSizeFull",
            headerName: "Cont. Size",
            width: 80,
          },
          ...dteArr.map(dte => ({
            field: `${dte}`,
            headerName: `${dte}`,
            width: 40,
            align: 'center',
            valueFormatter: (params) => `${params.value}`
          })),
          {
            field: "Total",
            headerName: "Total",
            width: 80,
            align: 'center',
            valueFormatter: (params) => `${params.value}`
          }
        ]

        //-------------------------------------------- Weekly Cont Size Table -------------------------------------------

        const transformedData = value.data.weeklyData.reduce((acc, { Week, ContSizeFull, Num }) => {
          if (!acc[ContSizeFull]) {
            acc[ContSizeFull] = {};
          }
          acc[ContSizeFull][Week] = Num;
          return acc;
        }, {});

        const weeklyColumns = [
          {
            field: "ContSizeFull",
            headerName: "Size",
            width: 60,
          },
        ]
        const daySt = dayjs(filter.AptTmSt).startOf('month').day(0).add(1, 'day')// 0 = Sunday Start week of Monday

        const curMonth = dayjs(filter.AptTmSt).month()
        const curYear = dayjs(filter.AptTmSt).year() * 100
        const curYearMonth = curYear + curMonth
        let runDte = daySt.clone()
        let i = 1
        const weekYears = []
        let runYearMonth = runDte.month() + (runDte.year() * 100)

        while (runYearMonth <= curYearMonth && i < 100) {
          let headerName = ""
          if (i === 1) headerName = "1st Week"
          else if (i === 2) headerName = "2nd Week"
          else if (i === 3) headerName = "3rd Week"
          else headerName = `${i}th Week`
          const weekYearObj = {
            weekYear: (runDte.year() * 100) + runDte.week(),
            dteSt: runDte.clone(),
            dteEn: runDte.add(6, 'day'),
            header: headerName,
            caption: `${runDte.format("DD/MM")} - ${runDte.add(6, 'day').format("DD/MM")}`
          }
          weekYears.push(weekYearObj)

          console.log("weekYearObj::", weekYearObj)
          const filterStr = `${runDte.format("YYYY-MM-DD_00:00:00")}~${weekYearObj.dteEn.format("YYYY-MM-DD_23:59:59.997")}`
          weeklyColumns.push({
            field: `${weekYearObj.weekYear}`,
            renderHeader: (params) => (
              <BoxFC sx={{ gap: 0, alignItems: "center" }}>
                <Typography variant="body2">{weekYearObj.header}</Typography>
                <Typography variant="caption">({weekYearObj.caption})</Typography>
              </BoxFC>
            ),
            flex: 1,
            minWidth: 100,
            align: 'center',
            // valueFormatter: (params) => `${params.value}`,
            renderCell: (params) => (
              <BoxFR>
                <Typography variant="body2">{params.value}</Typography>
                <IconButton sx={{ px: 0 }} onClick={
                  () => { window.open(`/client/DataQuery/YearMonthContSize/${filterStr}~${params.row.ContSizeFull}`, "_blank") }
                }>
                  <ArticleRounded color={params.value === 0 ? "disabled" : "primary"} />
                </IconButton>
              </BoxFR>
            )
          })
          i++;
          runDte = runDte.add(7, 'day')
          runYearMonth = runDte.month() + (runDte.year() * 100)
        }
        const dayEn = runDte.add(-7, 'day')
        const filterStr = `${daySt.format("YYYY-MM-DD_00:00:00")}~${dayEn.format("YYYY-MM-DD_23:59:59.997")}`
        weeklyColumns.push({
          field: `Total`,
          renderHeader: (params) => (
            <BoxFC sx={{ gap: 0, alignItems: "center" }}>
              <Typography variant="body2">Total</Typography>
              <Typography variant="caption">({daySt.format("DD/MM")} - {dayEn.format("DD/MM")})</Typography>
            </BoxFC>
          ),
          flex: 1,
          minWidth: 100,
          align: 'center',
          renderCell: (params) => (
            <BoxFR>
              <Typography variant="body2">{params.value}</Typography>
              <IconButton sx={{ px: 0 }} onClick={
                () => { window.open(`/client/DataQuery/YearMonthContSize/${filterStr}~${params.row.ContSizeFull}`, "_blank") }
              }>
                <ArticleRounded color={params.value === 0 ? "disabled" : "primary"} />
              </IconButton>
            </BoxFR>
          )

        })
        //Add missing weekYear
        for (const contSize in transformedData) {
          for (const weekYearObj of weekYears) {
            transformedData[contSize][weekYearObj.weekYear] = transformedData[contSize][weekYearObj.weekYear] || 0
          }
        }
        //Convert to array
        const weeklyData = Object.entries(transformedData).map(([ContSizeFull, weeks]) => ({
          id: ContSizeFull,
          ContSizeFull,
          ...weeks,
          Total: Object.values(weeks).reduce((prev, cur) => prev + cur, 0)
        }));
        sortObj(weeklyData, "ContSizeFull")
        weeklyData.push({
          id: "Total",
          ContSizeFull: "Total",
          ...weeklyData.reduce((prev, cur) => {
            for (const col of weeklyColumns) {
              if (col.field === "ContSizeFull") continue
              prev[col.field] = (prev[col.field] || 0) + cur[col.field]
            }
            return prev
          }, {})
        })
        console.log("transformedData::", transformedData)
        console.log("weeklyData::", weeklyData)
        console.log("weeklyColumns::", weeklyColumns)
        console.log("weekYears::", weekYears)

        //-------------------------------------------- Weekly Chart Data --------------------------------------------
        const monthDteSt = dayjs(filter.AptTmSt)
        const monthDteEn = dayjs(filter.AptTmEn)
        const weekYearDonutInnerData = [
          {
            weekYear: "WholeMonth",
            dteSt: monthDteSt,
            dteEn: monthDteEn,
            header: "Whole Month",
            caption: `${monthDteSt.format("DD/MM")} - ${monthDteEn.format("DD/MM")}`
          },
          {
            weekYear: "AllWeek",
            dteSt: weekYears[0]?.dteSt || monthDteSt,
            dteEn: weekYears[weekYears.length - 1]?.dteEn || monthDteEn,
            header: "All Week",
            caption: !weekYears[0] ? "" : `${weekYears[0].dteSt.format("DD/MM")} - ${weekYears[weekYears.length - 1].dteEn.format("DD/MM")}`
          },
          ...weekYears.map(weekYearObj => weekYearObj)
        ]
        const weeklyChartData = weeklyData.filter(data => data.id !== "Total")
        const weekYearObj = weekYears.reduce((prev, cur) => ({
          ...prev,
          [cur.weekYear]: {
            ...cur,
            data: [
              ...weeklyChartData.map(data => data[cur.weekYear])
            ]
          }
        }), {})
        weekYearObj["WholeMonth"] = {
          ...weekYearDonutInnerData[0],
          data: dailyContSizeTable.filter(data => data.id !== "Total").map(data => data.Total)
        }
        weekYearObj["AllWeek"] = {
          ...weekYearDonutInnerData[1],
          data: weeklyChartData.map(data => data.Total)
        }
        console.log("weekYearObj::", weekYearObj)
        const weeklyDataPie = {
          labels: weeklyChartData.map(data => data.ContSizeFull),
          datasets: [{
            data: weekYearObj["WholeMonth"].data,
            ...weekYearObj,
            borderColor: borderColors,
            backgroundColor: bgColors,
          }]
        }

        //-------------------------------------------- Vanning Table --------------------------------------------
        const vaningTransform = value.data.vanningHr.reduce((acc, { Hr, ContSizeFull, Num }) => {
          if (!acc[ContSizeFull]) {
            acc[ContSizeFull] = {};
          }
          acc[ContSizeFull][Hr] = Num;
          return acc;
        }, {});
        const vaningTable = Object.entries(vaningTransform).map(([ContSizeFull, hrs]) => ({
          id: ContSizeFull,
          ContSizeFull,
          ...hrs,
        }))
        sortObj(vaningTable, "ContSizeFull")
        vaningTable.push({
          id: "Total",
          ContSizeFull: "Total",
          ...vaningTable.reduce((prev, cur) => {
            for (const col of [1, 2, 3, 4, 5]) {
              prev[col] = (prev[col] || 0) + (cur[col] || 0)
            }
            return prev
          }, {})
        })

        //-------------------------------------------- Avg Vanning Chart Data --------------------------------------------
        const vanningAvg = value.data.vanningAvg
        sortObj(vanningAvg, "ContSizeFull")
        const avgVanningChartData = {
          labels: vanningAvg.map(row => row.ContSizeFull),
          datasets: [{
            data: vanningAvg.map(row => Math.round((row.AvgVanTm / 60) * 100) / 100),
            borderColor: borderColors[1],
            backgroundColor: bgColors[1],
          }]
        }

        //-------------------------------------------- LateTm Table --------------------------------------------
        const lateTmTable = []
        const rawLateTm = value.data.lateTmData
        for (const isLate of [0, 1]) {
          lateTmTable.push({
            id: isLate,
            IsLate: isLate,
            ...[10, 12, 15, 16].reduce((prev, cur) => ({
              ...prev,
              [cur]: rawLateTm.find(row => row.IsLate === isLate && row.Tm === cur)?.Num || 0
            }), {}),
            Total: rawLateTm.reduce((prev, cur) => cur.IsLate === isLate ? prev + cur.Num : prev, 0)
          })
        }
        lateTmTable.push({
          id: "Total",
          IsLate: "Total",
          ...[10, 12, 15, 16].reduce((prev, cur) => ({
            ...prev,
            [cur]: rawLateTm.find(row => row.Tm === cur)?.Num || 0
          }), {}),
          Total: rawLateTm.reduce((prev, cur) => prev + cur.Num, 0)
        })
        console.log("lateTmTable::", lateTmTable)

        //-------------------------------------------- Late Chart Data --------------------------------------------
        const lateData = value.data.lateData
        const lateDatasetData = [lateData.find(row => row.IsLate === 0)?.Num || 0, lateData.find(row => row.IsLate === 1)?.Num || 0]
        const lateChartData = {
          labels: [`${lateDatasetData[0]}: On-Time`, `${lateDatasetData[1]}: Late`],
          datasets: [{
            data: [lateData.find(row => row.IsLate === 0)?.Num || 0, lateData.find(row => row.IsLate === 1)?.Num || 0],
            borderColor: [borderColors[1], borderColors[0]],
            backgroundColor: [bgColors[1], bgColors[0]]
          }]
        }

        console.log("lateChartData::", lateChartData)
        //-------------------------------------------- Set State --------------------------------------------
        setChartData({
          dailyData: dailyData,
          weeklyData: weeklyDataPie,
          avgVanning: avgVanningChartData,
          lateData: lateChartData
        })
        setDailyColumns(dailyCols)
        setDataTable({
          dailyTable: dailyContSizeTable,
          weeklyTable: weeklyData,
          vaningTable: vaningTable,
          lateTmTable: lateTmTable
        })
        setWeeklyColumns(weeklyColumns)
        setWeekYearDonutData(weekYearDonutInnerData)
      }
    })
  }, [ax, w800])
//

  const handleWeekSelect = useCallback((weekYear) => {
    setWeekSelect(weekYear)
    setChartData(o => ({
      ...o,
      weeklyData: {
        ...o.weeklyData,
        datasets: [
          {
            ...o.weeklyData.datasets[0],
            data: o.weeklyData.datasets[0][weekYear].data
          }
        ]
      }
    }))
  }, [])

  const handleOpenVanningDetail = useCallback((hr, contSizeFull) => {
    queryVanning = {
      // AptTmSt: filterData.YearMonth.startOf('month'),
      // AptTmEn: filterData.YearMonth.endOf('month'),
      AptTmSt: filterData.AptTmSt,
      AptTmEn: filterData.AptTmEn,
      JobId: filterData.JobId,
      Hr: hr,
      ContSizeFull: contSizeFull === "Total" ? null : contSizeFull
    }
    setDialogVanningOpen(true)
  }, [filterData])

  const handleOpenLateDetail = useCallback((tm, isLate) => {
    queryLate = {
      AptTmSt: filterData.AptTmSt,
      AptTmEn: filterData.AptTmEn,
      JobId: filterData.JobId,
      Tm: tm,
      IsLate: isLate,
    }
    setDialogLateTmOpen(true)
  }, [filterData])

  useEffect(() => {
    const dteSt = 1;
    const dteEn = dayjs(filterData.AptTmEn).date();
    const dteArr = [...Array(dteEn - dteSt + 1).keys()].map(i => i + dteSt)
    setDailyColumns([
      {
        field: "ContSizeFull",
        headerName: "Cont. Size",
        width: 80,
      },
      ...dteArr.map(dte => ({
        field: `${dte}`,
        headerName: `${dte}`,
        width: 40,
        align: 'center',
        valueFormatter: (params) => `${params.value}`
      }))
    ])
  }, [filterData])

  useEffect(() => {
    getData(intiFilterData)
  }, [getData])
  return (
    <Container maxWidth="xl" width="100%" sx={{ px: 0 }}>
      <BoxFR justifyContent="center">
        {/* <DesktopDatePicker {...datePickerProp} /> */}
        <DateTimeSelect
          sx={{width:180}}
          label=""
          start={filterData.AptTmSt}
          end={filterData.AptTmEn}
          setSelectDate={(st, en) => {setFilterData(o=>({...o, AptTmSt: st, AptTmEn: en}))}}
          // getData={() => { getData(filterData) }}
        />
        <ComboBox sx={{ maxWidth: 180, flex: 1 }}
          options={jobCombo}
          label="Job"
          selectedId={filterData.JobId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))} />
        <SearchButton onClick={()=>{getData(filterData)}} />
      </BoxFR>
      <Grid2 container spacing={1} mt={2}>
        <Grid2 xs={12}>
          <Paper sx={{ p: 2, height: 300, }}>
            <BoxFC height="100%" sx={{ gap: 0 }} overflow="hidden">
              <Typography {...titleProp} >Daily Job Order Volume for {`${dayjs(filterData.AptTmSt).format("DD/MM/YYYY")}-${dayjs(filterData.AptTmEn).format("DD/MM/YYYY")}`}</Typography>
              <Box height="100%" sx={{ overflowX: "auto" }}>
                <Box height="100%" minWidth={750}  >
                  <Line data={chartData.dailyData} options={lineChartOptionMod} />
                </Box>
              </Box>
            </BoxFC>
          </Paper>
        </Grid2>
        <Grid2 xs={12}>
          <Paper sx={{ p: 2 }}>
            <BoxFC sx={{ gap: 2 }}>
              <Typography {...titleProp} >Daily Job Order distribution by Container Size: {`${dayjs(filterData.AptTmSt).format("DD/MM/YYYY")}-${dayjs(filterData.AptTmEn).format("DD/MM/YYYY")}`}</Typography>
              <Box width="100%" maxHeight={300} sx={{
                overflowY: "auto",
                '& .total': { fontWeight: "bold", bgcolor: yellow[100] }
              }}>
                <DataGridCellExpand
                  autoHeight
                  density={w800 ? "standard" : "compact"}
                  hideFooter
                  rows={dataTable.dailyTable}
                  columns={dailyColumns}
                  getRowClassName={params => params.id === "Total" ? "total" : ""} />
              </Box>
            </BoxFC>

          </Paper>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <BoxFC sx={{ gap: 2, height: w800 ? 350 : null }} >
              <Typography {...titleProp} >Weekly Jobs distribution by Container Size: {`${dayjs(filterData.AptTmSt).format("DD/MM/YYYY")}-${dayjs(filterData.AptTmEn).format("DD/MM/YYYY")}`}</Typography>
              <Box width="100%" maxHeight={300} sx={{
                overflowY: "auto",
                '& .total': { fontWeight: "bold", bgcolor: yellow[100] }
              }}>
                <DataGridCellExpand
                  autoHeight
                  density={w800 ? "standard" : "compact"}
                  hideFooter
                  rows={dataTable.weeklyTable}
                  columns={weeklyColumns}
                  getRowClassName={params => params.id === "Total" ? "total" : ""} />
              </Box>
            </BoxFC>
          </Paper>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <BoxFC sx={{ gap: 2 }}>
              {!w800 ? <TextField label="Time Period" select size="small" value={weekSelect}
                onChange={(e) => handleWeekSelect(e.target.value)}>
                {weekYearDonutData.map((weekYearObj, i) => (
                  <MenuItem key={weekYearObj.weekYear} value={weekYearObj.weekYear}>
                    {`${weekYearObj.header} (${weekYearObj.caption})`}
                  </MenuItem>
                ))}
              </TextField> : null}
              <BoxFR>
                {w800 ? (
                  <ButtonGroup orientation="vertical">
                    {weekYearDonutData.map((weekYearObj, i) => (
                      <Button key={weekYearObj.weekYear} sx={{ textTransform: "none", width: 230 }}
                        onClick={() => handleWeekSelect(weekYearObj.weekYear)}
                        variant={weekSelect === weekYearObj.weekYear ? "contained" : "outlined"}>
                        {`${weekYearObj.header} (${weekYearObj.caption})`}
                      </Button>
                    ))}
                  </ButtonGroup>
                ) : null}
                <BoxFC alignItems="center" flex={1} height={w800 ? 350 : null}>
                  <Typography {...titleProp} >Container Size: {doughnutTitle}</Typography>
                  <Box width="100%" height="100%" sx={{ '& .total': { fontWeight: "bold", bgcolor: yellow[100] } }}>
                    <Doughnut data={chartData.weeklyData} options={pieChartOptionsWihtLabel} />
                  </Box>
                </BoxFC>
              </BoxFR>
            </BoxFC>
          </Paper>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <BoxFC sx={{ gap: 2, height: w800 ? 350 : null }} >
              <Typography {...titleProp} > Monthly Vanning Time by Container Size: {`${dayjs(filterData.AptTmSt).format("DD/MM/YYYY")}-${dayjs(filterData.AptTmEn).format("DD/MM/YYYY")}`}</Typography>
              <Box width="100%" maxHeight={300} sx={{
                overflowY: "auto",
                '& .total': { fontWeight: "bold", bgcolor: yellow[100] }
              }}>
                <DataGridCellExpand
                  autoHeight
                  density={w800 ? "standard" : "compact"}
                  hideFooter
                  rows={dataTable.vaningTable}
                  columns={vaningColumns(handleOpenVanningDetail)}
                  getRowClassName={params => params.id === "Total" ? "total" : ""} />
              </Box>
            </BoxFC>
          </Paper>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <BoxFC sx={{ gap: 2, height: w800 ? 350 : null }} >
              <Typography {...titleProp} >Average Vanning Time per Container Size: {`${dayjs(filterData.AptTmSt).format("DD/MM/YYYY")}-${dayjs(filterData.AptTmEn).format("DD/MM/YYYY")}`}</Typography>
              <Box width="100%" height="100%" sx={{ '& .total': { fontWeight: "bold", bgcolor: yellow[100] } }}>
                <Bar data={chartData.avgVanning} options={barChartOption} />
              </Box>
            </BoxFC>
          </Paper>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <BoxFC sx={{ gap: 2, height: w800 ? 350 : null }} >
              <Typography {...titleProp} > Delivery Punctuality Breakdown by Time Period: {`${dayjs(filterData.AptTmSt).format("DD/MM/YYYY")}-${dayjs(filterData.AptTmEn).format("DD/MM/YYYY")}`}</Typography>
              <Box width="100%" maxHeight={300} sx={{ '& .total': { fontWeight: "bold", bgcolor: yellow[100] } }}>
                <DataGridCellExpand
                  autoHeight
                  density={w800 ? "standard" : "compact"}
                  hideFooter
                  rows={dataTable.lateTmTable}
                  columns={lateTmColumns(handleOpenLateDetail)}
                  getRowClassName={params => params.id === "Total" ? "total" : ""} />
              </Box>
            </BoxFC>
          </Paper>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <BoxFC sx={{ gap: 2, height: w800 ? 350 : null }} >
              <Typography {...titleProp} >On-Time Percentage: {`${dayjs(filterData.AptTmSt).format("DD/MM/YYYY")}-${dayjs(filterData.AptTmEn).format("DD/MM/YYYY")}`}</Typography>
              <Box width="100%" height="100%" sx={{ '& .total': { fontWeight: "bold", bgcolor: yellow[100] } }}>
                <Doughnut data={chartData.lateData} options={lateChartOptions} plugins={[plugin]} />
              </Box>
            </BoxFC>
          </Paper>
        </Grid2>
      </Grid2>
      <VanningDialog dialogOpen={dialogVanningOpen} setDialogOpen={setDialogVanningOpen} queryData={queryVanning} />
      <LateDialog dialogOpen={dialogLateTmOpen} setDialogOpen={setDialogLateTmOpen} queryData={queryLate} />
    </Container>
  );
}

export default MonthlyReport;