import { Button, InputAdornment, MenuItem, Paper, TextField } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback, useEffect, useState } from "react";

const AbsentChoice = ({ defaultSts, defaultDscp, defaultTemp, defaultAlc, drvAttnSts, drvId, col, handleCheck }) => {
  const [attnSts, setAttnSts] = useState(101)
  const [dscp, setDscp] = useState("")
  const [temp, setTemp] = useState(null)
  const [alc, setAlc] = useState(null)

  const textFieldProps = useCallback((state, setState, endAdornment)=>({
    size: "small",
    type: "number",
    value: state ?? "",
    onChange: (e) => { setState(e.target.value===""?null: e.target.value) },
    inputProps: {style: {textAlign: "center"}},
    InputProps: {
      endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
    }
  }), [])

  useEffect(() => {
    setAttnSts(defaultSts)
    setDscp(defaultDscp)
    setTemp(defaultTemp)
    setAlc(defaultAlc)
  }, [defaultSts, defaultDscp, defaultTemp, defaultAlc])
  return (
    <Paper sx={{ p: 1, width: 250 }}>
      <BoxFC sx={{ gap: 1 }}>
        <TextField label="สถานะ" size="small" fullWidth value={attnSts ?? ""} select onChange={(e) => { setAttnSts(e.target.value) }}>
          <MenuItem value={null}>ไม่ระบุ</MenuItem>
          {drvAttnSts.map(sts => (
            <MenuItem key={sts.DrvAttnStsId} value={sts.DrvAttnStsId}>
              {`(${sts.Abbr})${sts.DrvAttnStsNm}`}
            </MenuItem>
          ))}
        </TextField>
        <TextField label="หมายเหตุ" size="small" fullWidth value={dscp} onChange={(e) => { setDscp(e.target.value) }} />
        {
          +attnSts === 1 || +attnSts === 10 ?
            <BoxFR>
              <TextField sx={{flex: 5}} label="อุณหภูมิ" {...textFieldProps(temp, setTemp, `ºC`)}/>
              <TextField sx={{flex: 6}} label="Alcohol" {...textFieldProps(alc, setAlc, `Mg%`)}/>
            </BoxFR> : null
        }
        <Button variant="contained" size="small" onClick={() => { handleCheck(drvId, col, attnSts, dscp, temp, alc, true) }}>บันทึก</Button>
      </BoxFC>
    </Paper>
  )
}

export default AbsentChoice