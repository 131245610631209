import { Button } from "@mui/material";
import dayjs from "dayjs";

export const columns = (handleJobOrdIdClick) => [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: "center",
  },
  {
    field: "DteTm",
    headerName: "วัน-เวลา",
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "DrvNNm",
    headerName: "พนักงานขับรถ",
    width: 120,
  },
  {
    field: "LicNm",
    headerName: "ทะเบียนรถ",
    width: 100,
  },
  {
    field: "JobOrdId",
    headerName: "เลขที่ใบงาน",
    width: 90,
    renderCell: ({value}) => <Button onClick={handleJobOrdIdClick(value)}>{value}</Button>
  },
  {
    field: "Event",
    headerName: "เหตุการณ์",
    flex: 1,
  },
]