import React, { useCallback, useContext, useMemo } from "react";
import { Box, Button, Dialog, DialogContent, Divider, TextField, Typography, useMediaQuery } from '@mui/material'
import ModAccName from 'components/ModAccName';
import { DialogFooter, DialogHeader } from 'components/dialog/DialogHeader';
import { accountColNm, DIALOG_MODE_CLIENT, DIALOG_MODE_PROFILE, ACC_TYP_EMP } from "constants/accountConstant";
import { validate } from "utils";
import PaperComponent from "components/PaperComponent";
import { BoxFC, BoxFR } from "components/BoxCustom";
import useAccountDialog from "./useAccountDialog";
import SwitchFormControl from "components/SwitchFormControl";
import CheckboxFormControl from "components/CheckboxFormControl";
import ComboBox from "components/ComboBox";
import { blueGrey } from "@mui/material/colors";
import { columns } from "./columns"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { UserContext } from "contexts/UserContext";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const AccountDialog = ({ dialogOpen, selectedAccId, setDialogOpen, onFinish, mode }) => {

  const { user } = useContext(UserContext)
  const { state, fn } = useAccountDialog({ dialogOpen, selectedAccId, setDialogOpen, onFinish, mode })
  const { msData, dialogData, password, oldPassword, confirmPassword
    , selectedCusId, selectedJobId, selectedCusData, selectedJobData, jobCombo } = state


  const w800 = useMediaQuery('(min-width:800px)');

  const validation = useMemo(() => ({
    FName: dialogData.FName === '',
    LName: dialogData.LName === '',
    NName: dialogData.NName === '',
    Email: dialogData.Email === '',
  }), [dialogData])

  const dialogDataProp = useCallback((name) => ({
    variant: 'outlined',
    size: 'small',
    name: name,
    label: accountColNm[name],
    value: dialogData[name] || "",
    error: validation[name],
    onChange: (e) => { fn.setDialogData((oldState) => ({ ...oldState, [name]: e.target.value })) }
  }), [fn, dialogData, validation])
  //setJobCombo(msData.jobCombo.filter(item => item.refId === id))
  const comboProp = useCallback((name, label, value, options) => ({
    name: name,
    sx: { flex: 1 },
    options: options,
    label: label,
    selectedId: value
  }), [])

  const handleSelecteCusCombo = useCallback((id) => {
    fn.setJobCombo(msData.jobCombo.filter(item => item.refId === id))
    fn.setSelectedCusId(id)
  }, [fn, msData.jobCombo])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm' fullScreen={!w800}>
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={selectedAccId > 0 ? "แก้ไขบัญชีผู้ใช้งาน" : "เพิ่มบัญชีผู้ใช้งาน"}
        onDeleteClick={selectedAccId > 0 && user.AccTypId === ACC_TYP_EMP ? () => fn.deleteAccount() : null}
      />
      <DialogContent>
        <Grid2 container spacing={2} sx={{ mt: 1 }}>
          <Grid2 xs={12} md={5}>
            <BoxFC>
              <Typography variant="h6" >ข้อมูลผู้ใช้งาน</Typography>
              <TextField {...dialogDataProp('FName')} required />
              <TextField {...dialogDataProp('LName')} required />
              <TextField {...dialogDataProp('NName')} required />
              <TextField {...dialogDataProp('Email')} required disabled={selectedAccId > 0} />
              <TextField {...dialogDataProp('Tel')} />
              {mode !== DIALOG_MODE_PROFILE &&
                <SwitchFormControl
                  label={dialogData.AccSts === 1 ? "สถานะบัญชี Active" : "สถานะบัญชี Inactive"}
                  checked={dialogData.AccSts === 1 ? true : false}
                  onChange={(e) => fn.setDialogData(o => ({ ...o, AccSts: e.target.checked ? 1 : 0 }))} />
              }
              {
                !selectedAccId && mode !== DIALOG_MODE_PROFILE &&
                <SwitchFormControl
                  label={dialogData.SendEmail === 1 ? "ส่ง Email รหัสผ่าน" : "ไม่ส่ง Email รหัสผ่าน"}
                  checked={dialogData.SendEmail === 1 ? true : false}
                  onChange={(e) => fn.setDialogData(o => ({ ...o, SendEmail: e.target.checked ? 1 : 0 }))} />
              }
              <Divider />
            </BoxFC>
          </Grid2>
          <Grid2 xs={12} md={7}>
            <BoxFC alignSelf="flex-start" >
              <Typography variant="h6" >สิทธิ์การใช้งาน</Typography>

              {
                user.AccTypId === ACC_TYP_EMP && mode !== DIALOG_MODE_CLIENT ?
                  msData.permissions.map(item => (
                    <BoxFR key={item.PmId}>
                      <Typography sx={{ width: 150 }}>{item.Name}</Typography>
                      <CheckboxFormControl disabled={mode === DIALOG_MODE_PROFILE} label="" checked={fn.pmValue(item.PmId, 1)} onChange={(e) => fn.pmChange(item.PmId, 1, e.target.checked)} />
                      {/* <CheckboxFormControl disabled={mode === DIALOG_MODE_PROFILE} label="" checked={fn.pmValue(item.PmId, 2)} onChange={(e) => fn.pmChange(item.PmId, 2, e.target.checked)} /> */}
                    </BoxFR>
                  ))
                  :
                  <BoxFC>
                    <BoxFC sx={{ display: mode !== DIALOG_MODE_CLIENT && "none", gap: 1 }} >
                      <BoxFR>
                        <ComboBox {...comboProp("CusId", "ลูกค้า", selectedCusId, msData.cusCombo)} setSelectedId={handleSelecteCusCombo} />
                        <Button variant="contained" onClick={fn.handleAddCus}><AddCircleRoundedIcon /></Button>
                      </BoxFR>
                      <BoxFR>
                        <ComboBox {...comboProp("JobId", "งาน", selectedJobId, jobCombo)} setSelectedId={id => fn.setSelectedJobId(id)} />
                        <Button variant="contained" onClick={fn.handleAddJob}><AddCircleRoundedIcon /></Button>
                      </BoxFR>
                      <BoxFR>
                        <BoxFC flex={1}>
                          <Typography fontWeight="bold" >ลูกค้าที่เลือก</Typography>
                          <Box height={100} mt={-2} bgcolor={blueGrey[50]} pl={1}
                            sx={{
                              "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                                padding: 0
                              },
                              "& .MuiDataGrid-virtualScroller": { backgroundColor: blueGrey[50] },
                              "& .MuiDataGrid-root": { border: "none" }
                            }}>
                            <DataGridCellExpand
                              hideToolbar
                              headerHeight={0}
                              hideFooter
                              rowHeight={30}
                              rows={selectedCusData}
                              columns={columns(user.AccTypId === ACC_TYP_EMP ? fn.handleDeleteRowCus : null)} />
                          </Box>
                        </BoxFC>
                        <BoxFC flex={1}>
                          <Typography fontWeight="bold" >งานที่เลือก</Typography>
                          <Box height={100} mt={-2} bgcolor={blueGrey[50]} pl={1}
                            sx={{
                              "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                                padding: 0
                              },
                              "& .MuiDataGrid-virtualScroller": { backgroundColor: blueGrey[50] },
                              "& .MuiDataGrid-root": { border: "none" }
                            }}>
                            <DataGridCellExpand
                              hideToolbar
                              headerHeight={0}
                              hideFooter
                              rowHeight={30}
                              rows={selectedJobData}
                              columns={columns(user.AccTypId === ACC_TYP_EMP ? fn.handleDeleteRow : null)} />
                          </Box>
                        </BoxFC>
                      </BoxFR>
                    </BoxFC>

                    {user.AccTypId === ACC_TYP_EMP || user?.Pms?.find(pm => pm.Code === "FIN") ? (
                      <CheckboxFormControl
                        disabled={user.AccTypId !== ACC_TYP_EMP}
                        sx={{ mt: -1 }} label="สิทธิ์ทางบัญชี"
                        checked={fn.pmValue(3, 1)}
                        onChange={(e) => fn.pmChange(3, 1, e.target.checked)} />
                    ) : null
                    }
                  </BoxFC>
              }
              <Divider sx={{ mt: -1 }} />
              <Box width={300}>
                {selectedAccId > 0 && mode !== DIALOG_MODE_PROFILE &&
                  <BoxFC sx={{ gap: 1 }} >
                    <Typography variant="h6" >กำหนดพาสเวิร์ดใหม่</Typography>
                    <BoxFR >
                      <TextField sx={{ flex: 1 }} variant="outlined" type="password" label="พาสเวิร์ดใหม่" size="small" value={password} onChange={(e) => fn.setPassword(e.target.value)} />
                      <Button sx={{ flex: 1, px: 0 }} variant="contained" onClick={fn.handleSetNewPassword}>เปลี่ยนพาสเวิร์ด</Button>
                    </BoxFR>
                  </BoxFC>
                }
                {
                  mode === DIALOG_MODE_PROFILE &&
                  <BoxFC sx={{ gap: 1 }}>
                    <Typography variant="h6" >เปลี่ยนพาสเวิร์ด</Typography>
                    <BoxFR>
                      <TextField sx={{ flex: 1 }} variant="outlined" type="password" label="พาสเวิร์ดใหม่" size="small"
                        value={password} onChange={(e) => fn.setPassword(e.target.value)} />
                      <TextField sx={{ flex: 1 }} variant="outlined" type="password" label="ยืนยันพาสเวิร์ด" size="small"
                        value={confirmPassword} onChange={(e) => fn.setConfirmPassword(e.target.value)} />
                    </BoxFR>
                    <BoxFR>
                      <TextField sx={{ flex: 1 }} variant="outlined" type="password" label="พาสเวิร์ดเดิม" size="small"
                        value={oldPassword} onChange={(e) => fn.setOldPassword(e.target.value)} />
                      <Button sx={{ flex: 1, px: 0 }} variant="contained" onClick={fn.handleChangePassword}>เปลี่ยนพาสเวิร์ด</Button>
                    </BoxFR>
                  </BoxFC>
                }
              </Box>
            </BoxFC>
          </Grid2>
          <Grid2 xs={12}>
            {
              selectedAccId > 0 && <ModAccName modDte={dialogData.ModDte} modAcc={dialogData.ModAcc} />
            }
          </Grid2>
        </Grid2>
        <BoxFC sx={{ gap: 0 }} mt={1} >
          <BoxFR sx={{ gap: 2, alignItems: "flex-start" }} justifyContent="space-around" >


          </BoxFR>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={selectedAccId > 0 ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={() => validate(validation) && fn.insertOrUpdate()} />
    </Dialog>
  )
}

export default React.memo(AccountDialog)