export const driverAttendApiNm = {
  getTruckAttendTable: "/truck-attend/getTruckAttendTable",
  getTruckAttendStatus: "/truck-attend/getTruckAttendStatus",
  updateTruckAttendCols: "/truck-attend/updateTruckAttendCols",
  insertDuplicateUpdateBulkTruckAttend: "/truck-attend/insertDuplicateUpdateBulkTruckAttend",
  copyTruckAttend: "/truck-attend/copyTruckAttend",
  insertTruckAttendFromJobOrder: "/truck-attend/insertTruckAttendFromJobOrder",
}


