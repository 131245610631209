
export const branchApiNm = {
  getJobOrderTruckCoordinate: "/branch/map/getJobOrderTruckCoordinate",
  
  getTruckIncomeSummary: "/branch/getTruckIncomeSummary",
  getJobOrderAllDetail: "/branch/getJobOrderAllDetail",
  getDailyJobWithDriverIncome: "/branch/getDailyJobWithDriverIncome",
  getDriverIncomeShipment: "/branch/getDriverIncomeShipment",
  getDailyExpenseReport: "/branch/getDailyExpenseReport",
  updateDailyJobWithOthers: "/branch/updateDailyJobWithOthers",
  insertUpdateDriverIncomeShipment: "/branch/insertUpdateDriverIncomeShipment",
  getFinJobOrder: "/branch/getFinJobOrder",
  getInvoice: "/branch/getInvoice",
  getInvoiceDataForPrint: "/branch/getInvoiceDataForPrint",
  getPrintInvoiceConfig: "/branch/getPrintInvoiceConfig",
  insertPrintInvoiceConfig: "/branch/insertPrintInvoiceConfig",
  deletePrintInvoiceConfig: "/branch/deletePrintInvoiceConfig",
  getPrintLocationText: "/branch/getPrintLocationText",
  insertPrintLocationText: "/branch/insertPrintLocationText",
  deletePrintLocationText: "/branch/deletePrintLocationText",
  
  getDashBoardOperation: "/branch/dashboard/getDashBoardOperation",
  getDriverWithJob: "/branch/dashboard/getDriverWithJob",
  getTruckWithJob: "/branch/dashboard/getTruckWithJob",
  getJobOrderByTime: "/branch/dashboard/getJobOrderByTime",

  getJobPlaning: "/branch/job-planing/getJobPlaning",
  getJobPlaningV2: "/branch/job-planing/getJobPlaningV2",
  getJobOrderByDriver: "/branch/job-planing/getJobOrderByDriver",
  getJobORderByFinDte: "/branch/job-planing/getJobORderByFinDte",
  getJobWorkingTime: "/branch/job-planing/getJobWorkingTime",
  updateJobWorkingTime: "/branch/job-planing/updateJobWorkingTime",
  updateJobOrderAllDetail: "/branch/job-planing/updateJobOrderAllDetail",
  updateTo2x20: "/branch/job-planing/updateTo2x20",
  
  getFuelJob: "/branch/fuel-driver/getFuelJob",
  getFuelDriverRemain: "/branch/fuel-driver/getFuelDriverRemain",
  getFuelDriverRemainSummary: "/branch/fuel-driver/getFuelDriverRemainSummary",
  getFuelTransaction: "/branch/fuel-driver/getFuelTransaction",
  getFuelStationTransaction: "/branch/fuel-driver/getFuelStationTransaction",
  getLastFuelStationTransaction: "/branch/fuel-driver/getLastFuelStationTransaction",
  getDriverFuelMinMax: "/branch/fuel-driver/getDriverFuelMinMax",
  insertUpdateDriverFuelMinMax: "/branch/fuel-driver/insertUpdateDriverFuelMinMax",
  deleteDriverFuelMinMax: "/branch/fuel-driver/deleteDriverFuelMinMax",
  insertFuelTransaction: "/branch/fuel-driver/insertFuelTransaction",
  insertFuelStationTransaction: "/branch/fuel-driver/insertFuelStationTransaction",
  updateFuelStationTransaction: "/branch/fuel-driver/updateFuelStationTransaction",
  updateFuelStationTransactionColumn: "/branch/fuel-driver/updateFuelStationTransactionColumn",
  insertFuelJob: "/branch/fuel-driver/insertFuelJob",
  updateFuelJob: "/branch/fuel-driver/updateFuelJob",
  deleteFuelJob: "/branch/fuel-driver/deleteFuelJob",
  
  getDriverPayTable: "/branch/driver-pay/getDriverPayTable",
  getJobDriverPay: "/branch/driver-pay/getJobDriverPay",
  getDriverPay: "/branch/driver-pay/getDriverPay",
  getExpenseJobDriver: "/branch/driver-pay/getExpenseJobDriver",
  getExpenseJobDriverOrDefault: "/branch/driver-pay/getExpenseJobDriverOrDefault",
  insertExpenseJobDriverBulk: "/branch/driver-pay/insertExpenseJobDriverBulk",
  insertDriverPay: "/branch/driver-pay/insertDriverPay",
  deleteDriverPay: "/branch/driver-pay/deleteDriverPay",
  updatePayDte: "/branch/driver-pay/updatePayDte",
  insertJobDriverPay: "/branch/driver-pay/insertJobDriverPay",
  updateJobDriverPay: "/branch/driver-pay/updateJobDriverPay",
  deleteJobDriverPay: "/branch/driver-pay/deleteJobDriverPay",
  insertJobDriverPayBulk: "/branch/driver-pay/insertJobDriverPayBulk",
  
  getInvoiceReview: "/branch/invoice/getInvoiceReview",
  getExpenseJobOrderDetail: "/branch/invoice/getExpenseJobOrderDetail",
  getInvoiceDetail: "/branch/invoice/getInvoiceDetail",
  generateInvoiceNo: "/branch/generateInvoiceNo",
}