import {  Typography } from "@mui/material";
import { BoxFC,  } from "components/BoxCustom";
import React, { useCallback } from "react";
import {  moveRowTo } from "utils";
import { Box } from "@mui/system";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { columns } from "./columns";

const DailyTableColWidthContent = ({ dailyTableColWidth, setDailyTableColWidth }) => {

  const handleChangeCol = useCallback((id, field, value) => {
    console.log("handleChangeCol::", id, field, value)
    setDailyTableColWidth(o => {
      const result = [...o]
      const row = result.find(r => r.id === id)
      console.log("row::", row)
      row[field] = value
      console.log("result::", result)
      return result
    })
  }, [setDailyTableColWidth])

  const handleRowOrderChange = useCallback((params) => {
    console.log(params)
    setDailyTableColWidth(o => moveRowTo(o, params.oldIndex, params.targetIndex).map((row, index) => ({ ...row, order: index })))
  }, [setDailyTableColWidth])


  const handleEditCommit = useCallback((params,  e) => {
    // console.log("params::", params)
    // console.log("e::", e)
    if (params.field === "width") {
      handleChangeCol(params.id, params.field, params.value)
    }
  }, [handleChangeCol])

  console.log("dailyTableColWidth::", dailyTableColWidth)
  return (
    <BoxFC height="100%" sx={{ gap: 0 }}>
      <Typography variant="h6">ตารางงานประจำวัน</Typography>
      <Box flex={1} width={550}>
        <DataGridCellExpand
          disableSelectionOnClick
          rowReordering
          onRowOrderChange={handleRowOrderChange}
          hideFooter
          hideToolbar
          rows={dailyTableColWidth}
          columns={columns(handleChangeCol)}
          onCellEditCommit={handleEditCommit}
        />
      </Box>
    </BoxFC>
  )
}

export default React.memo(DailyTableColWidthContent)