
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";

import { BoxFC, BoxFR } from "components/BoxCustom";
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime";
import { GridActionsCellItem } from "@mui/x-data-grid";

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PushPinIcon from '@mui/icons-material/PushPin';
import DeleteIcon from '@mui/icons-material/Delete';
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import dayjs from "dayjs";
import { UserContext } from "contexts/UserContext";

const Memo = ({ memoData, setMemoData, msData, dialogOpen }) => {

  const [memoText, setMemoText] = useState("");
  const [memoIsPin, setMemoIsPin] = useState(1);
  const [memoTime, setMemoTime] = useState(null);
  const { user} = useContext(UserContext)

  
  const sortedMemoData = useMemo(()=>{
    const result = memoData.map((item)=>({...item}))
    return result.sort((a,b)=> {
      if(a.IsPin && !b.IsPin) return -1
      else if(b.IsPin && !a.IsPin) return 1
      else return dayjs(a.MemTm).unix()-dayjs(b.MemTm).unix()
    })
  }, [memoData])
  const datePickerJobOrderProp = () => ({

    label: "เวลา memo",
    inputFormat: "DD/MM HH:mm",
    mask: "__/__ __:__",
    value: memoTime,
    onChange: (newValue) => setMemoTime(newValue),
    renderInput: (params) => <TextField size="small"  {...params} sx={{ width: 135 }} />,
  })

  const textFieldProps = useMemo(() => ({
    variant: "outlined",
    size: "small",
    label: "memo",
    value: memoText,
    sx: { width: 180 },
    onChange: (e) => setMemoText(e.target.value)
  }), [memoText])


  const handleAddMemo = useCallback(() => {
    setMemoData(o => {
      const maxId = o.reduce((prev, cur) => cur.id > prev ? cur.id : prev, 0)
      return [ {
        id: maxId + 1,
        ModAccId: user.AccId,
        Memo: memoText,
        MemTm: memoTime,
        IsPin: memoIsPin,
      }, ...o]
    })
    setMemoText("");

  }, [user, memoText, memoTime, memoIsPin, setMemoData])

  const handleDeleteMemo = useCallback((row) => {
    console.log("handleDeleteMemo::", row)
    setMemoData(o => {
      console.log("handleDeleteMemo::", o)
      return o.filter(item => item.id !== row.id)
    })
  }, [setMemoData])

  const columns = useMemo(() => [
    {
      field: "IsPin",
      headerName: "Pin",
      width: 50,

      renderCell: (params) => {
        if ( params.value === 1) {
          return <PushPinIcon color="primary" />
        } else if ( params.value === 0) {
          return <PushPinIcon color="disabled" />
        } else {
          return ""
        }
      },
    },
    // {
    //   field: "MemTyp",
    //   headerName: "ประเภท",
    //   width: 70,
    //   valueFormatter: (params) => {
    //     if (params.value === "LO") return "Log"
    //     else if (params.value === "JO") return "ใบงาน"
    //     else if (params.value === "SH") return "ใบแจ้งลาก"
    //     else if (params.value === "CS") return "ปล่อยงาน"
    //     else return "N/A"
    //   },
    // },
    {
      field: "MemTm",
      headerName: "วัน-เวลา",
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
      width: 100,
    },
    {
      field: "Memo",
      headerName: "Memo",
      flex: 1,
    },
    {
      field: "ModAccId",
      headerName: "ผู้แก้ไข",
      valueGetter: (params)=>msData.accountObj[params.value]?.NName || "-",
      width: 70,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 30,
      getActions: (params) =>   [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteMemo(params.row)}
        />
      ] 
    },
  ], [handleDeleteMemo, msData.accountObj])

  useEffect(()=>{
    if(dialogOpen){
      setMemoText("")
      setMemoTime(dayjs())
    }
  }, [dialogOpen])

  console.log("in render Memo ::", sortedMemoData)
  return (
    <BoxFC sx={{ gap: 1}} height="100%">
      <BoxFR>
        <Typography variant="h6" sx={{ width: "100%" }}>Memo</Typography>
      <Box display="flex" flexDirection="row" gap={1}>
        <DateTimePickerCustomTime {...datePickerJobOrderProp()} />
        <TextField {...textFieldProps} />
        <IconButton sx={{p:0}} onClick={(e) => setMemoIsPin(o => o === 0 ? 1 : 0)}>
          <PushPinIcon color={memoIsPin ? "primary" : "disabled"} />
        </IconButton>
        <Button variant="contained" onClick={handleAddMemo}>เพิ่ม</Button>

      </Box>
      </BoxFR>
      
      <Box width="100%" flexGrow={1}
        sx={{
          "& .pined": {
            bgcolor: "Khaki ",
            "&:hover": {
              bgcolor: "darkKhaki!important"
            }
          }
        }}>
        <DataGridCellExpand
        density="compact"
          hideFooter
          rows={sortedMemoData}
          columns={columns}
          disableSelectionOnClick
          getRowClassName={(params) => params.row.IsPin === 1 ? "pined" : null}
        />
      </Box>
    </BoxFC>
  )
}

export default React.memo(Memo)