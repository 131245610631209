import { Dialog, DialogContent, Typography } from "@mui/material"
import { alertSuccess } from "components/Alert"
import { BoxFC, BoxFR } from "components/BoxCustom"
import FileDropPanel from "components/FileUplaod/FileDropPanel"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { jobOrderApiNm } from "constants/jobOrderConstant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { resizeImage } from "utils/resizeImage"

const AddJobOrderFileDialog = ({ selectedId, dialogOpen, setDialogOpen, onFinish }) => {

  const { ax } = useContext(GlobalStateContext)
  const [contNoFiles, setContNoFiles] = useState([])
  const [sealNoFiles, setSealNoFiles] = useState([])
  const [otherFiles, setOtherFiles] = useState([])
  const [contConFiles, setContConFiles] = useState([])

  const uploadFile = useCallback(async (file, jobOrdFileTypId, jobOrdId, drvId) => {
    
    let label = ""
    if (jobOrdFileTypId === 1) {
      label = "หมายเลขตู้"
    } else if (jobOrdFileTypId === 2) {
      label = "หมายเลขซีล"
    } else if (jobOrdFileTypId === 3) {
      label = "สภาพตู้"
    } else if (jobOrdFileTypId === 4) {
      label = "รูปอื่นๆ"
    }
    // const resizedFile  = await resizeFile(file)
    const resizedFile = await resizeImage(file)
    const formData = new FormData()
    formData.append("file", resizedFile)
    formData.append("Label", label)
    formData.append("JobOrdFileTypId", jobOrdFileTypId)
    formData.append("JobOrdId", jobOrdId)

    return ax.post(jobOrderApiNm.uploadJobOrderFile, formData)
  }, [ax])
  const handleSave = useCallback(async () => {
    // console.log("drvSts1::", drvSts1)
    for (const file of contNoFiles) {
      await uploadFile(file, 1, selectedId)
    }

    for (const file of sealNoFiles) {
      await uploadFile(file, 2, selectedId)
    }

    for (const file of contConFiles) {
      await uploadFile(file, 3, selectedId)
    }

    for (const file of otherFiles) {
      await uploadFile(file, 4, selectedId)
    }

    alertSuccess("บันทึกเรียบร้อยแล้ว")
    setDialogOpen(false)
    onFinish()
  }, [contNoFiles, sealNoFiles, contConFiles, otherFiles, selectedId, setDialogOpen, uploadFile, onFinish])
  useEffect(() => {
    if (!dialogOpen) {
      setContNoFiles([])
      setSealNoFiles([])
      setOtherFiles([])
      setContConFiles([])
    }
  }, [dialogOpen, ax])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"เพิ่มรูปใบงาน"}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFR mt={2} sx={{ justifyContent: "space-evenly", alignItems: "stretch" }} height={400}>
          <BoxFC width="100%" sx={{ gap: 0 }}>
            <Typography variant="h6">หมายเลขตู้</Typography>
            <FileDropPanel height="100%" selectedFiles={contNoFiles} setSelectedFiles={setContNoFiles} />
          </BoxFC>
          <BoxFC width="100%" sx={{ gap: 0 }}>
            <Typography variant="h6">หมายเลขซีล</Typography>
            <FileDropPanel height="100%" selectedFiles={sealNoFiles} setSelectedFiles={setSealNoFiles} />
          </BoxFC>
          <BoxFC width="100%" sx={{ gap: 0 }}>
            <Typography variant="h6">สภาพตู้</Typography>
            <FileDropPanel height="100%" selectedFiles={contConFiles} setSelectedFiles={setContConFiles} />
          </BoxFC>
          <BoxFC width="100%" sx={{ gap: 0 }}>
            <Typography variant="h6">รูปอื่นๆ</Typography>
            <FileDropPanel height="100%" selectedFiles={otherFiles} setSelectedFiles={setOtherFiles} />
          </BoxFC>

        </BoxFR>
      </DialogContent>
      <DialogFooter okText={"บันทึก"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleSave} />
    </Dialog>
  )
}

export default React.memo(AddJobOrderFileDialog, (o, n) => o.dialogOpen === n.dialogOpen)