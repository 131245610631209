import { DesktopDatePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { BoxFC, BoxFR } from "components/BoxCustom"
import React, { useCallback, useMemo, useState, useRef, useEffect } from "react"
import { Box, Button, Fade, IconButton, InputAdornment, Popover, Radio, RadioGroup, TextField } from "@mui/material";
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import dayjs from "dayjs";
import { lightBlue } from '@mui/material/colors';
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded';

const NumDteBox = ({ label, numDte, handleNumDteChange }) => {
  return (
    <TextField fullWidth size="small" label={label} type="number" value={numDte}
      onChange={(e) => handleNumDteChange(e.target.value)}
      inputProps={{ style: { textAlign: "center" } }}
      InputProps={{
        startAdornment: <InputAdornment position="start">จากวันนี้</InputAdornment>,
        endAdornment: <InputAdornment position="end">วัน</InputAdornment>,
      }} />
  )
}

const AddIcon = ({ onClick, sx }) => {
  return (
    <IconButton sx={{ m: 0, p: 0, ...sx }} onClick={onClick}>
      <AddCircleRoundedIcon color="primary" />
    </IconButton >
  )
}

const SubtractIcon = ({ onClick, sx }) => {
  return (
    <IconButton sx={{ m: 0, p: 0, ...sx }} onClick={onClick}>
      <RemoveCircleRoundedIcon color="primary" />
    </IconButton >
  )
}

/**
 * 
 * @param {radioValue} value can be "dte", "num" or "range"
 * @returns 
 */
const DateTimeSelect = ({ label, start, end, setSelectDate, sx, getData, initMode }) => {

  const inputDateEl = useRef(null);
  const [dteSt, setDteSt] = useState(start ? dayjs(start) : null)
  const [dteEn, setDteEn] = useState(end ? dayjs(end) : null)
  const [numDte, setNumDte] = useState(1)
  const [radioValue, setRadioValue] = useState(initMode || "dte");
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const displayTextRange = useMemo(() => {
    if (!dteSt || !dteEn) return ""

    const diff = dteEn.diff(dteSt, 'day')
    return `${dteSt.format("DD/MM/YYYY")} + ${diff} วัน`
  }, [dteSt, dteEn])

  const setSelectDateCheckNull = useCallback((st, en) => {
    setSelectDate(st ? st.format("YYYY-MM-DD HH:mm:ss.SSS") : null, en ? en.format("YYYY-MM-DD HH:mm:ss.SSS") : null)
  }, [setSelectDate])
  const setValue = useCallback((newValue) => {
    const st = newValue ? dayjs(newValue.format("YYYY-MM-DD 00:00:00.000")) : null
    const en = newValue ? dayjs(newValue.format("YYYY-MM-DD 23:59:59.997")) : null
    setDteSt(st)
    setDteEn(en)
    setSelectDateCheckNull(st, en)
  }, [setSelectDateCheckNull])
  const setDefaultDate = useCallback(() => {
    setValue(dayjs())
  }, [setValue])

  const onDisplayDesktopDatePickerChange = useCallback((newValue) => {
    setValue(newValue)
  }, [setValue])


  const handleAddDate = useCallback(num => {
    if (!dteSt || !dteEn) {
      setDefaultDate()
    } else {
      setDteSt(o => {
        const st = o.add(num, "day")
        const en = dayjs(st.format("YYYY-MM-DD 23:59:59.997"))
        setDteEn(en)
        setSelectDateCheckNull(st, en)
        return dayjs(st.format("YYYY-MM-DD 00:00:00.000"))
      })
    }
    setRadioValue("dte")
  }, [dteSt, dteEn, setDefaultDate, setSelectDateCheckNull])

  const handleDesktopDatePickerKeyDown = useCallback((e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      const num = e.key === "ArrowUp" ? 1 : -1
      // setDteSt(oSt => {
      //   const st = dayjs(oSt).add(num, "day")
      //   setDteEn(oEn => {
      //     const en = dayjs(oEn).add(num, "day")
      //     setSelectDateCheckNull(st, en)
      //     return en
      //   })
      //   return st
      // })
      const st = dteSt.add(num, "day")
      const en = dteEn.add(num, "day")
      setDteSt(st);
      setDteEn(en);
      setSelectDateCheckNull(st, en)
    } else if (e.key === "Enter") {
      console.log("enter")
      if (getData) {
        getData()
      }
    }
  }, [getData, setSelectDateCheckNull, dteSt, dteEn])

  const displayDesktopDatePickerProp = useMemo(() => ({
    label: label,
    inputFormat: "DD/MM/YYYY",
    mask: "__/__/____",
    value: dteSt,
    InputAdornmentProps:{ style: { marginLeft: 0 } },
    onChange: onDisplayDesktopDatePickerChange,
    renderInput: (params) => (
      <TextField sx={{ flexGrow: 1 }} size="small" {...params} onKeyDown={handleDesktopDatePickerKeyDown} />
    ),
  }), [label, dteSt, onDisplayDesktopDatePickerChange, handleDesktopDatePickerKeyDown])

  const handleRangeChange = useCallback((newValue, pos) => {
    //TODO::check null and set radio value
    setRadioValue("range")
    if (!newValue) {
      setValue(null)
      return
    }
    if (pos === "start") {
      const st = dayjs(newValue.format("YYYY-MM-DD 00:00:00.000"))
      setDteSt(st)
      setSelectDateCheckNull(st, dteEn)
    } else {
      const en = dayjs(newValue.format("YYYY-MM-DD 23:59:59.997"))
      setDteEn(en)
      setSelectDateCheckNull(dteSt, en)
    }

  }, [setValue, dteSt, dteEn, setSelectDateCheckNull])

  const datePickerProp = useCallback((label, pos) => ({
    label: label,
    inputFormat: "DD/MM/YYYY",
    mask: "__/__/____",
    onChange: (newValue) => handleRangeChange(newValue, pos),
    renderInput: (params) => <TextField sx={{ width: 145 }} size="small" {...params} />
  }), [handleRangeChange])

  const calculateNumDateSetDte = useCallback((num) => {
    const today = dayjs()
    let st = null
    let en = null
    if (num > 0) {
      st = dayjs(today.format("YYYY-MM-DD 00:00:00.000"))
      en = dayjs(today.add(num, "day").format("YYYY-MM-DD 23:59:59.997"))
    } else if (num < 0) {
      st = dayjs(today.add(num, "day").format("YYYY-MM-DD 00:00:00.000"))
      en = dayjs(today.format("YYYY-MM-DD 23:59:59.997"))
    } else {
      st = dayjs(today.format("YYYY-MM-DD 00:00:00.000"))
      en = dayjs(today.format("YYYY-MM-DD 23:59:59.997"))
    }
    setDteSt(st)
    setDteEn(en)
    setSelectDateCheckNull(st, en)
    setRadioValue("num")
  }, [setSelectDateCheckNull])
  const handleAddNumDate = useCallback(num => {
    if (!dteSt || !dteEn) {
      setDefaultDate()
      setRadioValue("num")
      return
    }
    setNumDte(o => {
      const result = o + num
      calculateNumDateSetDte(result)
      return result
    })
  }, [calculateNumDateSetDte, dteSt, dteEn, setDefaultDate])

  const handleNumDteChange = useCallback((num) => {
    setNumDte(num)
    calculateNumDateSetDte(num)
  }, [calculateNumDateSetDte])

  const handleAddNumRange = useCallback((num, pos) => {

    setRadioValue("range")
    if (!dteSt || !dteEn) {
      setDefaultDate()
      return
    }
    if (pos === "start") {
      setDteSt(o => {
        const result = o.add(num, "day")
        if (result.isAfter(dteEn)) return o
        else {
          setSelectDateCheckNull(result, dteEn)
          return result
        }
      })
    } else {
      setDteEn(o => {
        const result = o.add(num, "day")
        if (result.isBefore(dteSt)) return o
        else {
          setSelectDateCheckNull(dteSt, result)
          return result
        }
      })
    }
  }, [dteEn, dteSt, setDefaultDate, setSelectDateCheckNull])


  const handleRadioChange = useCallback(mode => {
    console.log("mode::", mode);
    console.log("radioValue::", radioValue)
    console.log("mode === radioValue::", mode === radioValue)
    if (mode === radioValue) return
    setRadioValue(mode)
    if (mode === "dte") {
      const en = dteSt ? dayjs(dteSt.format("YYYY-MM-DD 23:59:59.997")) : null
      setDteEn(en)
      setSelectDateCheckNull(dteSt, en)
    } else if (mode === "num") {
      calculateNumDateSetDte(numDte)
    } else if (mode === "range") {

      setSelectDateCheckNull(dteSt, dteEn)
    }
  }, [numDte, calculateNumDateSetDte, dteSt, dteEn, radioValue, setSelectDateCheckNull])

  // const handleTextKeyDown = useCallback(()=>{
  //   if(e.key === "ArrowUp" || e.key === "ArrowDown"){
  //     e.preventDefault();
  //     if()
  //     const num = e.key === "ArrowUp"?1: -1
  //     setDteSt(o=>dayjs(o).add(num, "day"))
  //     setDteEn(o=>dayjs(o).add(num, "day"))
  //   } else if(e.key === "Enter"){
  //     console.log("enter")
  //     if(getData){
  //       getData()
  //     }
  //   }
  // }, [])
  const displayDate = useMemo(() => {
    if (radioValue === "dte") {
      return <DesktopDatePicker  {...displayDesktopDatePickerProp} clearable={true} />
    } else if (radioValue === "num") {
      return <NumDteBox label={label} numDte={numDte} handleNumDteChange={handleNumDteChange} />
    } else if (radioValue === "range") {
      return <TextField value={displayTextRange} label={label} size="small" onKeyDown={handleDesktopDatePickerKeyDown} />
    }
  }, [radioValue, numDte, displayDesktopDatePickerProp, displayTextRange, handleNumDteChange, label, handleDesktopDatePickerKeyDown])

  const handleDeleteDate = useCallback(() => {
    setRadioValue("dte")
    setValue(null)
  }, [setValue])
  useEffect(() => {
    start ? setDteSt(dayjs(start)) : setDteSt(null)
    end ? setDteEn(dayjs(end)) : setDteEn(null)
    if (start && end) {
      if(initMode){
        // setNumdte from calculate date diff between end and  start
        const diff = dayjs(start).diff(dayjs(end), "day")
        setNumDte(diff)
        setRadioValue(initMode)
      } else if (dayjs(start).format("YYYY-MM-DD") !== dayjs(end).format("YYYY-MM-DD")) {
        setRadioValue("range")
      }
    }
  }, [start, end, initMode])
  console.log("dteSt - dteEn::", dteSt ? dteSt.format("YYYY-MM-DD HH:mm:ss") : "null", dteEn ? dteEn.format("YYYY-MM-DD HH:mm:ss") : "null")
  return (
    <Box>
      <BoxFR ref={inputDateEl} width={200} sx={{ ...sx, gap: 0, px: 0.9 }} position="relative">

        {displayDate}
        <SubtractIcon sx={{ position: "absolute", top: 8, left: -3 }} onClick={() => handleAddDate(-1)} />
        <AddIcon sx={{ position: "absolute", top: 8, right: -3 }} onClick={() => handleAddDate(1)} />
        {!open &&
          <IconButton sx={{ m: 0, p: 0, position: "absolute", top: 26, left: 0, right: 0, marginLeft: "auto", marginRight: "auto" }} onClick={(e) => setAnchorEl(inputDateEl.current)}>
            <ExpandCircleDownRoundedIcon color="primary" />
          </IconButton >
        }
      </BoxFR>
      <Popover
        id="filterPopOver"
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <Box>
          <Fade in={open}>
            <RadioGroup value={radioValue} onChange={(e) => handleRadioChange(e.target.value)}>
              <BoxFC sx={{ gap: 0 }}>

                <BoxFR sx={{ gap: 0, p: 1, bgcolor: radioValue === "dte" && lightBlue[50] }}>
                  <Radio value="dte" />
                  <SubtractIcon onClick={() => handleAddDate(-1)} />
                  <Box width={146}><DesktopDatePicker {...displayDesktopDatePickerProp} label="วันที่" /></Box>
                  <AddIcon onClick={() => handleAddDate(1)} />
                </BoxFR>
                <BoxFR sx={{ gap: 0, mt: 0.5, p: 1, bgcolor: radioValue === "num" && lightBlue[50] }}>
                  <Radio value="num" />
                  <SubtractIcon onClick={() => handleAddNumDate(-1)} />
                  <Box width={146}><NumDteBox label="จำนวนวัน" numDte={numDte} handleNumDteChange={handleNumDteChange} /></Box>
                  <AddIcon onClick={() => handleAddNumDate(1)} />
                </BoxFR>
                <BoxFR sx={{ gap: 0, mt: 0.5, p: 1, bgcolor: radioValue === "range" && lightBlue[50] }}>
                  <Radio value="range" />
                  <BoxFC sx={{ gap: 1 }}>
                    <BoxFR sx={{ gap: 0 }}>
                      <SubtractIcon onClick={() => handleAddNumRange(-1, "start")} />
                      <DesktopDatePicker {...datePickerProp("วันเริ่มต้น", "start")} value={dteSt} />
                      <AddIcon onClick={() => handleAddNumRange(1, "start")} />
                    </BoxFR>
                    <BoxFR sx={{ gap: 0 }}>
                      <SubtractIcon onClick={() => handleAddNumRange(-1, "end")} />
                      <DesktopDatePicker {...datePickerProp("วันสิ้นสุด", "end")} value={dteEn} />
                      <AddIcon onClick={() => handleAddNumRange(1, "end")} />
                    </BoxFR>
                  </BoxFC>
                </BoxFR>
                <BoxFR sx={{ gap: 1, p: 1 }}>
                  <Button variant="contained" color="secondary" fullWidth onClick={handleDeleteDate}>
                    <BackspaceRoundedIcon sx={{ mr: 1 }} />
                    ลบวันที่
                  </Button>
                  <Button variant="contained" color="primary" fullWidth onClick={() => setAnchorEl(null)}>ตกลง</Button>
                </BoxFR>

              </BoxFC>
            </RadioGroup>
          </Fade>
        </Box>
      </Popover>
    </Box>
  )
}

export default React.memo(DateTimeSelect)