

import { EditRounded } from "@mui/icons-material";
import { Typography, Box, IconButton } from "@mui/material";
import { BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import dayjs from "dayjs";
import React from "react";
import ContainerStatusItem from "./ContainerStatusItem";
import {suggessDrvCombo} from "utils/suggessDrvCombo"

export const drawBox = (msData, contStsData, setContStsData, options) => {
  return (
    <Box key={contStsData.ContStsId} gap={1} sx={{
      padding: 1,
      display: "flex",
      flexDirection: "column",
      bgcolor: contStsData.DrvId && `${contStsData.BgClr}.main`,
      border: "2px solid ",
      borderColor: `${contStsData.BgClr}.main`,
      borderRadius: 2,
      flex: 1
    }}>
      <BoxFR>
        <Typography color={contStsData.DrvId && "#FFFFFF"}>{contStsData.Name} </Typography>
        <Box flex={1} />
        <BoxFR sx={{ gap: 0.5 }}>
          {/* <Schedule sx={{ px: 0, mx: 0, color: contStsData.DrvId ? "white" : "black" }} /> */}
          <Typography color={contStsData.DrvId && "#FFFFFF"}>{contStsData.StsTm?dayjs(contStsData.StsTm).format("DD/MM HH:mm"):"วัน-เวลา"}</Typography>
          <IconButton sx={{p:0}}>
            <EditRounded sx={{ px: 0, mx: 0, color: contStsData.DrvId ? "white" : "black" }} />
          </IconButton>
        </BoxFR>

      </BoxFR>
      <Box display="flex" gap={1}>
        <ComboBox sx={{ flexGrow: 1 }} options={options || msData.driverCombo} required={false}
          selectedId={contStsData.DrvId}
          setSelectedId={(id) => {
            contStsData.DrvId = id;
            contStsData.StsTm = id && dayjs()
            setContStsData(oldData => [...oldData])
          }}
        />

        {/* <DesktopDateTimePicker
          label="เวลา"
          inputFormat="DD/MM HH:mm"
          mask="__/__ __:__"
          value={contStsData && contStsData.StsTm}
          onChange={(newValue) => { contStsData.stsTm = newValue; setContStsData(oldData => ([...oldData])) }}
          renderInput={(params) => <TextField size="small" sx={{width:150}}  {...params} />} /> */}
      </Box>
    </Box>
  )
}
const ContainerStatus = ({ msData, contStsData, setContStsData, direction, options, setJobOrderData, suggessDrvId
, hideDriverActTm, hideIsCmpt, processDrvId }) => {
  // console.log("options::", options)
  return (
    <Box display="flex" flexDirection={direction || "column"} gap={1} mt={0}>
      {/* {msData.containerStatuses.map((item) => drawBox(msData, item.ContStsId, item.Name, item.BgClr, contStsData.find(it => it.ContStsId === item.ContStsId), setContStsData))} */}
      {contStsData.map((item, index) => {
        let sDrvId = suggessDrvId || null
        if(!sDrvId){
          for (let i = index - 1; i >= 0; i--) {
            if (contStsData[i].DrvId) {
              sDrvId = contStsData[i].DrvId
              break
            }
          }
        }
        return (
        // drawBox(msData, item, setContStsData, options)
        <ContainerStatusItem 
          key={item.ContStsId} 
          msData={msData} 
          contStsData={item} 
          setContStsData={setContStsData} 
          options={suggessDrvCombo(sDrvId, options)} 
          setJobOrderData={setJobOrderData}
          suggessDrvId={suggessDrvId}
          hideDriverActTm={hideDriverActTm}
          hideIsCmpt={hideIsCmpt}
          processDrvId={processDrvId}/>
      )})}
    </Box>
  )
}

export default React.memo(ContainerStatus)