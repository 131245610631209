

import { jsPDF } from "jspdf";
import "resource/font/THSarabun-normal"
import "resource/font/THSarabun-bold"
import dayjs from "dayjs";
import companyLogo from "branch/resource/company-logo.png"

export const printCheckupCheck = ({ data, items, msData }) => {

  console.log("data::", data)
  console.log("items::", items)
  const pageSize = [8.3, 11.7]; //A4
  const startY = 0.4
  const startX = 0.5
  const lineHeight = 0.25
  const logoWidth = lineHeight * 2
  const logoHeight = lineHeight * 2
  const logoPadding = 0.1
  const contentSize = 13;
  const MAX_ROW = 30
  const numPage = Math.ceil(items.length / MAX_ROW)

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "in",
    format: pageSize
  });

  let lastCatId = 0
  let catIndex = 0;
  let itemIndex = 0;

  for (let curPage = 1; curPage <= numPage; curPage++) {
    if (curPage !== 1) {
      doc.addPage()
    }

    let yPos = startY
    let xPos = startX
    const img = new Image()
    //TODO:: finally change to /file/etc/company_log.png
    img.src = companyLogo

    try {
      doc.addImage(img, 'png', xPos + logoPadding / 2, yPos + logoPadding / 2, logoWidth - logoPadding, logoHeight - logoPadding)
      xPos += logoWidth + 0.2
    } catch (e) {
      console.log("error::", e)
    }

    const docNmWidth = 4.3
    const docNmCol = startX + logoWidth
    const codeCol = docNmCol + docNmWidth
    const codeWidth = 0.8
    const valueCol = codeCol + codeWidth
    const valueWidth = 1.9

    doc.setLineWidth(0.01)
    doc.lines([[logoWidth, 0], [0, logoHeight], [-logoWidth, 0], [0, -logoHeight]], startX, startY)
    doc.lines([[docNmWidth, 0], [0, lineHeight * 2], [-docNmWidth, 0]], docNmCol, startY)
    doc.lines([[codeWidth, 0], [0, lineHeight * 2], [-codeWidth, 0]], codeCol, startY)
    doc.lines([[valueWidth, 0], [0, lineHeight * 2], [-valueWidth, 0]], valueCol, startY)
    doc.line(codeCol, startY + lineHeight, codeCol + codeWidth + valueWidth, startY + lineHeight)
    doc.setFont("THSarabun", "bold")
    doc.setFontSize(20)
    yPos += 0.2
    doc.text(data.MtChkPlnNm, docNmCol + docNmWidth / 2, yPos + lineHeight / 2, { align: "center" })
    doc.setFont("THSarabun", "normal")
    doc.setFontSize(contentSize)
    doc.text("รหัสเอกสาร", codeCol + 0.1, yPos)
    doc.text(data.DocNo || "-", valueCol + valueWidth / 2, yPos, { align: "center" })
    yPos += lineHeight
    doc.text("แก้ไขครั้งที่", codeCol + 0.1, yPos)
    doc.text(`${data.Rev || "-"} : ${data.DocDte?dayjs(data.DocDte).format("DD/MM/YYYY"): "-"}`, valueCol + valueWidth / 2, yPos, { align: "center" })
    yPos += lineHeight * 3 / 2
    xPos = startX
    doc.text(`วันที่ตรวจ: ${dayjs(data.MtChkDte).format("DD/MM/YYYY")}`, xPos, yPos)
    if (numPage > 1) {
      doc.text(`(หน้า ${curPage}/${numPage})`, valueCol + valueWidth/2, yPos, { align: "center" })
    }
    yPos += lineHeight
    doc.text(`ทะเบียนรถ: ${data.LicNm} รุ่นรถ: ${data.TukModel || "-"}`, xPos, yPos)
    yPos += lineHeight
    doc.text(`พนักงานขับรถ: ${data.DrvFNm} ${data.DrvLNm}`, xPos, yPos)

    yPos += lineHeight * 3 / 2

    //------------------------------------------- table -------------------------------------------
    const catWidth = 2
    const itemWidth = 4.5
    const catCol = startX + 0.1
    const itemCol = catCol + catWidth
    const detailWidth = catWidth + itemWidth
    const resultCol = startX + detailWidth
    const resultColWidth = 1
    const passCol = resultCol + resultColWidth / 4
    const failCol = resultCol + resultColWidth * 3 / 4
    let yPosTable = yPos - lineHeight * 3 / 4

    //header
    doc.setFillColor("#cce6ff")
    doc.rect(startX, yPosTable, detailWidth + resultColWidth, lineHeight * 2, "F")
    doc.rect(startX, yPosTable, detailWidth, lineHeight * 2, )
    doc.line(startX, yPosTable + lineHeight, startX + detailWidth, yPosTable + lineHeight)
    doc.line(startX + catWidth, yPosTable + lineHeight, startX + catWidth, yPosTable + lineHeight * 2)
    doc.lines([[resultColWidth, 0], [0, lineHeight * 2], [-resultColWidth, 0]], resultCol, yPosTable)
    doc.line(resultCol, yPosTable + lineHeight, resultCol + resultColWidth, yPosTable + lineHeight)
    doc.line(resultCol + resultColWidth / 2, yPosTable + lineHeight, resultCol + resultColWidth / 2, yPosTable + lineHeight * 2)
    doc.text("รายการตรวจสภาพรถ", startX + detailWidth / 2, yPos, { align: "center" })
    doc.text("ผลการตรวจ", resultCol + resultColWidth / 2, yPos, { align: "center" })
    yPos += lineHeight
    doc.text("หัวข้อ", startX + catWidth / 2, yPos, { align: "center" })
    doc.text("รายการ", startX + catWidth + itemWidth / 2, yPos, { align: "center" })
    doc.text("ผ่าน", passCol, yPos, { align: "center" })
    doc.text("ไม่ผ่าน", failCol, yPos, { align: "center" })
    yPos += lineHeight
    yPosTable += lineHeight * 2

    //table content
    const starTableContent = yPosTable
    const startRow = (curPage - 1) * MAX_ROW
    const endRow = startRow + MAX_ROW > items.length ? items.length : startRow + MAX_ROW
    for (let i = startRow; i < endRow; i++) {
      yPosTable += lineHeight
      const item = items[i]
      if (lastCatId !== item.MtChkCatId || i === startRow) {
        if(i !== startRow || curPage ===1){
          catIndex++
          itemIndex = 0
        }
        const catName = `${catIndex}. ${item.MtChkCatNm}`
        const catNames = doc.splitTextToSize(catName, catWidth - 0.2)
        doc.text(catNames.join("\n"), catCol, yPos)
        lastCatId = item.MtChkCatId
        if (i !== startRow) {
          doc.line(catCol - 0.1, yPosTable - lineHeight, catCol - 0.1 + catWidth, yPosTable - lineHeight)
        }
      }
      itemIndex++
      doc.text(`${catIndex}.${itemIndex} ${item.MtChkItmNm}`, itemCol, yPos)
      doc.text(item.Chkd === 1 ? "/" : "", passCol, yPos, { align: "center" })
      doc.text(item.Chkd === 0 ? "X" : "", failCol, yPos, { align: "center" })
      doc.line(itemCol - 0.1, yPosTable, startX + detailWidth + resultColWidth, yPosTable)
      yPos += lineHeight
    }
    //last cat line
    doc.line(catCol - 0.1, yPosTable, catCol - 0.1 + catWidth, yPosTable)

    //vertical line
    doc.line(catCol - 0.1, starTableContent, catCol - 0.1, yPosTable)
    doc.line(itemCol - 0.1, starTableContent, itemCol - 0.1, yPosTable)
    doc.line(resultCol, starTableContent, resultCol, yPosTable)
    doc.line(resultCol + resultColWidth / 2, starTableContent, resultCol + resultColWidth / 2, yPosTable)
    doc.line(resultCol + resultColWidth, starTableContent, resultCol + resultColWidth, yPosTable)

    if (curPage === numPage) {
      yPos += lineHeight / 2
      const aprvNm = data.AprvFNm ? `${data.AprvFNm} ${data.AprvLNm}` : "-"
      const aprvTm = data.AprvFNm ? dayjs(data.ModDte).format("DD/MM/YYYY HH:mm") : "-"
      doc.text(`ผู้ตรวจสอบ: ${aprvNm}`, catCol, yPos)
      yPos += lineHeight
      doc.text(`เวลาตรวจสอบ: ${aprvTm}`, catCol, yPos)
    }
  }


  doc.output('dataurlnewwindow');
}