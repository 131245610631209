import React, { useCallback, useState, useContext } from "react";
import {  Dialog, DialogContent } from "@mui/material"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import { shipmentApiNm } from "constants/shipmentConstant";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { useEffect } from "react";
import PaperComponent from "components/PaperComponent";
import JobOrderData from "pages/Menu1/AddJobOrder/JobOrderData";
import { alertWarning } from "components/Alert";

const PrintJobOrderDialog = ({ dialogOpen, setDialogOpen, selectedId, onFinish}) => {

  const {ax, msData} = useContext(GlobalStateContext)
  const [shipmentData, setShipmentData] = useState(null)

  const handleClose = useCallback(()=>{
    setDialogOpen(false)
  }, [setDialogOpen])

  
  useEffect(()=>{
    if(dialogOpen){
      ax.post(shipmentApiNm.getShipment, {ShpmId: selectedId, a:"b"}).then(value=>{
        if(value.data){
          console.log("in printJobOrdDialog value.data::", value.data.length)
          if(value.data.length !== 1){
            alertWarning("ไม่พบข้อมูบใบแจ้งลาก")
            return
          }
          console.log("in printJobOrdDialog value.data::", value.data)

          setShipmentData(value.data[0])
        }
      })
    }
  }, [ax, dialogOpen, selectedId])
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth="sm" >
      <DialogHeader  handleDialogClose={handleClose}
        title={"พิมพ์ใบงาน"}
        onDeleteClick={null}
      >
      </DialogHeader>
      <DialogContent >
        <JobOrderData 
        ax={ax} msData={msData} 
        shipmentData={shipmentData} 
        onFinish={onFinish}
        />
      </DialogContent>
      <DialogFooter okText="" cancelText="ปิด"
          handleDialogClose={handleClose}
          handleDialogOk={null} />
    </Dialog>
  )
}

export default PrintJobOrderDialog