
import { Dialog, DialogContent } from "@mui/material";
import { Box } from "@mui/system";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import React from "react";
import { notClearColumns } from "./columns";

const DriverNotClearDialog = ({ dialogOpen, setDialogOpen, openExpenseDialog, dataTable}) => {


  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth="xl" 
      PaperProps={{
        style: {
          minHeight: '90%',
          maxHeight: '90%',
        }
      }}>
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="รายการพนักงานขับรถยังไม่ Clear เงิน"
        onDeleteClick={null}
      />
      <DialogContent sx={{display:"flex", backgroundColor: "#f6f6f6" }}>
        <Box width="100%"  flex={1} mt={2} >
          <DataGridCellExpand
            hideFooter
            rows={dataTable}
            columns={notClearColumns}
            disableSelectionOnClick
            onRowDoubleClick={(params, event, detail) => openExpenseDialog(params.id)} />
        </Box>
        
      </DialogContent>
      <DialogFooter okText={null}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(DriverNotClearDialog)