export const pettyCashApiNm = {
  getPettyCashTable: "/petty-cash/getPettyCashTable",
  getPettyCashDetail: "/petty-cash/getPettyCashDetail",
  getJobOrderLocation: "/petty-cash/getJobOrderLocation",
  getPettyCashItem: "/petty-cash/getPettyCashItem",
  getPCJournalAccount: "/petty-cash/getPCJournalAccount",
  getPCTransactionNotCmptByAccId: "/petty-cash/getPCTransactionNotCmptByAccId",
  getPCTransactionNoPrd: "/petty-cash/getPCTransactionNoPrd",
  getPettyCashPeriod: "/petty-cash/getPettyCashPeriod",
  getPCBalance: "/petty-cash/getPCBalance",
  getPettyCashHistory: "/petty-cash/getPettyCashHistory",
  insertPCTransaction: "/petty-cash/insertPCTransaction",
  insertReimbursment: "/petty-cash/insertReimbursment",
  updatePCTransaction: "/petty-cash/updatePCTransaction",
  updatePCTransactionCols: "/petty-cash/updatePCTransactionCols",
  insertPCTransactionBulk: "/petty-cash/insertPCTransactionBulk",
  updateIsCmpt: "/petty-cash/updateIsCmpt",
  deletePCTransaction: "/petty-cash/deletePCTransaction",
  insertPCItem: "/petty-cash/insertPCItem",
  updatePCItem: "/petty-cash/updatePCItem",
  updatePCItemCols: "/petty-cash/updatePCItemCols",
  deletePCItem: "/petty-cash/deletePCItem",
  insertPettyCashPeriodWithItems: "/petty-cash/insertPettyCashPeriodWithItems",
  updatePettyCashPeriodWithItems: "/petty-cash/updatePettyCashPeriodWithItems",
  updatePCProidCols: "/petty-cash/updatePCProidCols",
  deletePettyCashPeriod: "/petty-cash/deletePettyCashPeriod",
  insertPCJournalAccount: "/petty-cash/insertPCJournalAccount",
  updatePCJournalAccount: "/petty-cash/updatePCJournalAccount",
  deletePCJournalAccount: "/petty-cash/deletePCJournalAccount",
}

export const pettyCashColNm = { 
  AdmAccId: "ผู้ทำรายการ",
  AdmAccNm: "ผู้ทำรายการ",
  UsrAccId: "ผู้รับเงิน",
  UsrNNm: "ผู้รับเงิน",
  InOutTyp: "รับ/จ่าย",
  ExpId: "รายการ",
  ExpNm: "รายการ",
  PCTrnsTypId: "",
  PCJnlId: "สมุดบัญชี",
  PCJnlNm: "สมุดบัญชี",
  JobOrdId: "เลขที่ใบงาน",
  Loc: "สถานที่วิ่งงาน",
  LocText: "ข้อมูลใบงาน",
  PayTm: "เวลาทำรายการ",
  Dscp: "รายละเอียดเพิ่มเติม",
  Amnt: "จำนวนเงิน",
  VatPrct: "Vat(%)",
  WhtPrct: "หัก ณ ที่จ่าย(%)",
  PayAmnt: "ยอดจ่าย",
  PrPCTrnsId: "รายการหลัก",
  IsCmpt: "รายการเสร็จสิ้นสมบูรณ์",
  Rmk: "หมายเหตุ",
  DocNo: "เลขที่เอกสาร",
  DocDte: "วันที่เอกสาร",
  ItmDte: "วันที่รับเอกสาร",
  ReimDscp: "รายละเอียดเพิ่มเติม",
  ReimRmk: "หมายเหตุ",
}

export const pettyCashPeriodColNm = {
  PCPrdTm: "เวลาทำรายการ",
  PCPrdSt: "เวลาเริ่มรายการ",
  PCPrdEn: "เวลาสิ้นสุดรายการ",
  PCJnlId: "สมุดบัญชี",
  PCJnlNm: "สมุดบัญชี",
  InitAmnt: "ยอดเงินเริ่มต้น",
  TrnsAmnt: "ยอดเงินทำรายการ",
  TotalAmnt: "ยอดเงินคงเหลือ",
  FinAmnt: "ยอดเงินคงเหลือจริง",
  DiffAmnt: "ส่วนต่าง(คงเหลือ-เหลือจริง)",
  AdmAccId: "ผู้ทำรายการ",
  AdmAccNm: "ผุ้ทำรายการ",
  Rmk: "หมายเหตุ",
}