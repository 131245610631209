import { Box, Button, Dialog, DialogContent, Paper, Typography } from "@mui/material";
import { initPageData, jobOrderApiNm } from 'constants/jobOrderConstant';
import { blueGrey } from "@mui/material/colors";
import { BoxFC, BoxFR } from "components/BoxCustom";
import PaperComponent from "components/PaperComponent";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ContainerStatus from "./ContainerStatus";
import ContStatus from "./DailyJobDialogV2Component/ContStatus";
import Expense from "./DailyJobDialogV2Component/Expense";
import JobOrderDriver from "./DailyJobDialogV2Component/JobOrderDriver";
import JobOrderData from "./DailyJobDialogV2Component/JobOrderData";
import Shipment from "./DailyJobDialogV2Component/Shipment";
import Shore from "./DailyJobDialogV2Component/Shore";
import Memo from "./DailyJobDialogV2Component/Memo";
import dayjs from "dayjs";
import { printJobOrder } from "branch/functions/printJobOrder";
import { UserContext } from "contexts/UserContext";
import ButtonGroupPopper from "components/ButtonGroupPopper";
import { alertConfirmDelete } from "components/Alert";
import { checkContStatus } from "./DailyJobDialogV2Component/checkContStatus";

let prevContStsData = []
let oContStatusData = []
let oExpenseData = []
let oJOData = {}
let oTagData = []
let oMemoData = []
// let oMemoData = []

let loaded = false

const DailyJobDialogV2 = ({ selectedId, dialogOpen, setDialogOpen, lastFilter, onFinish }) => {

  const { ax, msData, setTargetTimestamp } = useContext(GlobalStateContext)
  const { user } = useContext(UserContext)

  const [contStatusData, setContStsData] = useState([])
  const [joData, setJOData] = useState({ ...initPageData })
  const [tagData, setTagData] = useState([])
  const [expenseData, setExpenseData] = useState([])
  const [memoData, setMemoData] = useState([])
  // const [loaded, setLoaded] = useState(false)

  const deleteJobOrder = useCallback((jobOrdId) => {
    // console.log("in deleteJobOrder lastFilter::", lastFilter)
    alertConfirmDelete(() => {
      ax.post(jobOrderApiNm.deleteJobOrder, { JobOrdId: jobOrdId }).then(value => {
        if (value.data) {
          // processConstStatus(value.data, msData)
          //setDataTable(addIdForDataGrid(value.data, "JobOrdId"));
          setDialogOpen(false);
          setTargetTimestamp(dayjs())
          onFinish()
        }
      })
    })
  }, [ax, setTargetTimestamp, onFinish, setDialogOpen])

  const handleDialogOK = useCallback(async () => {
    const expHasChange = JSON.stringify(oExpenseData) !== JSON.stringify(expenseData)
    const contStsHasChange = JSON.stringify(oContStatusData) !== JSON.stringify(contStatusData)
    const tagDataHasChange = JSON.stringify(oTagData) !== JSON.stringify(tagData)
    const joDataHasChange = JSON.stringify(oJOData) !== JSON.stringify(joData)
    const memoDataHasChange = JSON.stringify(oMemoData) !== JSON.stringify(memoData)
    // console.log("handleDialogOK::", joDataHasChange, contStsHasChange, expHasChange)

    const postData = {
      getArgs: lastFilter
    }

    if (expHasChange) {
      const argsArr = []
      for (const data of expenseData) {
        argsArr.push({
          DalyExpId: data.DalyExpId,
          ExpDte: data.ExpDte?.toString("YYYY-MM-DD HH:mm:ss") || null,
          ClrDte: (data.ClrDte && data.ClrDte.toString("YYYY-MM-DD HH:mm:ss")) || null,
          DrvId: data.DrvId,
          JobOrdId: +data.MoveJobOrdId || data.JobOrdId,
          Amnt: data.payType === "OUT" ? data.Amnt * -1 : data.Amnt,
          IsCash: data.IsCash,
          IsPaid: data.IsPaid,
          Dscp: data.Dscp,
          Rmk: data.Rmk
        })
      }
      // await ax.post(dailyExpApiNm.insertDailyExpenseBulk, { argsArr, getArgs: null })
      postData.dalyExpData = { argsArr }
    }

    if (contStsHasChange) {
      // await ax.post(jobOrderApiNm.insertUpdateJobOrderContainerStatus, contStatusData)
      postData.contStatusData = contStatusData
    }

    if (tagDataHasChange) {
      // await ax.post(jobOrderApiNm.updateJobOrderTag, { JobOrdIds: [selectedId], TagIds: tagData.map(tag => tag.id) })
      postData.tagData = { JobOrdIds: [selectedId], TagIds: tagData.map(tag => tag.id) }
    }

    const joColValue = {}
    if (joDataHasChange) {
      // joData.Memo = memoData && memoData.length > 0 ? JSON.stringify(memoData.map(memo => ({
      //   IsPin: memo.IsPin,
      //   Memo: memo.Memo,
      //   MemTm: dayjs(memo.MemTm).format("YYYY-MM-DD HH:mm:ss"),
      //   ModAccId: memo.ModAccId,
      // }))) : null
      // // const postData = { ...joData, getArgs: lastFilter }
      // // result = await ax.post(jobOrderApiNm.updateJobOrder, postData);
      // postData.joData = {
      //   ...joData,
      //   Memo: memoData && memoData.length > 0 ?
      //     JSON.stringify(memoData.map(memo => ({
      //       IsPin: memo.IsPin,
      //       Memo: memo.Memo,
      //       MemTm: dayjs(memo.MemTm).format("YYYY-MM-DD HH:mm:ss"),
      //       ModAccId: memo.ModAccId,
      //     })
      //     ))
      //     : null
      // }
      for (const key in joData) {
        if (oJOData[key] !== joData[key]) {
          joColValue[key] = joData[key]
        }
      }
      postData.jobOrdData = {
        JobOrdId: joData.JobOrdId,
        ColValues: joColValue,
        LogRmk: "แก้ไขข้อมูลใบงาน"
      }
    }
    if (memoDataHasChange) {
      const memoText = memoData && memoData.length > 0 ?
        JSON.stringify(memoData.map(memo => ({
          IsPin: memo.IsPin,
          Memo: memo.Memo,
          MemTm: dayjs(memo.MemTm).format("YYYY-MM-DD HH:mm:ss"),
          ModAccId: memo.ModAccId,
        })
        ))
        : null
      if (postData.jobOrdData) {

        postData.jobOrdData.ColValue = {
          ...postData.jobOrdData.ColValue,
          Memo: memoText
        }
      } else {
        postData.jobOrdData = {
          JobOrdId: joData.JobOrdId,
          ColValues: { Memo: memoText },
          LogRmk: "แก้ไข Memo"
        }
      }
    }
    // console.log(oJOData, joData)
    if (expHasChange || contStsHasChange || tagDataHasChange || joDataHasChange || memoDataHasChange) {
      ax.post(jobOrderApiNm.updateJobOrderAllDetail, postData).then(value => {
        if (value.data) {
          onFinish && onFinish(value.data)
        }
      })
    }
    setDialogOpen(false)
    // if (expHasChange || contStsHasChange || tagDataHasChange || joDataHasChange) {
    //   if (!result && lastFilter) result = await ax.post(jobOrderApiNm.getDailyJob, lastFilter)
    //   onFinish(result?.data)
    // }

  }, [joData, expenseData, contStatusData, tagData, ax, lastFilter, memoData, onFinish, selectedId, setDialogOpen])

  const handlePrintJobOrder = useCallback(() => {
    // console.log("joData::", joData)
    printJobOrder({
      printDataArr: [{
        jobOrderData: joData, shipmentData: joData.Shipment, jobOrdIds: [joData.JobOrdId],
        fName: user.FName
      }], msData
    })
  }, [joData, msData, user.FName])

  const handlePrintJobOrderWithBg = useCallback(() => {
    // console.log("joData::", joData)
    printJobOrder({
      printDataArr: [{
        jobOrderData: joData, shipmentData: joData.Shipment, jobOrdIds: [joData.JobOrdId],
        fName: user.FName
      }],
      msData,
      withBackground: true
    })
  }, [joData, msData, user.FName])

  useEffect(() => {
    // console.log("in DailyJobDialogV2 loaded::", loaded, joData.DrvId)
    if (loaded) {
      setExpenseData(o => {
        const nullDrvId = oExpenseData.filter(item => item.DrvId === null).map(item => item.DalyExpId)
        // console.log("nullDrvId::", nullDrvId)
        return o.map(item => {
          if (nullDrvId.includes(item.DalyExpId)) {
            return { ...item, DrvId: joData.DrvId }
          } else {
            return item
          }
        })
      })
    }
  }, [joData.DrvId])

  useEffect(() => {
    checkContStatus({ contStatusData, joData, prevContStsData })
  }, [contStatusData, joData])

  useEffect(() => {
    if (dialogOpen) {
      ax.post(jobOrderApiNm.getDailyJobAllDetail, { JobOrdId: selectedId }).then(value => {
        if (value.data) {
          const contStses = value.data.ContStses
          oContStatusData = msData.containerStatuses.filter(item => !item.IsHide).map(contStatus => {
            const foundData = contStses?.find(it => it.ContStsId === contStatus.ContStsId)
            return {
              ...contStatus,
              DrvId: foundData && foundData.DrvId,
              TukId: foundData && foundData.TukId,
              JobOrdId: selectedId,
              StsTm: foundData && foundData.StsTm,
              Rmk: foundData && foundData.Rmk,
              IsCmpt: foundData && foundData.IsCmpt
            }
          })
          delete value.data.ContStses

          oExpenseData = value.data.DailyExpenses.map(item => ({ ...item }))
          delete value.data.DailyExpenses

          oTagData = value.data.Tags.map(item => ({ id: item.TagId, label: item.Name }))

          !value.data.Memo && (value.data.Memo = [])
          oMemoData = value.data.Memo.map((item, index) => ({ ...item, id: index }))
          delete value.data.Memo
          oJOData = { ...value.data }
          oJOData.Shipment = {
            CusNm: oJOData.CusNm,
            JobNo: oJOData.JobNo,
            Bkg: oJOData.Bkg,
            Agnt: oJOData.Agnt,
            Port: oJOData.Port,
            Vssl: oJOData.Vssl,
            Voy: oJOData.Voy,
            ShpmTypId: oJOData.ShpmTypId,
            Job: {
              RcptNm: oJOData.RcptNm,
              RcptAddr: oJOData.RcptAddr,
              RcptTaxId: oJOData.RcptTaxId,
              RcptBrchNo: oJOData.RcptBrchNo,
              Name: oJOData.JobNm,
              CmdyTyp: oJOData.CmdyTyp
            }
          }
          oJOData.ShRtnPOIId = oJOData.Shore?.RtnPOIId
          oJOData.ShRtnPlc = oJOData.Shore?.RtnPlc || ""
          oJOData.ShVssl = oJOData.Shore?.Vssl || ""
          oJOData.ShVoy = oJOData.Shore?.Voy || ""
          setContStsData(oContStatusData.map(item => ({ ...item })))
          prevContStsData = oContStatusData.map(item => ({ ...item }))
          setExpenseData(oExpenseData.map(item => ({ ...item })))
          setJOData({ ...oJOData })
          setMemoData(oMemoData.map((item) => ({ ...item })))
          setTagData(oTagData.map(item => ({ ...item })))
          setTimeout(() => {
            loaded = true
          }, 500);
        }
      })
    } else {
      setTagData([])
      setJOData({ ...initPageData })
      setContStsData([])
      setExpenseData([])
      setMemoData([])
      loaded = false
      prevContStsData = []
      oContStatusData = []
    }
  }, [dialogOpen, selectedId, ax, msData.containerStatuses])

  // console.log("in render DailyJobDialogV2 loaded::", loaded)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth
      PaperProps={{
        style: {
          minWidth: 1850,
          maxWidth: 1850,
          minHeight: 930,
          maxHeight: 930,
        }
      }} >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={`ข้อมูลใบงานเลขที่ ${selectedId}`}
        onDeleteClick={() => deleteJobOrder(selectedId)}>
        <Box display="flex" gap={2} ml={2}>
          {/* <Box display="flex" alignItems="center">
              <Typography variant="h6" color="#000000"> แก้ไขใบงานเลขที่ {selectedId} </Typography>
            </Box> */}
          {!joData.PrId &&
            <Button variant="contained" color="secondary" onClick={() => window.open(`${window.location.origin}/emily/AddJobOrderChild/${selectedId}`, "_blank")}>เพิ่ม/พิมพ์ใบงานย่อย</Button>
          }

          <ButtonGroupPopper mainButton={
            <Button variant="contained" color="secondary"
              onClick={handlePrintJobOrder} >
              พิมพ์ซ้ำใบงาน
            </Button>
          }
            arrowButtonStyle={{ variant: "contained", color: "secondary" }}>
            <Paper >
              <Button variant="outlined" size="small" color="secondary" onClick={handlePrintJobOrderWithBg}>พิมพ์พร้อมพื้นหลัง</Button>
            </Paper>

          </ButtonGroupPopper>
        </Box>
      </DialogHeader>
      <DialogContent sx={{ bgcolor: blueGrey[50], p: 1 }}>
        <BoxFR height={750} pt={2} sx={{ alignItems: "stretch" }}>
          <BoxFC sx={{ gap: 1 }}>
            <Paper sx={{ pt: 1, pb: 1, width: "100%", height: 148 }}>
              <BoxFR ml={2}>
                <Typography variant="h6">สถานะตู้</Typography>
              </BoxFR>
              <ContStatus contStatusData={contStatusData} joData={joData} />
            </Paper>
            <Paper sx={{ pl: 2, pr: 0, py: 1, width: "100%" }}>
              {/* <Expense jobOrdId={selectedId} msData={msData} expenseData={expenseData} setExpenseData={setExpenseData} /> */}
              <BoxFC sx={{ height: 140, overflow: "auto", gap: 1 }}>
                <Typography variant="h6">เงินแนบใบงาน</Typography>
                <Expense expenseData={expenseData} setExpenseData={setExpenseData} ax={ax} msData={msData} user={user} />
              </BoxFC>
            </Paper>
            <Paper sx={{ px: 2, pt: 1, pb: 2, alignSelf: "stretch", flex: 1 }}>
              <BoxFR sx={{ alignItems: "flex-start" }} >
                <Box flex={1}>
                  <Typography variant="h6">พนักงานขับรถ</Typography>
                  <ContainerStatus
                    msData={msData}
                    options={msData.driverCombo}
                    contStsData={contStatusData}
                    setContStsData={setContStsData}
                    setJobOrderData={setJOData} />
                </Box>
                <BoxFC flex={1}>
                  <Typography variant="h6" >ผู้รับผิดชอบ</Typography>
                  <JobOrderDriver joData={joData} setJOData={setJOData} msData={msData} />
                </BoxFC>
              </BoxFR>
            </Paper>
          </BoxFC>
          <Paper sx={{ px: 2, width: 600, pt: 1, height: 775 }}>
            <JobOrderData joData={joData} setJOData={setJOData} msData={msData} tagData={tagData} setTagData={setTagData} />
          </Paper>
          <BoxFC flex={1} sx={{ gap: 1 }} height={775}>
            <Paper sx={{ px: 2, pt: 1, pb: 2, width: "100%", flex: 1 }}>
              <BoxFC sx={{ gap: 1 }} height="100%">
                <Memo memoData={memoData} setMemoData={setMemoData} dialogOpen={dialogOpen} msData={msData} />
              </BoxFC>
            </Paper>
            <Paper sx={{ px: 2, pt: 1, pb: 2, width: "100%" }}>
              <BoxFC sx={{ gap: 1 }}>
                <Typography variant="h6" sx={{ width: "100%" }}>ข้อมูลใบแจ้งลาก</Typography>
                <Shipment joData={joData} msData={msData} />
              </BoxFC>
            </Paper>
            <Paper sx={{ px: 2, pt: 1, pb: 2, width: "100%", }}>
              <BoxFC sx={{ gap: 1 }}>
                <Typography variant="h6" sx={{ width: "100%" }}>ข้อมูลชอร์</Typography>
                <Shore joData={joData} msData={msData} />
              </BoxFC>
            </Paper>
          </BoxFC>
        </BoxFR>
      </DialogContent>
      <DialogFooter okText="แก้ไขข้อมูล"
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOK} />
    </Dialog>
  );
}

export default DailyJobDialogV2;