

export const clearFilterData = {
  AptTmSt: null,
  AptTmEn: null,
  ScId: null,
  CusId: null, 
  JobId: null,
  Bkg: null, 
  IsFinDte: '',
  JobNo: null,
}

export const initFilterData = {
  ...clearFilterData,
  IsFinDte: 0
}