import { dailyExpenseColNm } from "constants/dailyExpenseConstant";
import dayjs from "dayjs";
import { numberFormat } from "utils";
import { getPOIPlace } from "utils/getPOIPlace";

export const columns = [
  {
    field: "No",
    headerName: "No.",
    width: 60,
    align: "center",
  },
  {
    field: "ExpDte",
    headerName: dailyExpenseColNm.ExpDte,
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params)=> params.value? params.value.format("DD/MM HH:mm"): "-",
  },
  {
    field: "JobOrdId",
    headerName: dailyExpenseColNm.JobOrdId,
    width: 100,
    align: "center"
  },
  {
    field: "DrvNNm",
    headerName: dailyExpenseColNm.DrvNNm,
    width: 120,
  },
  {
    field: "TakePlc",
    headerName: dailyExpenseColNm.TakePlc,
    valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
    width: 100,
  },
  {
    field: "Loc",
    headerName: dailyExpenseColNm.Loc,
    valueGetter: (params) => getPOIPlace(params.row, "LOC"),
    width: 180,
  },
  {
    field: "RtnPlc",
    headerName: dailyExpenseColNm.RtnPlc,
    valueGetter: (params) => getPOIPlace(params.row, "RTN"),
    width: 100,
  },
  {
    field: "ContSizeFull",
    headerName: dailyExpenseColNm.ContSizeFull,
    width: 100,
  },
  {
    field: "IsCash",
    headerName: dailyExpenseColNm.IsCash,
    width: 90,
    valueFormatter: (params)=> params.value===1? "เงินสด": "เงินโอน",
  },
  {
    field: "Amnt",
    headerName: dailyExpenseColNm.Amnt,
    width: 120,
    align: "right",
    valueFormatter: (params)=> params.value? numberFormat(parseFloat(params.value)): "0",
  },
  {
    field: "PayAccNNm",
    headerName: dailyExpenseColNm.PayAccNNm,
    width: 100,
  },
  {
    field: "ClrDte",
    headerName: dailyExpenseColNm.ClrDte,
    width: 150,
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params)=> params.value? params.value.format("DD/MM/YYYY HH:mm"): "",
  },
  {
    field: "ClrNNm",
    headerName: dailyExpenseColNm.ClrAccNNm,
    width: 120,
  },
  {
    field: "Dscp",
    headerName: dailyExpenseColNm.Dscp,
    width: 150,
  },
  {
    field: "Rmk",
    headerName: dailyExpenseColNm.Rmk,
    flex: 1
  },
]

export const notClearColumns = columns.filter(item=>!["ClrNNm", "ClrDte"].includes(item.field))