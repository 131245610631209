import { Dialog, DialogContent, Divider, Typography } from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import React from "react"

import Step1 from "resource/HowToPasteClipboard/step1.png"
import Step2 from "resource/HowToPasteClipboard/step2.png"
import Step3 from "resource/HowToPasteClipboard/step3.png"

const HowToClipBoardDialog = ({ dialogOpen, setDialogOpen }) => {

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="วิธีการวางข้อมูลจาก Clipboard"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} sx={{ gap: 1 }}>
          <Typography alignSelf="center" variant="h5">วิธีวางข้อมูลจาก Clipboard</Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>1. กดปุ่ม EXPORT &gt; Download as CSV เพื่อกำหนด column ให้ตรง </Typography>
          <img src={Step1} alt="Step1" style={{ width: "100%" }} />
          <Divider sx={{ alignSelf: "center", width: "70%" }} />
          <Typography variant="body1" sx={{ mt: 2 }}>2. ใส่ข้อมูลและ copy ข้อมูลใน Excel </Typography>
          <img src={Step2} alt="Step2" style={{ width: "100%" }} />
          <Divider sx={{ alignSelf: "center", width: "70%" }} />
          <Typography variant="body1" sx={{ mt: 2 }}>3. Click cell ที่ต้องการวางและกดปุ่ม "วางข้อมูลจาก CLIPBOARD"</Typography>
          <img src={Step3} alt="Step2" style={{ width: "100%" }} />
          <Divider sx={{ alignSelf: "center", width: "70%" }} />
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(HowToClipBoardDialog)