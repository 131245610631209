import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export const jobApiNm = {
  getJob: "/cus-job/getJob",
  getJobTable: "/cus-job/getTable",
  getJobDetail: "/cus-job/getDetail",
  insertJob: "/cus-job/insertJob",
  updateJob: "/cus-job/updateJob",
  deleteJob: "/cus-job/deleteJob",
  uploadJobFile: "/cus-job/uploadJobFile",
  updateJobFileName: "/cus-job/updateJobFileName",
  deleteJobFile: "/cus-job/deleteJobFile",
  updateJobTag: "/cus-job/updateJobTag",
  updateJobPOI: "/cus-job/updateJobPOI",
}

export const jobColNm =  {
  JobId: "รหัสงาน",
  Name: "ชื่องาน",
  SName: "ชื่อย่อ",
  Abbr: "รหัสงาน",
  Loc: "สถานที่",
  POI: "สถานที่",
  POIIds: "สถานที่",
  CmdyTyp: "ประเภทสินค้า",
  Dscp: "รายละเอียด",
  Addr: "ที่อยู่",
  Addr2: "ที่อยู่2",
  Addr3: "ที่อยู่3",
  Rmk: "หมายเหตุ",
  CusId: "รหัสลูกค้า",
  CusNm: "ชื่อลูกค้า",
  CusSNm: "ชื่อย่อลูกค้า",
  ModDte: "แก้ไขล่าสุด",
  ModAccId: "ผู้แก้ไขล่าสุด",
  RcptNm: "ชื่อใบเสร็จ",
  RcptAddr: "ที่อยู่ใบเสร็จ",
  RcptTaxId: "เลขประจำตัวผู้เสียภาษี",
  CtDscp: "รายละเอียดผู้ติดต่อ",
  CtMailCont: "Email ส่งเบอร์ตู้",
  CtMailInv: "Email ส่งเอกสารบัญชี",
  RcptPortCd: "รหัสแลกผ่านท่า"
}

export const columns = [
  
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "IsActv",
    headerName: "Active",
    width: 100,
    align: "center",
    renderCell: (params) => {
      return params.value===1?<CheckCircleIcon color="success"/>: <CancelIcon color="action"/>
    }
  },
  {
    field: "Name",
    headerName: jobColNm.Name,
    width: 300,
  },
  {
    field: "SName",
    headerName: jobColNm.SName,
    width: 200,
  },
  {
    field: "Abbr",
    headerName: jobColNm.Abbr,
    width: 80,
  },
  {
    field: "Loc",
    headerName: jobColNm.Loc,
    width: 200,
  },
  {
    field: "Dscp",
    headerName: jobColNm.Dscp,
    width: 100,
  },
  {
    field: "CusSNm",
    headerName: jobColNm.CusSNm,
    width: 100,
  },
  {
    field: "RcptNm",
    headerName: jobColNm.RcptNm,
    width: 100,
    // resizable: true
  },
  {
    field: "RcptAddr",
    headerName: jobColNm.RcptAddr,
    flex: 1,
    minWidth: 180
  },
  {
    field: "RcptPortCd",
    headerName: jobColNm.RcptPortCd,
    width: 120,
    // resizable: true
  },
  {
    field: "NumFile",
    headerName: "Files",
    width: 80,
    // resizable: true
  },
  {
    field: "Rmk",
    headerName: jobColNm.Rmk,
    flex: 1,
    minWidth: 120
  },
]

export const initDialogData = {
  JobId: 0,
  Name: "",
  SName: "",
  Abbr: "",
  Loc: "",
  CmdyTyp: "",
  Dscp: "",
  Addr: "",
  Rmk: "",
  CusId: null,
  CusNm: "",
  CusSNm: "",
  ModDte: null,
  ModAccId: "",
  ModAcc: null,
  RcptNm: "",
  RcptAddr: "",
  RcptTaxId: "",
  RcptBrchNo: 0,
  CtDscp: "",
  CtMailCont: "",
  CtMailInv: "", 
  IsActv: 1, 
  IsOnTm: 0,
  OnTmBuff: 0,
  TagIds: [],
  Lat: null,
  Lng: null, 
  POIId: null,
  IsGPSArvTm: 1,
  IsGPSLevTm: 1,
  IsHeadBrch: 1, 
  RcptPortCd: "",
}

export const initFilterData = {
  Name: null,
  SName: null,
  CusId: null,
  Loc: null
}

export const initComboData = {
  cusFilterValue: null, 
  cusFilterText: "",
  cusDialogValue: null,
  cusDialogText: "",
}

export const act = {
  ON_CHANGE_FILTER: "ON_CHANGE_FILTER",
  ON_CHANGE_FILTER_COMBO: "ON_CHANGE_COMBO",
  ON_CHANGE_DIALOG_DATA: "ON_CHANGE_DIALOG_DATA",
  ON_CHANGE_DIALOG_COMBO: "ON_CHANGE_DIALOG_COMBO",
  CLEAR_FILTER: "FILTER_CLEAR",
  SET_DATA_TABALE: "SET_DATA_TABALE",
  SET_DIALOG_OPEN: "SET_DIALOG_OPEN",
  SET_DIALOG_DATA: "SET_DIALOG_DATA"
}