
import React, { useContext, useMemo } from "react";
import { Avatar, SvgIcon, Toolbar, Typography } from "@mui/material";
import { BoxFR } from "components/BoxCustom";
import { UserContext } from "contexts/UserContext";
import { ReactComponent as EmilyLogo } from "resource/emily_no_fill.svg";
import ProfileButton from "components/MainLayout/ProfileButton";
import { NavLink } from "react-router-dom";
import { extraMenuData } from "branch/pages/Subcontract/extraMenuData";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ArrowDropDown } from "@mui/icons-material";
import SubMenuPopover from "./SubmenuPopover";

let selectedSubMenu = []
const DesktopToolbar = ({ menuData }) => {
  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { pathname } = useLocation();

  const pms = useMemo(() => {
    return (user?.Pms?.map(pm => pm.Code)) || []
  }, [user])

  const handleMenuOpen = (subMenu) => (event) => {
    selectedSubMenu = subMenu
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar variant="dense">
      <Avatar alt="emily" sx={{ bgcolor: "white", width: 50, height: 42 }}>
        <SvgIcon sx={{ fontSize: 40, color: "primary.dark" }}>
          <EmilyLogo />
        </SvgIcon>
      </Avatar>
      <BoxFR flex={1} sx={{ alignItems: "stretch", height: "100%", ml: 1 }}>
        {[...menuData, ...extraMenuData]
          .filter(item => !item.pms || item.pms?.some(pm => pms.includes(pm)))
          .map((item, index) => (
            <BoxFR key={index}
              component={NavLink}
              to={item.subMenu ? "#" : item.path}
              onClick={item.subMenu ? handleMenuOpen(item.subMenu) : null}
              sx={{ px: 2, verticalAlign: "middle", gap: 0, borderBottom: "4px solid transparent" }}
              activeStyle={{ borderBottom: "4px solid white" }}
              isActive={()=>pathname.startsWith(item.path)}
            >
              <BoxFR >
                <Typography color="white" variant="h6">{item.label}</Typography>
                {item.subMenu ? <ArrowDropDown sx={{ color: "white" }} /> : null}

              </BoxFR>
            </BoxFR>
          ))}
      </BoxFR>
      <SubMenuPopover
        anchorEl={anchorEl}
        subMenu={selectedSubMenu}
        handleMenuClose={handleMenuClose}
        pms={pms} />
      <ProfileButton />
    </Toolbar>
  );
};
export default DesktopToolbar;