import { RemoveCircleRounded, SystemUpdateAltRounded } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";
import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback } from "react";
import isFileOverSize from "utils/isFileOverSize";

const FileDropPanel = ({ selectedFiles, setSelectedFiles, onChange, width, height, sx }) => {

  const handleSelectFile = useCallback((e) => {
    const files = Array.from(e.target.files)
    console.log({ files })
    for (const file of files) {
      if (isFileOverSize(file)) return
      // const fileNameOnly = file.name.substring(0, file.name.lastIndexOf('.')) || file.name
      file.label = file.name
      file.imgLink= URL.createObjectURL(file)
    }
    if(onChange){
      onChange(files)
    }
    setSelectedFiles(o=>[...o, ...files])
  }, [setSelectedFiles, onChange])

  const handleRemoveFile = useCallback((index)=>() => {
    setSelectedFiles(o=>{
      const newFiles = [...o]
      newFiles.splice(index,1)
      return newFiles
    })
  }, [setSelectedFiles])
  const handleDragOver = useCallback((e) => {
    /** Must prevent the can use onDrop */
    e.preventDefault()
  }, [])
  const handleDropFile = useCallback((e) => {
    
    e.preventDefault()
    let files = []
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items.length > 0 && e.dataTransfer.items[0].kind === 'file') {
        files = Array.from(e.dataTransfer.items).map(item => item.getAsFile())
      } else {
        return
      }
    } else {
      if (e.dataTransfer.files.length > 0) {
        files = Array.from(e.dataTransfer.files)
      } else {
        return
      }
    }

    for (const file of files) {
      if (isFileOverSize(file)) return
      
      // const fileNameOnly = file.name.substring(0, file.name.lastIndexOf('.')) || file.name
      file.label = file.name
      file.imgLink= URL.createObjectURL(file)
    }
    
    if(onChange){
      onChange(files)
    }
    setSelectedFiles(o=>[...o, ...files])
  }, [setSelectedFiles, onChange])

  return (
    <BoxFC width={width || "100%"} height={height || "100%"} sx={{ gap: 1, ...sx }}  >
      {/* <TextField size="small" label="Packing List file" required
        error={selectedFiles.length === 0}
        onDrop={handleDropFile}
        value={selectedFiles.map(file => file.name).join(", ")} /> */}
      <Button variant="contained" component="label" >
        เลือกไฟล์
        <input type="file" hidden onChange={handleSelectFile} />
      </Button>
      <BoxFC flex={1} sx={{ gap: 1 }} bgcolor={blueGrey[50]} p={1} borderRadius={1} 
        overflow="auto" onDrop={handleDropFile} onDragOver={handleDragOver}>
        {selectedFiles.length> 0? selectedFiles.map((file, index) => (
          <BoxFR key={index} >
            <IconButton sx={{ p: 0 }} onClick={handleRemoveFile(index)}>
              <RemoveCircleRounded />
            </IconButton>
            <Typography noWrap component="span" variant="caption" >{index + 1}. {file.label}</Typography>
          </BoxFR>
        ))
        :
        <BoxFC sx={{gap:0}} justifyContent="center" alignItems="center" width="100%" height="100%">
          <SystemUpdateAltRounded sx={{fontSize:50, color: grey[500]}}/>
          <Typography variant="h6" sx={{color:grey[500]}}>Drop file here</Typography>
        </BoxFC>
        }

      </BoxFC>
    </BoxFC>
  )
}

export default FileDropPanel
