import { HideImageRounded, PhotoRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";
import { BoxFC, BoxFR } from "components/BoxCustom";
import FullScreenImage from "pages/FullScreenImage/FullScreenImage";
import React, { useCallback, useState } from "react";

const ImagePreview = ({ title, imageItems, sx, thumbnailWidth = 450, imgWidth = 200 }) => {

  const [curImage, setCurImage] = useState("")

  const handleImageClick = useCallback((img) => {
    setCurImage(img.imgLink)
  }, [])
  return (
    <BoxFR sx={{ width: "100%", height: "100%", alignItems: "stretch", ...sx }}>
      <BoxFC sx={{ gap: 0 }} width={thumbnailWidth}>
        {title === "" ? null :

          <BoxFR sx={{ gap: 0 }}>
            <Typography fontWeight="bold">{title}</Typography>

          </BoxFR>
        }
        <Box p={1} overflow="auto"
          bgcolor={blueGrey[50]}
          height= {title==="" ?"100%": "calc(100% - 24px)"}
        >
          {imageItems.length === 0 ?
            <BoxFC sx={{ gap: 0 }} justifyContent="center" alignItems="center" width="100%" height="100%" >
              <HideImageRounded sx={{ fontSize: 50, color: grey[500] }} />
              <Typography variant="h6" sx={{ color: grey[500] }}>ไม่พบรูปภาพ</Typography>
            </BoxFC>
            :
            <BoxFR flexWrap="wrap" height="100%" sx={{ alignItems: "flex-start" }}>
              {imageItems.map((item, index) => (
                <Box key={index} sx={{ p: 0.5, bgcolor: "white", borderRadius: 1, width: imgWidth, position: "relative" }}
                  onClick={() => { handleImageClick(item) }}>
                  <img src={item.imgLink} width={"100%"} height={"100%"} alt={item.label} style={{ objectFit: "contain" }} />

                </Box>
              ))}
            </BoxFR>
          }
        </Box>
      </BoxFC>
      <BoxFC flex={1} bgcolor={blueGrey[50]} borderRadius={1} height="100%"
        sx={{ justifyContent: "center", alignItems: "center" }}>
        {curImage ?
          <FullScreenImage linkUrl={curImage} />
          :
          <BoxFC sx={{ gap: 0 }} justifyContent="center" alignItems="center" width="100%" height="100%">
            <PhotoRounded sx={{ fontSize: 50, color: grey[500] }} />
            <Typography variant="h6" sx={{ color: grey[500] }}>กรุณาเลือกรูป</Typography>
          </BoxFC>
        }

      </BoxFC>
    </BoxFR>
  )

}

export default ImagePreview;