import { Box, Dialog, DialogContent, Divider, List, ListItem, ListItemButton, ListItemText, Paper } from "@mui/material";
import { BoxFR } from "components/BoxCustom";
import { UserContext } from "contexts/UserContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { navMenuItemsHeader } from "components/MainLayout/constant"
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { lightBlue } from "@mui/material/colors";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import { accountApiNm, defaultUserPref, topicData } from "constants/accountConstant";
import { alertSuccess } from "components/Alert";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import defaultDailyTableColWidth from "branch/constant/defaultDailyTableColWidth";
import { addIdForDataGrid, sortObj } from "utils";
import DefaultOpenMenuContent from "./DefaultOpenMenuContent";
import DailyTableColWidthContent from "./DailyTableColWidthContent"
import EmailDataContent from "./EmailDataContent"
import TargetDataContent from "./TargetDataContent";
import { initRefColumnName } from "pages/Menu6/ServerDataManagement/constant";
import { useMemo } from "react";
import NotificationContent from "./NotificationContent";
import { columns } from "pages/Menu1/DailyJob/columns"
import GeneralContent from "./GeneralContent";
const userPrefMenus = [
  "เปิดเมนูเริ่มต้น", "ตารางงานประจำวัน", "ข้อมูลการส่ง E-mail", "เป้าหมายวันนี้", "การรับการแจ้งเตือน", "ตั้งค่าทั่วไป"
]

let oldNotificationData = null;

const UserPrefDialog = ({ dialogOpen, setDialogOpen }) => {
  const { user, setUser } = useContext(UserContext)
  const { ax, msData } = useContext(GlobalStateContext)
  const [selectedPref, setSelectedPref] = useState(userPrefMenus[0])

  const [defaultOpenMenu, setDefaultOpenMenu] = useState(defaultUserPref.DefaultOpenMenu)
  const [dailyTableColWidth, setDailyTableColWidth] = useState(defaultUserPref.DailyTableColWidth)
  const [emailData, setEmailData] = useState(defaultUserPref.EmailData)
  const [targetData, setTargetData] = useState(defaultUserPref.TargetData)
  const [notificationData, setNotificationData] = useState(defaultUserPref.NotificationData)
  const [generalData, setGeneralData] = useState(defaultUserPref.GeneralData)

  // console.log("dailyTableColWidht::", sortObj(dailyTableColWidth, "order"))
  //console.log("dailyTableColWidht::", dailyTableColWidth)

  const generateContent = useMemo(() => {
    if (selectedPref === userPrefMenus[0]) {
      return <DefaultOpenMenuContent defaultOpenMenu={defaultOpenMenu} setDefaultOpenMenu={setDefaultOpenMenu} pms={user.Pms} />
    } else if (selectedPref === userPrefMenus[1]) {
      return <DailyTableColWidthContent dailyTableColWidth={dailyTableColWidth} setDailyTableColWidth={setDailyTableColWidth} />
    } else if (selectedPref === userPrefMenus[2]) {
      return <EmailDataContent emailData={emailData} setEmailData={setEmailData} />
    } else if (selectedPref === userPrefMenus[3]) {
      return <TargetDataContent targetData={targetData} setTargetData={setTargetData} />
    } else if (selectedPref === userPrefMenus[4]) {
      return <NotificationContent notificationData={notificationData} setNotificationData={setNotificationData} />
    } else if (selectedPref === userPrefMenus[5]) {
      return <GeneralContent generalData={generalData} setGeneralData={setGeneralData} />
    } else {
      return null
    }
  }, [selectedPref, user.Pms, dailyTableColWidth, defaultOpenMenu, emailData, targetData, notificationData, generalData])

  const handleMenuClick = useCallback((item) => {
    setSelectedPref(item)
  }, [])

  const updateUserPref = useCallback(async () => {
    /**
     * Have to settime out coz wait for finish edit DailyTableColWidthContent
     */
    setTimeout( async() => {
      if (JSON.stringify(notificationData) !== JSON.stringify(oldNotificationData)) {
        console.log(notificationData, oldNotificationData);
        for (const key in notificationData) {
          if (notificationData[key] !== oldNotificationData[key]) {
            await ax.post(accountApiNm.subscribeTopicFCM, { Topic: topicData[key], IsSubc: notificationData[key] })
          }
        }
      }
      const postData = {
        DefaultOpenMenu: defaultOpenMenu,
        DailyTableColWidth: dailyTableColWidth.map(item => ({
          field: item.field,
          width: item.width,
          visible: item.visible,
          colClr: item.colClr,
          order: item.order
        })),
        EmailData: emailData,
        TargetData: targetData,
        NotificationData: notificationData,
        GeneralData: generalData
      }
      const postDataStr = JSON.stringify(postData)
      ax.post(accountApiNm.updateUserPref, { AccId: user.AccId, UsrPref: postDataStr }).then(value => {
        if (value.data) {
          alertSuccess("บันทึกการตั้งค่าเรียบร้อยแล้ว")
          setUser(o => {
            const newUserData = { ...o, UsrPref: postDataStr }
            localStorage.setItem("userData", JSON.stringify(newUserData))
            return newUserData
          })

          setDialogOpen(false)
        }
      })
    }, 300)

  }, [ax, defaultOpenMenu, setDialogOpen, user.AccId, setUser, dailyTableColWidth, emailData, targetData
    , notificationData, generalData])

  const handleClose = useCallback(() => {
    setDialogOpen(false)
  }, [setDialogOpen])

  useEffect(() => {
    if (dialogOpen) {
      const userPref = (user.UsrPref && JSON.parse(user.UsrPref)) || defaultUserPref
      console.log("userPref::", userPref)

      const refColumnName = msData.serverData.RefColumnName || initRefColumnName

      const dailyTableColWidhtWihtRef = defaultDailyTableColWidth.map(item => {
        if (["JoRef1", "JoRef2", "ShRef1", "ShRef2"].includes(item.field)) {
          let colName = "";
          if (item.field.startsWith("Jo")) {
            colName = refColumnName.job_order[item.field.replace("Jo", "")] || "อ้างอิง"
          } else {
            colName = refColumnName.shipment[item.field.replace("Sh", "")] || "อ้างอิง"
          }
          return {
            ...item,
            headerName: colName
          }
        } else {
          return { ...item }
        }
      })
      const joColumns = columns()
      /**
       * have to do two loop coz dont want to change array length
       */
      const notFoundCols = []
      for (const joCol of joColumns) {
        const found = dailyTableColWidhtWihtRef.find(col => joCol.field === col.field)

        if (!found) notFoundCols.push(joCol)
      }
      for (const col of notFoundCols) {
        dailyTableColWidhtWihtRef.push({
          field: col.field,
          headerName: col.headerName,
          width: 120,
          visible: false,
          order: 1000,
          colClr: null,
        })
      }
      setDailyTableColWidth(
        sortObj(dailyTableColWidhtWihtRef.map(item => ({
          id: item.field,
          ...item, ...userPref.DailyTableColWidth.find(col => col.field === item.field)
        })), "order")
      )
      setDefaultOpenMenu(
        // change from array to obj
        navMenuItemsHeader.reduce((obj, item) => {
          return ({ ...obj, [item.num]: userPref && userPref.DefaultOpenMenu && userPref.DefaultOpenMenu[item.num] ? true : false })
        }, {})
      )
      setEmailData({ ...defaultUserPref.EmailData, ...userPref.EmailData })
      setTargetData({ ...defaultUserPref.TargetData, ...userPref.TargetData })
      setNotificationData({ ...defaultUserPref.NotificationData, ...userPref.NotificationData })
      setGeneralData({ ...defaultUserPref.GeneralData, ...userPref.GeneralData })
      oldNotificationData = { ...userPref.NotificationData }
    } else {
      setDailyTableColWidth(addIdForDataGrid(defaultUserPref.DailyTableColWidth, "field"))
      setDefaultOpenMenu(defaultUserPref.DefaultOpenMenu)
      setEmailData(defaultUserPref.EmailData)
      setTargetData(defaultUserPref.TargetData)
      setNotificationData({ ...defaultUserPref.NotificationData })
      setGeneralData({...defaultUserPref.GeneralData})
    }
  }, [user.UsrPref, dialogOpen, msData.serverData.RefColumnName])

  console.log("notificationData::", notificationData)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg' >
      <DialogHeader handleDialogClose={handleClose}
        title="ตั้งค่าส่วนตัว"
        onDeleteClick={null}
      />
      <DialogContent sx={{ backgroundColor: "#f9f9f9" }}>
        <BoxFR p={1} sx={{ alignItems: "stretch", height: "100%" }}>
          <Paper sx={{ width: 400, height: 600 }}>
            <List>
              {
                userPrefMenus.map(item => (
                  <Box key={item}>
                    <ListItem disablePadding sx={{ backgroundColor: selectedPref === item ? lightBlue[100] : "transparent" }}>
                      <ListItemButton onClick={() => handleMenuClick(item)}>
                        <ListItemText primary={item} />
                        <ArrowForwardIosRoundedIcon />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </Box >
                ))
              }

            </List>
          </Paper>
          <Paper sx={{ p: 2, flex: 1, height: 600 }}>
            {generateContent}
          </Paper>
        </BoxFR>
      </DialogContent>
      <DialogFooter okText="บันทึกข้อมูล"
        handleDialogClose={handleClose}
        handleDialogOk={updateUserPref} />
    </Dialog>
  )
}

export default React.memo(UserPrefDialog)