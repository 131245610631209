import React from 'react';
import { Card, CardMedia, CardActions, Button, Box } from '@mui/material';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { ImageRounded } from '@mui/icons-material';
import { blueGrey, grey } from '@mui/material/colors';

const CardImage = ({ width, height, imgLink, name, onImageChange, onDelete, defaultImageComponent }) => (
  <Card sx={{ width: width }}>
    {
      imgLink ?
        <CardMedia
          component='img'
          image={imgLink}
          alt={name}
          sx={{ width: width, height: height, bgcolor: blueGrey[50]}}
          onClick={e=>{console.log('CardMedia click imgLink', imgLink);window.open(imgLink);}}
        /> :
        <Box width={width} height={height} bgcolor={blueGrey[50]}>
          {defaultImageComponent || <ImageRounded sx={{color: grey[500], width: "100%", height: "100%" }}/>}
        </Box>
        // <FontAwesomeIcon
        //   icon={faAddressCard}
        //   style={{ width: width, height: height, color: '#ccc' }} />
    }
    <CardActions sx={{ py: 0, justifyContent: 'space-between' }}>
      <Button size='small' component='label'>
        {`แก้ไข${name}`}
        <input type='file' onChange={onImageChange} hidden />
      </Button>
      <Button size='small' color='error' onClick={onDelete} sx={{ minWidth: 20 }}>
        <DeleteForeverRoundedIcon />
      </Button>
    </CardActions>
  </Card>
)

export default CardImage