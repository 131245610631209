import { CheckRounded, LocalShippingRounded } from "@mui/icons-material";
import { Step, StepConnector, StepLabel, Stepper, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { BoxFC, BoxFR } from "components/BoxCustom";

import React, { useCallback } from "react";
import { getPOIPlace } from "utils/getPOIPlace";

const ContStatus = ({ contStatusData, joData }) => {

  const calculateStepContent = useCallback((contStsId, joData) => {
    if (contStsId === 1) {
      return getPOIPlace(joData, "TAKE")
    } else if (contStsId === 3) {
      return getPOIPlace(joData, "LOC")
    } else if (contStsId === 5) {
      return getPOIPlace(joData, "RTN")
    }
  }, [])

  return (
    <Stepper alternativeLabel
      // connector={<Box sx={{
      //   ml: 1.5,
      //   borderLeft: 1,
      //   borderColor: grey[500],
      //   height: 36
      // }} />}
      connector={<StepConnector sx={{ mt: 1.5 }} />}
      sx={{ width: "100%" }}
    >
      {
        contStatusData.map((contStatus, index) => {
          // console.log("contStatus::", contStatus)
          return (
            <Step key={contStatus.ContStsId} expanded

              completed={contStatus.IsCmpt === 1}
              active={contStatus.DrvId > 0}>
              <StepLabel sx={{ "& .MuiStepLabel-alternativeLabel": { marginTop: 0 } }}
                StepIconComponent={({ active, completed, className }) => {
                  return completed ?
                    <BoxFR borderRadius="50%" width={40} height={40}
                      sx={{
                        justifyContent: "center",
                        bgcolor: contStatus.DrvId ? `${contStatus.BgClr}.main` : grey[500]
                      }}
                    >
                      <CheckRounded sx={{ color: "white" }} />
                    </BoxFR>
                    :
                    <BoxFR borderRadius="50%" width={40} height={40}
                      sx={{
                        justifyContent: "center",
                        bgcolor: contStatus.DrvId ? `${contStatus.BgClr}.main` : grey[500]
                      }}
                    >
                      <LocalShippingRounded sx={{ color: "white" }} />
                    </BoxFR>
                }}
              >
                <BoxFC sx={{ gap: 0, alignItems: "center" }}>
                  <Typography variant="body" fontWeight="bold">{contStatus.Name}</Typography>
                  <Typography variant="body">{calculateStepContent(contStatus.ContStsId, joData)}</Typography>
                </BoxFC>
              </StepLabel>
              {/* <StepContent sx={{ margin: 0, padding: 0, border: 0 }}>
                          <BoxFR justifyContent="center">
                            
                          </BoxFR>
                        </StepContent> */}
            </Step>
          )
        })
      }
    </Stepper>
  )
}

export default React.memo(ContStatus)