
import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import { jobOrderColNm } from "constants/jobOrderConstant";

import { modifyPOICombo } from "branch/functions/modifyPOICombo";
import TagBox from "components/TagBox";
import PlanColorBox from "components/PlanColorBox";
import { PLAN_COLORS, SHIPMENT_TYPE } from "constants/serverConstant";
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime";
import React, { useCallback, useMemo, useState } from "react";
import { containerNoCheck, getISOCd, generateMenuComboItem } from "utils";
import {
  BookmarkAdded, BookmarkBorder, CreditCard, CreditScore,
  Description, DescriptionOutlined, DirectionsBoat, DirectionsBoatFilledOutlined,
  MailOutline, MarkEmailRead
} from "@mui/icons-material";

const iconProps = {
  color: "primary",
  sx: { width: 30, height: 30 }
}

const shortDateProp = {
  inputFormat: "DD/MM HH:mm",
  mask: "__/__ __:__"
}

const JobOrderData = ({ joData, setJOData, tagData, setTagData, msData }) => {

  const [isToUpper, setIsToUpper] = useState(true)

  const validation = useMemo(()=>({
    AptTm: joData.AptTm === null
  }), [joData])

  const refJobOrderColNm = useMemo(() => {
    const result = { ...jobOrderColNm }
    const refColumnName = msData.serverData.RefColumnName?.job_order || {}
    for (const name in refColumnName) {
      result[name] = refColumnName[name]
    }
    return result
  }, [msData.serverData])


  const dataProp = useCallback((name) => ({
    variant: "outlined",
    size: "small",
    name: name,
    label: refJobOrderColNm[name],
    fullWidth: true,
    value: joData[name],
    onChange: (e) => {
      if (name === 'ContSize' || name === 'ContTyp' || name === 'ContHq') {
        let isoCd = "";
        if (name === 'ContSize') isoCd = getISOCd(e.target.value, joData.ContTyp, joData.ContHq);
        else if (name === 'ContTyp') isoCd = getISOCd(joData.ContSize, e.target.value, joData.ContHq);
        else if (name === 'ContHq') isoCd = getISOCd(joData.ContSize, joData.ContTyp, e.target.value);

        setJOData((oldData) => ({
          ...oldData,
          [name]: e.target.value,
          ISOCd: isoCd
        }))
      } else {
        setJOData((oldData) => ({ ...oldData, [name]: e.target.value }))
      }
    },
    onBlur: (e) => {

      const value = isToUpper ? e.target.value.toUpperCase() : e.target.value
      setJOData((oldData) => ({ ...oldData, [name]: value }))
      if (name === "ContNo") {
        containerNoCheck(value);
      }
    }
  }), [refJobOrderColNm, joData, isToUpper, setJOData])

  const datePickerJobOrderProp = useCallback((name, isRequire) => ({
    label: jobOrderColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: joData[name],
    onChange: (newValue) => { setJOData(oldState => ({ ...oldState, [name]: newValue && newValue.format("YYYY-MM-DD HH:mm:00.00") })) },
    renderInput: (params) => <TextField size="small"  {...params} required={isRequire} error={validation[name]} fullWidth />,
  }), [joData, setJOData, validation])

  const calculateExportIcon = useMemo(() => {
    return (
      <Box display="flex" flexDirection="row" gap={2} alignItems="center" alignSelf="flex-end">
        <Tooltip title={`${joData.IsRdyTake ? "ตู้พร้อมรับ" : "รอเช็คบุคกิ้ง"}`}>
          {joData.IsRdyTake === 0 ? <BookmarkBorder {...iconProps} color="disabled" /> : <BookmarkAdded {...iconProps} />}
        </Tooltip>
        <Tooltip title={`${joData.IsSndMail ? "ส่ง Email แล้ว" : "รอส่ง Email"}`} >
          {joData.IsSndMail === 0 ? <MailOutline  {...iconProps} color="disabled" /> : <MarkEmailRead  {...iconProps} />}
        </Tooltip>
        <Tooltip title={`${joData.ContDoc ? "มีใบกำกับตู้แล้ว" : "รอใบกำกับตู้"}`}>
          {joData.ContDoc ? <Description  {...iconProps} /> : <DescriptionOutlined {...iconProps} color="disabled" />}
        </Tooltip>
        <Tooltip title={`${joData.ShorId ? "จ่ายชอร์แล้ว" : "รอจ่ายชอร์"}`}>
          {joData.ShorId ? <DirectionsBoat  {...iconProps} /> : <DirectionsBoatFilledOutlined {...iconProps} color="disabled" />}
        </Tooltip>

      </Box>
    )
  }, [joData])

  const calculateImportIcon = useMemo(() => {
    return (
      <Box display="flex" flexDirection="row" gap={2} alignItems="center">
        <Tooltip title="ตู้พร้อมรับ">
          {joData.IsRdyTake === 0 ? <CreditCard {...iconProps} /> : <CreditScore {...iconProps} />}
        </Tooltip>
      </Box>
    )
  }, [joData])

  return (
    <BoxFC sx={{ gap: 1, height: "100%", overflow: "auto" }}>
      <BoxFR>
        <Typography variant="h6" sx={{ width: "100%" }}>ข้อมูลใบงาน</Typography>
        {
          joData.ShpmTypId === SHIPMENT_TYPE.EXPORT ?
            calculateExportIcon : calculateImportIcon
        }
      </BoxFR>
      <FormControlLabel sx={{ m: 0 }}
        label="ใช้อักษรพิมพ์ใหญ่(ภาษาอังกฤษ)"
        control={<Checkbox sx={{ p: 0 }} checked={isToUpper} onChange={(e) => setIsToUpper(e.target.checked)} />}
      />
      <Box display="flex" gap={1} justifyContent="flex-start" alignItems="center">
        <FormControl sx={{ width: 75, minWidth: 75 }} size="small">
          <InputLabel id="plnClrLabel">{jobOrderColNm.PlnClr}</InputLabel>
          <Select label={jobOrderColNm.PlnClr} labelId="plnClrLabel"
            value={joData.PlnClr}
            onChange={(e) => setJOData(oldData => ({ ...oldData, PlnClr: e.target.value }))}>
            {PLAN_COLORS.map(item => (
              <MenuItem key={item} value={item}>
                <PlanColorBox color={item} /></MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField {...dataProp("PlnTxt")} fullWidth />
      </Box>
      <Box display="flex" flexDirection="row" gap={1}>
        <TextField {...dataProp("ContNo")} />
        <TextField {...dataProp("SealNo")} />
      </Box>
      <Box display="flex" flexDirection="row" gap={1}>
        <TextField select {...dataProp("ContSize")} required >{generateMenuComboItem(msData.contSizeCombo)}</TextField>
        <TextField select {...dataProp("ContTyp")} required >{generateMenuComboItem(msData.contTypCombo)}</TextField>
        <TextField select {...dataProp("ContHq")} required >
          <MenuItem value={0}>Std</MenuItem>
          <MenuItem value={1}>HQ</MenuItem>
        </TextField>
        <TextField select {...dataProp("TlrTypId")} required >{generateMenuComboItem(msData.tlrTypCombo)}</TextField>
      </Box>
      <Box display="flex" flexDirection="row" gap={1}>
        <TextField {...dataProp("TareWt")} />
        <TextField {...dataProp("FullWt")} />
        <TextField {...dataProp("ISOCd")} />
      </Box>
      <Box display="flex" flexDirection="row" gap={1}>
        <ComboBox options={modifyPOICombo(msData.poiCombo, "TAKE", joData.Shipment?.ShpmTypId)}
          sx={{ width: "100%" }}
          label={jobOrderColNm.TakePlc}
          selectedId={joData.TakePOIId}
          setSelectedId={(id) => setJOData(o => ({ ...o, TakePOIId: id }))} />
        <ComboBox options={modifyPOICombo(msData.poiCombo, "LOC", joData.Shipment?.ShpmTypId)}
          sx={{ width: "100%" }}
          label={jobOrderColNm.Loc}
          selectedId={joData.LocPOIId}
          setSelectedId={(id) => setJOData(o => ({ ...o, LocPOIId: id }))} />
        <ComboBox options={modifyPOICombo(msData.poiCombo, "RTN", joData.Shipment?.ShpmTypId)}
          sx={{ width: "100%" }}
          label={jobOrderColNm.RtnPlc}
          selectedId={joData.RtnPOIId}
          setSelectedId={(id) => setJOData(o => ({ ...o, RtnPOIId: id }))} />
        {/* <TextField {...dataProp("TakePlc")} />
                <TextField {...dataProp("Loc")} />
                <TextField {...dataProp("RtnPlc")} /> */}
      </Box>
      <Box display="flex" flexDirection="row" gap={1}>
        <TextField {...dataProp("Ref1")} />
        <TextField {...dataProp("Ref2")} />
      </Box>
      <TextField {...dataProp("Rmk")} />
      <Box display="flex" flexDirection="row" gap={1}>
        <Box flex={2} >
          <TextField {...dataProp("InvNote")} />
        </Box>
        <Box flex={1}>
          <TagBox value={tagData} setValue={setTagData} tblAbbr="JO" />
        </Box>
        <Button variant="contained" disabled={!joData.PrId} sx={{ flex: 1, px: 0 }}
          onClick={() => window.open(`/emily/DailyJob/${joData.PrId}`, "_blank")}>
          ใบงานหลัก: {joData.PrId ? joData.PrId : "(ไม่มี)"}
        </Button>
      </Box>
      <Typography fontWeight="bold" sx={{ width: "100%" }}>เวลาใบงาน</Typography>
      <Box display="flex" flexDirection="row" gap={1} mt={-1}>
        {/* <Box display="flex" flexDirection="row" gap={1}>
                <TextField {...dataProp("JobOrdId")} required disabled />
                </Box > */}
        <DateTimePickerCustomTime {...datePickerJobOrderProp("AptTm", true)} {...shortDateProp} />
        <DateTimePickerCustomTime {...datePickerJobOrderProp("FinDte", false)} {...shortDateProp} label="ลงข้อมูลจบ" />
        <DateTimePickerCustomTime {...datePickerJobOrderProp("TakeAptTm", false)} {...shortDateProp} />
        <DateTimePickerCustomTime {...datePickerJobOrderProp("RtnAptTm", false)} {...shortDateProp} />
      </Box>
      <Typography fontWeight="bold" sx={{ width: "100%" }}>เวลารับ</Typography>
      <Box display="flex" flexDirection="row" gap={1} mt={-1}>
        <DateTimePickerCustomTime {...datePickerJobOrderProp("TakeArvTm", false)} {...shortDateProp} />
        <DateTimePickerCustomTime {...datePickerJobOrderProp("TakeLevTm", false)} {...shortDateProp} />
        <DateTimePickerCustomTime {...datePickerJobOrderProp("TakeDteSt", false)} {...shortDateProp} />
        <DateTimePickerCustomTime {...datePickerJobOrderProp("TakeDteEn", false)} {...shortDateProp} />
      </Box>
      <Typography fontWeight="bold" sx={{ width: "100%" }}>เวลาส่ง</Typography>
      <Box display="flex" flexDirection="row" gap={1} mt={-1}>
        <DateTimePickerCustomTime {...datePickerJobOrderProp("ArvTm", false)} {...shortDateProp} />
        <DateTimePickerCustomTime {...datePickerJobOrderProp("LevTm", false)} {...shortDateProp} />
        <Box width="100%" />
        <Box width="100%" />
      </Box>
      <Typography fontWeight="bold" sx={{ width: "100%" }}>เวลาคืน</Typography>
      <Box display="flex" flexDirection="row" gap={1} mt={-1}>
        <DateTimePickerCustomTime {...datePickerJobOrderProp("RtnArvTm", false)} {...shortDateProp} />
        <DateTimePickerCustomTime {...datePickerJobOrderProp("RtnLevTm", false)} {...shortDateProp} />
        <DateTimePickerCustomTime {...datePickerJobOrderProp("RtnDteSt", false)} {...shortDateProp} />
        <DateTimePickerCustomTime {...datePickerJobOrderProp("RtnDteEn", false)} {...shortDateProp} />
      </Box>
    </BoxFC>
  )
}

export default React.memo(JobOrderData)