import { ArticleRounded } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { BoxFR } from "components/BoxCustom";
import dayjs from "dayjs";
import { dateTimeDiff } from "utils";

const renderCellVanning = (handleOpenVanningDetail) => (params) => (
  <BoxFR>
    <Typography variant="body2">{params.value || "0"}</Typography>
    <IconButton sx={{ px: 0 }} onClick={() => handleOpenVanningDetail(params.field, params.row.ContSizeFull)}>
      <ArticleRounded color={params.value ? "primary" : "disabled"} />
    </IconButton>
  </BoxFR>
)

const renderCellLate = (handleOpenLateDetail) => (params) => (
  <BoxFR>
    <Typography variant="body2">{params.value || "0"}</Typography>
    <IconButton sx={{ px: 0 }} onClick={() => handleOpenLateDetail(params.field, params.row.IsLate)}>
      <ArticleRounded color={params.value ? "primary" : "disabled"} />
    </IconButton>
  </BoxFR>
)
export const vaningColumns = (handleOpenVanningDetail) => [
  {
    field: "ContSizeFull",
    headerName: "Size",
    width: 60,
  },
  {
    field: "1",
    headerName: "< 1 Hr.",
    valueFormatter: (params) => params.value || "0",
    renderCell: renderCellVanning(handleOpenVanningDetail),
    align: "center",
    flex: 1,
    minWidth: 60,
  },
  {
    field: "2",
    headerName: "1 - 2 Hrs.",
    valueFormatter: (params) => params.value || "0",
    renderCell: renderCellVanning(handleOpenVanningDetail),
    align: "center",
    flex: 1,
    minWidth: 80,
  },
  {
    field: "3",
    headerName: "2 - 3 Hrs.",
    valueFormatter: (params) => params.value || "0",
    renderCell: renderCellVanning(handleOpenVanningDetail),
    align: "center",
    flex: 1,
    minWidth: 80,
  },
  {
    field: "4",
    headerName: "3 - 4 Hrs.",
    valueFormatter: (params) => params.value || "0",
    renderCell: renderCellVanning(handleOpenVanningDetail),
    align: "center",
    flex: 1,
    minWidth: 80,
  },
  {
    field: "5",
    headerName: "> 4 Hrs.",
    valueFormatter: (params) => params.value || "0",
    renderCell: renderCellVanning(handleOpenVanningDetail),
    align: "center",
    flex: 1,
    minWidth: 90,
  },
  {
    field: "Total",
    headerName: "total",
    valueGetter: ({row}) => (+row["1"] || 0) + (+row["2"] || 0) + (+row["3"] || 0) + (+row["4"] || 0) + (+row["5"] || 0),
    valueFormatter: (params) => params.value || "0",
    renderCell: renderCellVanning(handleOpenVanningDetail),
    align: "center",
    flex: 1,
    minWidth: 90,
  }
]

export const lateTmColumns = (handleOpenLateTmDetail) => [
  {
    field: "IsLate",
    headerName: "Time",
    width: 100,
    valueGetter: (params) => params.value === "Total" ? "Total" : params.value ? "Late" : "On-Time",
  },
  {
    field: "10",
    headerName: "Before 10:00",
    valueFormatter: (params) => params.value || "0",
    renderCell: renderCellLate(handleOpenLateTmDetail),
    align: "center",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "12",
    headerName: "10:01-12:00",
    valueFormatter: (params) => params.value || "0",
    renderCell: renderCellLate(handleOpenLateTmDetail),
    align: "center",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "15",
    headerName: "12:01-15:00",
    valueFormatter: (params) => params.value || "0",
    renderCell: renderCellLate(handleOpenLateTmDetail),
    align: "center",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "16",
    headerName: "After 15:00",
    valueFormatter: (params) => params.value || "0",
    renderCell: renderCellLate(handleOpenLateTmDetail),
    align: "center",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "Total",
    headerName: "Total",
    valueFormatter: (params) => params.value || "0",
    renderCell: renderCellLate(handleOpenLateTmDetail),
    align: "center",
    flex: 1,
    minWidth: 80,
  },
]

export const vanningDetailColumns = [
  {
    field: "No",
    headerName: "No",
    width: 50,
  },
  {
    field: "Bkg",
    headerName: "Booking",
    width: 120,
  },
  {
    field: "ContNo",
    headerName: "Container No.",
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: "Size",
    width: 60,
  },
  {
    field: "AptTm",
    headerName: "Appoint Tm",
    width: 100,
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-",
    cellClassName: ({ row: { AptTm, ArvTm } }) => AptTm && ArvTm && dayjs(AptTm).isAfter(ArvTm) ? "highlight" : "",
  },
  {
    field: "ArvTm",
    headerName: "Arrive Tm",
    width: 100,
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-",
    cellClassName: ({ row: { AptTm, ArvTm } }) => AptTm && ArvTm && dayjs(AptTm).isBefore(ArvTm) ? "highlight" : "",
  },
  {
    field: "LevTm",
    headerName: "Leave Tm",
    width: 100,
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-",
    cellClassName: "highlight"
  },
  {
    field: "Hr",
    headerName: "Hour(s)",
    valueGetter: ({ row: { AptTm, ArvTm, LevTm } }) => dateTimeDiff(LevTm, dayjs(AptTm).isAfter(ArvTm) ? AptTm : ArvTm),
    flex: 1,
    minWidth: 100
  },
]

export const lateTmDetailColumns = [
  {
    field: "No",
    headerName: "No",
    width: 50,
  },
  {
    field: "Bkg",
    headerName: "Booking",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "ContNo",
    headerName: "Container No.",
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: "Size",
    width: 60,
  },
  {
    field: "AptTm",
    headerName: "Appoint Tm",
    width: 100,
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-",
  },
  {
    field: "ArvTm",
    headerName: "Arrive Tm",
    width: 100,
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-",
  },
  {
    field: "IsLate",
    headerName: "On-Time",
    width: 110,
    valueGetter: (params) => dayjs(params.row.AptTm).isAfter(params.row.ArvTm) ?
      "On-Time" : `Late: ${dateTimeDiff(params.row.ArvTm, params.row.AptTm)}`,
    cellClassName: (params) => params.value === "On-Time" ? "ontime" : "late",
  },
]