import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Dialog, DialogContent, TextField, Typography } from "@mui/material"
import { DialogHeader, DialogFooter } from "components/dialog/DialogHeader";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import ClearButton from "components/buttons/ClearButton";
import PaperComponent from "components/PaperComponent";
import dayjs from "dayjs";

const SelectDateTimeDialog = ({ numSelect, title, label, oldData, setDialogOpen, dialogOpen, onFinish }) => {

  const [colData, setColData] = useState(null)


  const datePickerProp = useMemo(() => ({
    label: label,
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: colData,
    onChange: (newValue) => { setColData(newValue) },
    renderInput: (params) => <TextField size="small" {...params} sx={{ width: 200 }} />,

  }), [colData, label])

  const handleDialogOk = useCallback(()=>{
    onFinish(colData) 
    setDialogOpen(false)
  }, [onFinish, colData, setDialogOpen])

  useEffect(() => {
    if (dialogOpen) {
      if (oldData) {
        setColData(dayjs(oldData))
      } else {
        setColData(dayjs())
      }
    }
  }, [oldData, dialogOpen])
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={title}
        onDeleteClick={null}
      />
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2} mt={2}>
          {numSelect && numSelect > 1 ?
            <Typography variant="Body" >{`แก้ไขข้อมูลจำนวน ${numSelect} แถว`}</Typography> : null
          }
          <Box display="flex" gap={2} justifyContent="center">
            <DesktopDatePicker {...datePickerProp} />
            <ClearButton onClick={() => setColData(null)} />
          </Box>
        </Box>
      </DialogContent>
      <DialogFooter okText={"แก้ไขข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} />
    </Dialog>
  )
}

export default React.memo(SelectDateTimeDialog)