
import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { Button, MenuItem, TextField } from "@mui/material";
import AddButton from "components/buttons/AddButton";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { dailyExpenseColNm } from "constants/dailyExpenseConstant";
import { Box } from "@mui/system";
import SearchButton from "components/buttons/SearchButton";
import ClearButton from "components/buttons/ClearButton";
import BoxFilter from "components/BoxCustom/BoxFilter";
import DateTimeSelect from "components/DateTimeSelect";
import ArticleIcon from '@mui/icons-material/Article';
import { initFilterData, clearFilterData } from "./initData";
import ComboBox from "components/ComboBox";

const DailyExpenseFilter = ({ onAddButtonClick, onSearchButtonClick, onNotClearListClick }) => {

  const [filterData, setFilterData] = useState(initFilterData);
  const { msData } = useContext(GlobalStateContext);

  const jobCombo = useMemo(() => filterData.CusId ?
    msData.jobCombo.filter(job => job.CusId === filterData.CusId) :
    msData.jobCombo
    , [filterData.CusId, msData.jobCombo])


  const setSelectDate = useCallback((start, end) => {
    setFilterData(oldState => ({
      ...oldState,
      ExpDteSt: start,
      ExpDteEn: end
    }))
  }, [])

  const filterProps = useCallback((name) => ({
    variant: "outlined",
    size: "small",
    name: name,
    sx: { width: 120 },
    label: dailyExpenseColNm[name],
    value: filterData[name] || "",
    onKeyUp: e => { if (e.code === "Enter" || e.code === "NumpadEnter") { onSearchButtonClick(filterData) } },
    onChange: (e) => { setFilterData(oldState => ({ ...oldState, [name]: e.target.value || null })) }
  }), [filterData, onSearchButtonClick])

  useEffect(() => {
    onSearchButtonClick(initFilterData)
  }, [onSearchButtonClick])

  return (
    <Box display="flex" gap={1}>
      <AddButton onClick={onAddButtonClick}>เพิ่มรายการ</AddButton>
      <Button variant="contained" size="small" onClick={onNotClearListClick}>
        <ArticleIcon sx={{ mr: 1 }} />ยังไม่ Clear เงิน
      </Button>
      <BoxFilter>
        <TextField {...filterProps("IsCash")} select
          sx={{ width: 120 }}
          value={filterData.IsCash ?? ""}
          onChange={(e) => setFilterData(o => ({ ...o, IsCash: e.target.value }))}>
          <MenuItem value={null}>ไม่ระบุ</MenuItem>
          <MenuItem value={1}>เงินสด</MenuItem>
          <MenuItem value={0}>โอน</MenuItem>

        </TextField>
        <DateTimeSelect
          sx={{ width: 150 }}
          label="วันที่ทำรายการ"
          start={filterData.ExpDteSt}
          end={filterData.ExpDteEn}
          setSelectDate={setSelectDate}
        />
        <TextField {...filterProps("JobOrdId")} type="number" />
        <TextField {...filterProps("Dscp")} />
        <TextField {...filterProps("Bkg")} label="Booking" />
        <ComboBox label="พนักงานขับรถ" options={msData.driverCombo}
          sx={{ width: 120 }}
          selectedId={filterData.DrvId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))} />
        <ComboBox label="ลูกค้า" options={msData.cusCombo}
          sx={{ width: 150 }}
          selectedId={filterData.CusId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, CusId: id }))} />
        <ComboBox label="งาน" options={jobCombo}
          sx={{ width: 150 }}
          selectedId={filterData.JobId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))} />
        {/* <TextField {...filterProps("DrvId")} select 
          value={filterData.DrvId||0}>
          <MenuItem value={0}>ไม่ระบุ</MenuItem>
          {msData.driverCombo.map(item=>(
            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
          ))}
        </TextField> */}
        <TextField {...filterProps("IsNotClr")} sx={{ width: 150 }} label="สถานะ Clear เงิน" select
          value={filterData.IsNotClr == null ? -1 : filterData.IsNotClr}
          onChange={(e) => setFilterData(oldState => ({ ...oldState, IsNotClr: e.target.value === -1 ? null : e.target.value }))}>
          <MenuItem value={-1}>ไม่ระบุ</MenuItem>
          <MenuItem value={0}>Clear เงินแล้ว</MenuItem>
          <MenuItem value={1}>ยังไม่ Clear เงิน</MenuItem>
        </TextField>
        <SearchButton onClick={() => { onSearchButtonClick(filterData) }} />
        <ClearButton onClick={() => { setFilterData(clearFilterData) }} />
      </BoxFilter>
    </Box>
  )
}
export default DailyExpenseFilter