import { alertWarning } from "components/Alert";
import dayjs from "dayjs";

export const checkContStatus = ({ contStatusData, joData, prevContStsData }) => {

  if (prevContStsData) {
    const foundOldStsId1 = prevContStsData.find(item => item.ContStsId === 1)
    const foundOldStsId5 = prevContStsData.find(item => item.ContStsId === 5)

    const foundStsId1 = contStatusData.find(item => item.ContStsId === 1)
    const foundStsId5 = contStatusData.find(item => item.ContStsId === 5)

    // console.log({ foundOldStsId1 })
    // console.log({ foundStsId1 })
    if (foundStsId1 && foundStsId1.DrvId && foundOldStsId1 && !foundOldStsId1.DrvId) {
      console.log("New driver")
      const wrongData = []
      if (!joData.IsRdyTake) {
        wrongData.push("ตู้ยังไม่พร้อมรับ")
      }
      if ((joData.TakeDteSt && dayjs(joData.TakeDteSt).isAfter(dayjs())) ||
        (joData.TakeDteEn && dayjs(joData.TakeDteEn).isBefore(dayjs()))) {
        wrongData.push("ไม่อยู่ในช่วงรับตู้")
      }
      if (wrongData.length > 0) {
        alertWarning(`คำเตือน: ${wrongData.join(", ")}`)
      }
    }
    if (foundStsId5 && foundStsId5.DrvId && foundOldStsId5 && !foundOldStsId5.DrvId) {
      console.log("New driver Id 5")
      console.log({ joData })
      const wrongData = []
      if (joData.Shipment?.ShpmTypId === 1) {
        if (!joData.ContDoc) {
          wrongData.push("ยังไม่มีใบกำกับตู้")
        }
        const foundShore = joData.PettyCash?.itmData?.find(itm => itm.ExpId === 2)
        console.log("foundShore::", foundShore)
        if (!foundShore) {
          wrongData.push("ยังไม่มีชอร์")
        } else if (foundShore.TagData?.Vssl !== joData.Vssl
          || foundShore.TagData?.Voy !== joData.Voy
          || foundShore.TagData?.RtnPOIId !== joData.RtnPOIId) {
          wrongData.push("ข้อมูล ชอร์ไม่ตรง")
        }
      }

      if ((joData.RtnDteSt && dayjs(joData.RtnDteSt).isAfter(dayjs())) ||
        (joData.RtnDteEn && dayjs(joData.RtnDteEn).isBefore(dayjs()))) {
        wrongData.push("ไม่อยู่ในช่วงคืนตู้")
      }
      if (wrongData.length > 0) {
        alertWarning(`คำเตือน: ${wrongData.join(", ")}`)
      }
    }
    for (let i = 0; i < prevContStsData.length; i++) {
      prevContStsData[i] = { ...contStatusData[i] }
    }
    // prevContStsData = contStatusData.map(item => ({ ...item }));

  } else {

    for (let i = 0; i < prevContStsData.length; i++) {
      prevContStsData[i] = { ...contStatusData[i] }
    }
    // prevContStsData = contStatusData.map(item => ({ ...item }));
  }
}