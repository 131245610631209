import { Typography } from "@mui/material";
import { BoxFR } from "components/BoxCustom";
import React from "react";

export const HeaderDataText = ({ header, text, sx }) => {
  return (
    <BoxFR width="100%" height={25} sx={{ alignItems: "flex-start", overflow: "auto", ...sx }} >
      <Typography variant="body" fontWeight="bold" >{header}</Typography>
      <Typography variant="body" >{text}</Typography>
    </BoxFR>
  )
}

export default HeaderDataText