import { Box, Typography } from "@mui/material";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import React from "react";
import DailyExpenseFilter from "./DailyExpenseFilter";
import useDailyExpense from "./useDailyExpense";
import { columns } from "./columns";
import DailyExpenseDialog from "./DailyExpenseDialog";
import { BoxFR } from "components/BoxCustom";
import { numberFormat } from "utils";
import DriverNotClearDialog from "./DriverNotClearDialog";


const calculateRowColor = (params) => {
  if (params.row.Amnt < 0) return "less-than-0"
  else return "more-than-0"
}

const DailyExpense = () => {

  const { state, fn } = useDailyExpense();
  const { dialogOpen, selectedId, sum, isShowNotClearTxt
    , dialogNotClearOpen, notClearDataTable, lastFilter } = state
  const {onFinish, setDialogOpen} = fn

  return (
    <Box display="flex" flexDirection="column" height="100%" sx={{ bgcolor: "white" }}>
        <DailyExpenseFilter
          onAddButtonClick={() => fn.openDialog(0)}
          onSearchButtonClick={fn.onSearchButtonClick}
          onNotClearListClick={fn.onNotClearListClick} />
        <Box width="100%" mt={2} flexGrow={1}
          sx={{
            "& .more-than-0": {
              bgcolor: "HoneyDew",
              "&:hover": {
                bgcolor: "Azure!important"
              }
            },
            "& .less-than-0": {
              bgcolor: "MistyRose",
              "&:hover": {
                bgcolor: "LavenderBlush!important"
              }
            }
          }}>
          {
            isShowNotClearTxt &&
            <Typography display="block" ml={1} variant="body1">แสดงรายการพนักงานขับรถที่ยังไม่ clear เงิน</Typography>
          }
          <DataGridCellExpand
            hideFooter
            rows={state.dataTable}
            columns={columns}
            disableSelectionOnClick
            getRowClassName={calculateRowColor}
            onRowDoubleClick={(params, event, detail) => fn.openDialog(params.id)} />
        </Box>
        <DailyExpenseDialog {...{dialogOpen, setDialogOpen, selectedId, onFinish, lastFilter}}/>
        <DriverNotClearDialog 
          dialogOpen={dialogNotClearOpen} 
          dataTable={notClearDataTable}
          setDialogOpen={fn.setDialogNotClearOpen}
          deleteDailyExpense={fn.deleteDailyExpense}
          insertUpdateDailyExpense={fn.insertUpdateDailyExpense}
          openExpenseDialog={fn.openDialog}/>
        <BoxFR p={1}>
          <Box sx={{ fontWeight: 'bold' }}>รวมเงิน </Box><Typography>{` ${numberFormat(sum)} บาท`}</Typography>
        </BoxFR>
    </Box>
  )
}

export default DailyExpense