import { Button, ButtonGroup, Dialog, DialogContent, InputAdornment, TextField, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { initChangeDialogData } from "./initData"
import { UserContext } from "contexts/UserContext"
import { pettyCashApiNm, pettyCashColNm } from "./constant"
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime"
import ComboBox from "components/ComboBox"
import { alertConfirmDelete } from "components/Alert"
import CheckboxFormControl from "components/CheckboxFormControl"
import { defaultExpWithDrawId } from "branch/constant/defaultId"
import dayjs from "dayjs"

const ChangeDialog = ({initData, prPCTrnsData, prPCTrnsId, pcTrnsId, dialogOpen, setDialogOpen, onFinish, isFromJobOrder
  , getFn, getArgs
 }) => {

  const { user } = useContext(UserContext)
  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initChangeDialogData })

  const textFieldProps = useCallback((name) => ({
    fullWidth: true,
    label: pettyCashColNm[name],
    size: "small",
    value: dialogData[name],
    onChange: (e) => setDialogData(o => ({ ...o, [name]: e.target.value })),
  }), [dialogData])

  const dateTimePickerProp = useCallback((name) => ({
    label: pettyCashColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} fullWidth />,
  }), [dialogData])

  const comboboxProps = useCallback((nameId, nameNm, options) => ({
    label: pettyCashColNm[nameId],
    sx: { width: "100%" },
    options: options,
    selectedId: dialogData[nameId],
    setSelectedId: (id) => setDialogData(o => ({ ...o, [nameId]: id, [nameNm]: options.find(option => option.id === id)?.label })),
  }), [dialogData])

  const handleDialogOK = useCallback(() => {
    const dscp = dialogData.InOutTyp === "I" ? "เงินทอน" : "เงินเพิ่ม"
    const postData = {
      ...dialogData,
      AdmAccId: user.AccId,
      UsrAccId: prPCTrnsData.UsrAccId,
      ExpId: prPCTrnsData.ExpId,
      JobOrdId: prPCTrnsData.JobOrdId,
      Dscp: dscp,
      PrPCTrnsId: prPCTrnsId,
      IsCmpt: null,
    }
    if(isFromJobOrder){
      postData.ExpNm = `(${dscp})${msData.expObj[prPCTrnsData.ExpId]?.ExpNm}`
      postData.UsrNNm = msData.accountAllCombo.find(acc => acc.id === prPCTrnsData.UsrAccId)?.label
      postData.isReimbursement = true
      
      if (dialogData.IsChangeWithdraw) {
        const reimbursePostData = {
          ...postData,
          JobOrdId: null,
          ExpId: defaultExpWithDrawId,
          ExpNm: msData.expObj[defaultExpWithDrawId]?.ExpNm ||"เบิกเงินล่วงหน้า",
          PrPCTrnsId: null,
          Dscp: "เงินทอนลงเบิก",
          InOutTyp: "O",
        }
        postData.reimburseData = {
          transactionArgs: reimbursePostData,
          itemArgs: {
            ...reimbursePostData,
            isReimbursement:true,
            PCItmTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            DocDte: dayjs().format("YYYY-MM-DD HH:mm:ss")
          }
        }
      } 
      onFinish(postData)
      setDialogOpen(false)
    } else {
      postData.getFn = getFn || "getPettyCashDetail"
      postData.getArgs = getArgs ||null
      const apiNm = pcTrnsId ? pettyCashApiNm.updatePCTransaction : pettyCashApiNm.insertPCTransaction
      
      ax.post(apiNm, postData).then(value => {
        if (value.data) {
          if (dialogData.IsChangeWithdraw) {
            postData.ExpId = defaultExpWithDrawId
            postData.PrPCTrnsId = null
            postData.Dscp = "เงินทอนลงเบิก"
            postData.InOutTyp = "O"
            postData.isReimbursement = true
            const reimbursePostData = {
              transactionArgs: {
                ...postData,
              },
              itemArgs: {
                ...postData,
                PCItmTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                DocDte: dayjs().format("YYYY-MM-DD HH:mm:ss")
              }
            }
  
            reimbursePostData.getFn = getFn || "getPettyCashDetail"
            reimbursePostData.getArgs = getArgs || null
            ax.post(pettyCashApiNm.insertReimbursment, reimbursePostData).then(reImValue => {
              if (reImValue.data) {
                onFinish(reImValue.data)
                setDialogOpen(false)
              }
            })
          } else {
            onFinish(value.data)
            setDialogOpen(false)
          }
        }
      })
    }
  }, [dialogData, onFinish, setDialogOpen, user.AccId, prPCTrnsData, prPCTrnsId, ax, msData.expObj
    , msData.accountAllCombo, isFromJobOrder, pcTrnsId, getFn, getArgs])

  const handleDelete = useCallback(() => {
    const postData = {
      PCTrnsId: pcTrnsId,
      getArgs: { PrPCTrnsId: prPCTrnsId },
      getFn: "getPettyCashDetail",
    }
    alertConfirmDelete(() => {
      ax.post(pettyCashApiNm.deletePCTransaction, postData).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false)
        }
      })
    })
  }, [pcTrnsId, prPCTrnsId, ax, onFinish, setDialogOpen])
  useEffect(() => {
    if (dialogOpen) {
      console.log("ChangeDialog useEffect initData", initData)
      if (pcTrnsId) {
        ax.post(pettyCashApiNm.getPettyCashTable, { PCTrnsIdExat: pcTrnsId }).then(value => {
          if (value.data && value.data.length === 1) {
            // const data = value.data[0]
            setDialogData({...value.data[0], IsChangeWithdraw: false})
          }
        })
      }else if(initData){
        setDialogData({
          ...initChangeDialogData,
          ...initData,
          PayTm: dayjs().format("YYYY-MM-DD HH:mm"), 
          PCTrnsId: null, 
          IsChangeWithdraw: false,
          AdmAccId: user.AccId,
        })
      }
    } else {
      setDialogData({ 
        ...initChangeDialogData, 
        AdmAccId: user.AccId,
        PayTm: dayjs().format("YYYY-MM-DD HH:mm"),
      })
    }
  }, [dialogOpen, ax, pcTrnsId, initData, user.AccId])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="เงินทอน-เงินเพิ่ม"
        onDeleteClick={pcTrnsId ? handleDelete : null}
      />
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <BoxFC pt={2} sx={{ gap: 1, alignItems: "stretch", width: 300 }}>
          <DateTimePickerCustomTime {...dateTimePickerProp("PayTm")} />
          {/* <TextField {...textFieldProps("Dscp")} /> */}
          <ComboBox {...comboboxProps("PCJnlId", "PCJnlNm", msData.pcJournalCombo)} />
          <BoxFR>
            <ButtonGroup>
              <Button variant={dialogData.InOutTyp === "I" ? "contained" : "outlined"}
                onClick={() => { setDialogData(o => ({ ...o, InOutTyp: "I" })) }}>ทอน</Button>
              <Button variant={dialogData.InOutTyp === "O" ? "contained" : "outlined"}
                onClick={() => { setDialogData(o => ({ ...o, InOutTyp: "O", IsChangeWithdraw: false })) }}>เพิ่ม</Button>
            </ButtonGroup>
            <TextField {...textFieldProps("Amnt")} type="number"
              InputProps={{ endAdornment: <InputAdornment position="end">บาท</InputAdornment> }}
              inputProps={{ style: { textAlign: "right" } }} />
          </BoxFR>
          <TextField {...textFieldProps("Rmk")} />
          <CheckboxFormControl label="เงินทอนลงเบิก" checked={dialogData.IsChangeWithdraw} disabled={dialogData.InOutTyp === "O"}
            onChange={(e)=>setDialogData(o=>({...o, IsChangeWithdraw: e.target.checked}))}/>
          <Typography ><strong>ผู้ทำรายการ: </strong>{user.FName} {user.LName} ({user.NName})</Typography>
        </BoxFC>

      </DialogContent>
      <DialogFooter okText={"เพิ่มข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOK} />
    </Dialog>
  )
}

export default React.memo(ChangeDialog)