import { Box, Button, ButtonGroup, MenuItem, TextField } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import DateTimeSelect from "components/DateTimeSelect";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { maintenanceApiNm } from "../Checkup/constant";
import { addIdForDataGrid } from "utils";
import { columns } from "./columns";
import { red } from "@mui/material/colors";
import SearchButton from "components/buttons/SearchButton";
import ClearButton from "components/buttons/ClearButton";
import CheckupRecordDialog from "./CheckupRecordDialog";
import PopoverBarebone from "components/PopoverBarebone";
import { ArrowDropDownRounded } from "@mui/icons-material";
import { alertError, alertSuccess } from "components/Alert";

const clearFilterData = {
  MtChkTypId: null,
  DrvId: null,
  TukId: null,
  MtChkDteSt: null,
  MtChkDteEn: null,
}
const initFilterData = {
  ...clearFilterData,
  MtChkDteSt: dayjs().format("YYYY-MM-DD 00:00:00.000"),
  MtChkDteEn: dayjs().format("YYYY-MM-DD 23:59:59.997"),
}

let selectedId = null
let row = null
let lastFilter = { ...initFilterData }
const CheckupRecord = () => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [filterData, setFilterData] = React.useState({ ...initFilterData })
  const [dataTable, setDataTable] = React.useState([])
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [selectionmodel, setSelectionModel] = React.useState([])

  const updateAprvRef = useRef(null)

  const getData = useCallback((filter) => {
    ax.post(maintenanceApiNm.getCheckupCheckTable, filter).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, "MtChkChkId"))
        lastFilter = { ...filter }
      }
    })
  }, [ax])

  const handleRowDoubleClick = useCallback((params) => {
    row = params.row
    selectedId = params.id
    setDialogOpen(true)
  }, [])

  const handleUpdateApprove = useCallback((isAprv) => {
    if(selectionmodel.length === 0) {
      alertError("กรุณาเลือกแถวที่ต้องการยืนยันการตรวจ")
      return;
    }
    if (!isAprv) {
      updateAprvRef.current.close()
    }
    const postData = {
      MtChkChkIds: selectionmodel,
      IsAprv: isAprv,
      getArgs: lastFilter
    }
    ax.post(maintenanceApiNm.updateCheckupCheckApprove, postData).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, "MtChkChkId"))
        alertSuccess("บันทึกข้อมูลเรียบร้อยแล้ว")
        setSelectionModel([])
      }
    })
  }, [ax, selectionmodel])

  const onFinish = useCallback((data) => {
    if (data) {
      setDataTable(addIdForDataGrid(data, "MtChkChkId"))
    } else {
      getData(lastFilter)
    }
  }, [getData])

  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  return (
    <BoxFC height="100%">
      <BoxFR>
        <ButtonGroup>
          <Button variant="contained" onClick={() => handleUpdateApprove(1)}>ยืนยันการตรวจแถวที่เลือก</Button>
          <PopoverBarebone
            ref={updateAprvRef}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }} transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }} component={
              <Button variant="contained" sx={{ minWidth: '10px !important', width: 18, p: 0, height: "100%" }}>
                <ArrowDropDownRounded />
              </Button>
            }>
            <Button variant="outlined" size="small" onClick={() => handleUpdateApprove(0)}>ยกเลิกการยืนยัน</Button>
          </PopoverBarebone>
        </ButtonGroup>
        <Box flex={1} />
        <TextField size="small" label="ยืนยันการตรวจ" select value={filterData.IsAprv || ""}
          onChange={e => setFilterData(o => ({ ...o, IsAprv: e.target.value }))} sx={{ width: 150 }}>
          <MenuItem value={null}>ไม่ระบุ</MenuItem>
          <MenuItem value={1}>ยืนยันแล้ว</MenuItem>
          <MenuItem value={0}>ไม่ยืนยัน</MenuItem>
        </TextField>
        <TextField size="small" label="ประเภทการตรวจ" select value={filterData.MtChkTypId || ""}
          onChange={e => setFilterData(o => ({ ...o, MtChkTypId: e.target.value }))} sx={{ width: 150 }}>
          <MenuItem value={null}>ไม่ระบุ</MenuItem>
          <MenuItem value={1}>รายวัน</MenuItem>
          <MenuItem value={2}>รายสัปดาห์</MenuItem>
          <MenuItem value={3}>รายเดือน</MenuItem>
        </TextField>
        <ComboBox sx={{ width: 150 }} options={msData.driverOnlyCombo} label={"พนักงานขับรถ"}
          selectedId={filterData.DrvId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))}
        />
        <ComboBox sx={{ width: 150 }} options={msData.truckCombo} label={"เลขรถ"}
          selectedId={filterData.TukId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, TukId: id }))}
        />
        <DateTimeSelect
          sx={{ width: 180 }}
          label=""
          start={filterData.MtChkDteSt}
          end={filterData.MtChkDteEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, MtChkDteSt: st, MtChkDteEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <SearchButton onClick={() => getData(filterData)} />
        <ClearButton onClick={() => setFilterData({ ...clearFilterData })} />
      </BoxFR>
      <Box flex={1} sx={{ "& .highlight": { bgcolor: red[200] } }}>
        <DataGridCellExpand
          hideToolbar
          checkboxSelection
          selectionmodel={selectionmodel}
          onSelectionModelChange={ids => setSelectionModel(ids)}
          rows={dataTable}
          columns={columns}
          onRowDoubleClick={handleRowDoubleClick}
        />
      </Box>
      <CheckupRecordDialog
        selectedId={selectedId}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        row={row}
        lastFilter={lastFilter}
        onFinish={onFinish} />
    </BoxFC>
  );
}

export default CheckupRecord;