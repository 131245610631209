
import React from "react";
import { Button, Checkbox, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import { Link } from 'react-router-dom';
import { blueGrey, grey, red, yellow } from '@mui/material/colors';
import { Box } from "@mui/system";
import { locationText } from "utils/locationText";
import dayjs from "dayjs";

const sxGap0 = { gap: 0 }

const DataBoxInner = ({ item, handles }) => {
  const { handleRemoveJobOrder, handleBkgClick, handleJobOrdIdClick
    , filterData, selectionPrint, setSelectionPrint, showCardDetail } = handles

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  let bgColor = "white"
  if (filterData.JobNo.indexOf(item.JobNo) > -1
    || filterData.CusId === item.CusId
    || filterData.JobId === item.JobId) {
    bgColor = yellow[100]
  } else if (item.hilightShpmId === item.ShpmId) {
    bgColor = yellow[100]
  } else if (item.RtnLevTm) {
    bgColor = grey[200]
  }
  return (
    <BoxFR
      sx={{
        border: `1px solid ${blueGrey[300]}`,
        borderRadius: 2,
        bgcolor: bgColor,
        px: 0.5,
        position: "relative"
      }}>
      <BoxFC sx={sxGap0}>
        <BoxFR sx={{ gap: 0 }}>
          {showCardDetail &&
            <BoxFR>
              <Checkbox sx={{ p: 0 }} checked={selectionPrint.includes(item.JobOrdId)}
                onChange={(e) => setSelectionPrint(o => {
                  return e.target.checked ? [...o, item.JobOrdId] : o.filter(j => j !== item.JobOrdId)
                })} />
            </BoxFR>
          }
          <Button sx={{ px: 0.5, minWidth: 0 }} onClick={(e) => {
            e.stopPropagation()
            handleJobOrdIdClick(item.JobOrdId)
          }}>{item.JobOrdId}</Button>
          {item.JobSNm?.substring(0, 5)}
          <Box flex={1} />
          <Tooltip title="ดึงงานกลับ" arrow placement="top" followCursor >
            <IconButton onClick={(e) => {
              e.stopPropagation()
              handleRemoveJobOrder(item)
            }} sx={{ p: 0, m: 0 }} >
              <RemoveCircleRoundedIcon />
            </IconButton>

          </Tooltip>
        </BoxFR>
        {showCardDetail &&
          <BoxFC sx={{ gap: 0 }}>
            <BoxFR>
              <Typography variant="body2">Apt: {item.AptTm ? dayjs(item.AptTm).format("DD/MM HH:mm") : " -"}</Typography>
            </BoxFR>
            <BoxFR>
              <Typography variant="body2">{item.ContSizeFull} {locationText(item, { numChar: 5 })}</Typography>
            </BoxFR>
            <BoxFR>
              <Typography variant="body2"><Link target="_blank" to={`/emily/DailyJob/JobNo/${item.JobNo}`} style={{ color: "#1976d2" }}>{item.JobNo}</Link> </Typography>
              <Tooltip title="ไฮไลท์บุคกิ้งเดียวกัน" arrow followCursor>
                <IconButton sx={{ p: 0, m: 0 }} onClick={handleBkgClick(item.hilightShpmId === item.ShpmId ? null : item.ShpmId)}>
                  {
                    item.ShpmId === item.hilightShpmId ?
                      <BookmarkRoundedIcon color="primary" /> :
                      <BookmarkBorderRoundedIcon color="primary" />
                  }
                </IconButton>
              </Tooltip>
            </BoxFR>
            <BoxFR>
              <Typography variant="body2">Clsg: {item.RtnDteEn ? dayjs(item.RtnDteEn).format("DD/MM HH:mm") : " -"}</Typography>
            </BoxFR>
          </BoxFC>
        }
        {/* <BoxFR sx={{ gap: 0.5 }}>
          <Box width={18} height={14} borderRadius={1} bgcolor={item.PlnClr} border={1} borderColor={grey[500]} />
          <Typography variant="body2"> {item.PlnTxt || "-"}</Typography>
        </BoxFR> */}
      </BoxFC>


      {item.Rmk && <Box>
        <Typography
          color={red[500]}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{
            fontSize: "1.5rem",
            position: "absolute",
            top: -2,
            right: 3,
            cursor: "help"
          }}>*</Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{item.Rmk}</Typography>
        </Popover>
      </Box>}
    </BoxFR>
  )
}
const DataBox = ({ dataArr, handles }) => {

  return (
    <BoxFR sx={{ gap: 0.2, flexWrap: "wrap" }} py={0.5} >
      {dataArr.map(item => (
        <DataBoxInner key={item.JobOrdId} item={item} handles={handles} />
      ))}
    </BoxFR>
  )
}

export default DataBox