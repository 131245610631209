
import React from "react"
import { Box, IconButton, Popover } from "@mui/material";
const PopoverDefault = ({ children, icon, anchorOrigin ={
  vertical: 'bottom',
  horizontal: 'center',
}, transformOrigin = {
  vertical: 'top',
  horizontal: 'center',
} }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  return (
    <Box>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ p: 0 }}>
        {icon}
      </IconButton>
      <Popover open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}>
        {children}
      </Popover>
    </Box>
  )
}

export default React.memo(PopoverDefault)