import React, { useCallback, useMemo, useState, useContext, useEffect } from "react";
import { Box, Dialog, DialogContent, TextField, FormLabel, RadioGroup, Radio, Checkbox, FormControlLabel, InputAdornment, Typography, Paper } from '@mui/material'
import ModAccName from 'components/ModAccName';
import { DialogFooter, DialogHeader } from 'components/dialog/DialogHeader';
import { initDialogData, truckColNm, truckApiNm } from 'constants/truckConstant';
import useDialogOpen from "hooks/useDialogOpen";
import { loadMSData, validate } from "utils";
import PaperComponent from "components/PaperComponent";
import { BoxFC, BoxFR } from "components/BoxCustom";
import FileUpload from "components/FileUplaod/FileUpload";
import { initFileUploadData } from "constants/fileUploadConstant";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { alertConfirmDelete, alertWarning } from "components/Alert";
import { saveFile } from "components/FileUplaod/saveFile";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { blueGrey } from "@mui/material/colors";
import TagBox from "components/TagBox";
import CardImage from "components/CardImage";
import isFileOverSize from "utils/isFileOverSize";

let originalDialogData = { ...initDialogData }
let originalTagIds = []
let originalpfFileData = { file: null, imgLink: null }
const initPfFileData = { TukFileId: null, file: null, imgLink: null }

const TruckDialog = ({ state, fn }) => {
  const { dialogOpen, selectedId, lastFilter } = state
  const { setDialogOpen, onFinish } = fn
  const [dialogData, setDialogData] = useState(initDialogData)
  const [licFileUploadData, setLicFileUploadData] = useState(initFileUploadData())
  const [pfFileData, setPfFileData] = useState({ ...initPfFileData })
  const [tagData, setTagData] = useState([])

  const { ax, msData, setMSData } = useContext(GlobalStateContext)

  const datePickerProp = useCallback((name) => ({
    label: truckColNm[name],
    inputFormat: "DD/MM/YYYY",
    mask: "__/__/____",
    value: dialogData[name],
    onChange: (newValue) => setDialogData((o) => ({ ...o, [name]: newValue })),
    renderInput: (params) => <TextField fullWidth size="small" {...params} />
  }), [dialogData])

  const handleFileChange = useCallback((file) => {

    if (file && isFileOverSize(file)) return

    if (file) {
      setPfFileData({
        TukFileId: null,
        file: file,
        imgLink: file ? URL.createObjectURL(file) : null
      })
    } else {
      setPfFileData({ ...initPfFileData })
    }
  }, [])


  const modifyData = useCallback((dialogData) => {
    const makeFiles = (files) => {
      return files.map(item => ({
        id: item.TukFileId,
        fileNm: item.FileNm,
        label: item.Label,
        oLabel: item.Label,
        TukId: selectedId,
        isEdit: false,
        file: null,
        // imgLink: `https://dev.emily.bittosen.com/${FILDER_TRUCK_FILE}${item.TukId}/${item.FileNm}`,
        imgLink: `/file/truck/${item.TukId}/${item.FileNm}`,
      }))
    }
    const licFile = makeFiles(dialogData.Files.filter(file => file.FileTyp !== "PF"))
    const pfFile = dialogData.Files.find(file => file.FileTyp === "PF")
    if (pfFile) {
      originalpfFileData = {
        TukFileId: pfFile.TukFileId,
        file: null,
        // imgLink: `https://dev.emily.bittosen.com/${FILDER_TRUCK_FILE}${pfFile.TukId}/${pfFile.FileNm}`,
        imgLink: `/file/truck/${pfFile.TukId}/${pfFile.FileNm}`
      }
      setPfFileData({ ...originalpfFileData })
    }
    delete originalDialogData.Files

    if (dialogData.Tags) {
      originalTagIds = dialogData.Tags.map(tag => tag.TagId)
    }
    originalDialogData = { ...dialogData }

    setLicFileUploadData({ ...initFileUploadData(), serverFiles: licFile })

    if (dialogData.Tags) {
      setTagData(dialogData.Tags.map(tag => ({ id: tag.TagId, label: tag.Name, refId: tag.TblAbbr })))
    }

    return dialogData
  }, [selectedId])

  useDialogOpen(dialogOpen, selectedId, truckApiNm.getTruck, "TukId", setDialogData, initDialogData, modifyData)

  const validation = useMemo(() => ({
    Code: dialogData.Code === '',
    LicNm: dialogData.LicNm === '',
    MileAdj: dialogData.MileAdj === '',
  }), [dialogData]);

  const dialogDataProp = useCallback((name) => ({
    variant: 'outlined',
    size: 'small',
    name: name,
    label: truckColNm[name],
    value: dialogData[name] ?? "",
    error: validation[name],
    onChange: (e) => { setDialogData((oldState) => ({ ...oldState, [name]: e.target.value })) }
  }), [dialogData, validation])

  const insertUpdateTruck = useCallback(async (dialogData) => {
    let isSame = true
    for (const key in originalDialogData) {
      if (originalDialogData[key] !== dialogData[key]) {
        isSame = false
        break;
      }
    }

    const newTagIds = tagData.map(tag => tag.id)

    const isTagSame = originalTagIds.every(num => newTagIds.includes(num))
      && newTagIds.every(num => originalTagIds.includes(num));

    let hasChange = false
    let tukId = dialogData.TukId
    if (!isSame) {
      hasChange = true
      if (!isTagSame) {
        dialogData.TagIds = newTagIds
      }
      let value;
      if (dialogData.TukId > 0) {
        tukId = dialogData.TukId
        value = await ax.post(truckApiNm.updateTruck, { ...dialogData });
      } else {
        value = await ax.post(truckApiNm.insertTruck, { ...dialogData });
        if (value.data) {
          tukId = value.data
          loadMSData(ax, setMSData, null)
        }
      }



    } else if (!isTagSame) {
      hasChange = true
      await ax.post(truckApiNm.replaceTruckTag, { TukId: dialogData.TukId, TagIds: newTagIds })
    }
    if (licFileUploadData.addedFiles.length > 0 || licFileUploadData.deletedFiles.length > 0) {
      hasChange = true
    }
    if (tukId) {
      console.log("licFileUploadData", licFileUploadData)
      await saveFile({
        ax,
        alertWarning,
        fileUploadData: licFileUploadData,
        setFileUploadData: setLicFileUploadData,
        apiUpdateFileName: truckApiNm.updateFileName,
        apiDeleteFile: truckApiNm.deleteFile,
        apiAddFile: truckApiNm.uploadFile,
        key: "TukFileId",
        refKey: "TukId",
        refValue: tukId,
        extraColValues: { FileTyp: "LIC" },
      })

      if (originalpfFileData.imgLink !== pfFileData.imgLink) {
        if (pfFileData.file === null) {
          await ax.post(truckApiNm.deleteFile, { TukFileId: originalpfFileData.TukFileId })
        } else {
          const formData = new FormData();
          formData.append("file", pfFileData.file);
          formData.append("TukId", tukId);
          formData.append("Label", "ProfileFile");
          formData.append("FileTyp", "PF");
          await ax.post(truckApiNm.uploadFile, formData)
        }
      }

    }
    if (hasChange) {
      onFinish()
    }
    setDialogOpen(false);
  }, [ax, setMSData, licFileUploadData, onFinish, setDialogOpen, tagData, pfFileData])

  const deleteTruck = useCallback((tukId) => {
    alertConfirmDelete(() => {
      ax.post(truckApiNm.deleteTruck, { TukId: tukId, getArgs: lastFilter }).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false);
          loadMSData(ax, setMSData, null)
        }
      })
    })
  }, [ax, setMSData, lastFilter, onFinish, setDialogOpen])

  useEffect(() => {
    if (!dialogOpen) {
      originalDialogData = { ...initDialogData }
      setLicFileUploadData(initFileUploadData())
      setPfFileData({ ...initPfFileData })
      setTagData([])
    }
  }, [dialogOpen])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm'
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "720px!important",
        },
      }} >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={selectedId > 0 ? "แก้ไขรถ" : "เพิ่มรถ"}
        onDeleteClick={selectedId > 0 ? () => deleteTruck(selectedId) : null}
      />
      <DialogContent sx={{ bgcolor: blueGrey[50] }}>
        <BoxFR sx={{ alignItems: "stretch" }} mt={1}  >
          <Paper sx={{ px: 2, py: 1 }}>
            <Box display='flex' flexDirection='column' gap={1} width={250}>
              <Typography variant="h6" mb={-1} >ข้อมูลรถ</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dialogData.IsActv === 1 ? true : false}
                    onChange={(e) => { setDialogData((oldState) => ({ ...oldState, IsActv: e.target.checked ? 1 : 0 })) }}
                  />
                }
                label={truckColNm.IsActv} />
              <TextField {...dialogDataProp('Code')} required />
              <TextField {...dialogDataProp('LicNm')} required />
              <TextField {...dialogDataProp('Model')} />
              <TextField {...dialogDataProp('Dscp')} multiline rows={2} />
              <FormLabel component="legend" sx={{ mb: -2 }}>{truckColNm.IsGas}</FormLabel>
              <RadioGroup row name='IsGas' value={dialogData.IsGas}
                onChange={(e) => { setDialogData((oldState) => ({ ...oldState, IsGas: e.target.value })) }}
              >
                <FormControlLabel value={0} control={<Radio />} label="น้ำมัน" />
                <FormControlLabel value={1} control={<Radio />} label="แก๊ส" />
                <FormControlLabel value={2} control={<Radio />} label="ไฟฟ้า" />
              </RadioGroup>
              <TextField {...dialogDataProp('MileAdj')} required type="number"
                inputProps={{ style: { textAlign: "right" } }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }} />
              <DesktopDatePicker {...datePickerProp('TaxExpDte')} />
              <DesktopDatePicker {...datePickerProp('InsExpDte')} />
              <DesktopDatePicker {...datePickerProp('GdInsExpDte')} />
              <DesktopDatePicker {...datePickerProp('AOLExpDte')} />
              <TextField {...dialogDataProp('Rmk')} multiline rows={2} />
              <TagBox value={tagData} setValue={setTagData} tblAbbr="TK" />
            </Box>
          </Paper>
          <Paper sx={{ px: 2, py: 1, flex: 1 }}>
            <BoxFC sx={{ width: "100%", height: "100%", gap: 1 }}>
              <Typography variant="h6" >ข้อมูลไฟล์</Typography>
              <BoxFC sx={{ gap: 0 }} height="100%" width="100%">
                <BoxFC sx={{ gap: 0 }} width="100%">

                  <Typography fontWeight="bold">รูปรถ:</Typography>
                  <CardImage
                    width={"100%"}
                    height={250}
                    name={"รูปรถ"}
                    imgLink={pfFileData.imgLink}
                    onImageChange={(e) => { handleFileChange(e.target.files[0]) }}
                    onDelete={(e) => { handleFileChange(null) }}
                  />
                </BoxFC>
                <BoxFC sx={{ gap: 0, pt: 1 }} flex={1} width="100%">
                  <Typography fontWeight="bold">เอกสารรถ:</Typography>
                  <FileUpload
                    width="100%"
                    options={msData.serverData.PreText.TruckFiles || []}
                    fileUploadData={licFileUploadData}
                    setFileUploadData={setLicFileUploadData}
                  />
                </BoxFC>
              </BoxFC>
            </BoxFC>
          </Paper>
        </BoxFR>
        {
          dialogData.TukId > 0 && <ModAccName modDte={dialogData.ModDte} modAcc={dialogData.ModAcc} />
        }
      </DialogContent>
      <DialogFooter okText={selectedId > 0 ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={() => validate(validation) && insertUpdateTruck(dialogData)} />
    </Dialog>
  )
}

export default React.memo(TruckDialog)