
import { useCallback, useState, useContext, useEffect, useMemo } from "react"
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { dailyExpApiNm } from "constants/dailyExpenseConstant";
import { initFilterData } from "./initData";
import { addIdForDataGrid} from "utils";

let lastFilter = null;
const useDailyExpense = () => {

  const [dataTable, setDataTable] = useState([]);
  const [notClearDataTable, setNotClearDataTable] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogNotClearOpen, setDialogNotClearOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0)

  const { ax } = useContext(GlobalStateContext);

  const sum = useMemo(() => {
    console.log("in calculate sum datatable::", dataTable)
    return dataTable.reduce((a, b) => a + parseFloat(b.Amnt), 0);
  }, [dataTable])

  const openDialog = useCallback((id) => {
    setSelectedId(id)
    setDialogOpen(true);
  }, [])

  const onSearchButtonClick = useCallback((filterData) => {
    ax.post(dailyExpApiNm.getDailyExpense, filterData).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, "DalyExpId"));
        lastFilter = { ...filterData }
      }
    })
  }, [ax])

  const onFinish = useCallback((data) => {
    
    setDataTable(addIdForDataGrid(data, "DalyExpId"));
    // setDialogOpen(false);
    if (dialogNotClearOpen) {
      ax.post(dailyExpApiNm.getDailyExpense, { IsNotClr: 1 }).then(value => {
        if(value.data){
          setNotClearDataTable(addIdForDataGrid(value.data.map(item=>({...item, Amnt: +item.Amnt*-1})), "DalyExpId"));
        }
      })
    }
  }, [ax, dialogNotClearOpen])
  const onNotClearListClick = useCallback(() => {
    console.log("onNotClearListClick")

    ax.post(dailyExpApiNm.getDailyExpense, { IsNotClr: 1 }).then(value => {
      if(value.data){
        setNotClearDataTable(addIdForDataGrid(value.data.map(item=>({...item, Amnt: +item.Amnt*-1})), "DalyExpId"));
        setDialogNotClearOpen(true)
      }
    })
  }, [ax])

  useEffect(() => {
    onSearchButtonClick(initFilterData)
  }, [onSearchButtonClick])
  return {
    state: { dataTable, dialogOpen, selectedId, sum, dialogNotClearOpen, notClearDataTable, lastFilter },
    fn: {
      onSearchButtonClick, openDialog, setDialogOpen,
      onFinish, onNotClearListClick, setDialogNotClearOpen, setSelectedId
    }
  }
}

export default useDailyExpense