import { jobOrderApiNm } from "constants/jobOrderConstant";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addIdForDataGrid, addStatusForClientDailyTable } from "utils";
import { clearFilterData, initFilterData } from "./initData";

let selectedJobOrdId = null
const useClientDataQuery = () =>{
  const [dataTable, setDataTable] = useState([]);
  const { ax, msData } = useContext(GlobalStateContext);
  const [dialogImageOpen, setDialogImageOpen] = useState(false)
  
  const { mField, mValue} = useParams();

  const handleImageClick = useCallback((jobOrdId)=>(e)=>{
    selectedJobOrdId  = jobOrdId
    setDialogImageOpen(true)
  }, [])


  const getDailyJob = useCallback((filter) => {
    ax.post(jobOrderApiNm.getDailyJobForClient, filter).then(value => {
      if (value.data) {
        const modTable = addStatusForClientDailyTable(value.data, msData.containerStatuses)
        for(const row of modTable){
          if (row.DrvSts3 != null){
            const stses = row.DrvSts3.split(';')
            row.DrvNm = msData.driverObj[stses[0]]?.FName || "-"
            row.LicNm = msData.truckObj[stses[2]]?.LicNm || "-"
          }
        }
        setDataTable(addIdForDataGrid(modTable,"JobOrdId"));
      }
    })
  }, [ax, msData])
  useEffect(()=>{
    let filter = {...initFilterData}
    if(mField && mValue){
      if(mField === 'YearMonthContSize'){
        filter = {...clearFilterData}
        const values = mValue.split('~')
        filter.AptTmSt = values[0].replace('_', ' ')
        filter.AptTmEn = values[1].replace('_', ' ')
        filter.ContSizeFull = values[2] === "Total"?null:values[2]
      } else {
        filter = { ...clearFilterData }
        filter[mField] = mValue
      }
    }
    getDailyJob(filter)
  }, [getDailyJob, mField, mValue])
  return {
    state:{dataTable, dialogImageOpen, selectedJobOrdId },
    fn:{getDailyJob, handleImageClick, setDialogImageOpen}
  }
}

export default useClientDataQuery